import { IDataTableColumn, IDataTableSettings } from "../types/DataTable.types";
import { DATE, INT, STRING } from "./commonConstants";


export const SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_SEARCH_LOG_KEY: string = 'SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_SEARCH_LOG';

export const SEARCH_LOG_DATATABLE_SETTINGS: IDataTableSettings = {
    defaultSortColumn: 'searchTerm',
    dataEndpoint: 'SearchLog/GetAggregateDeteails',
    staticFilters: []
}

export const SEARCH_LOG_DATATABLE_COLUMNS: IDataTableColumn[] = [
    {
        key: 'recordCount',
        dataIndex: 'recordCount',
        languageKey: '',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: INT,
        isMandatory:true,
        isHiddenFromTableDisplay:true,
    },
    
    {
        key: 'searchTerm',
        dataIndex: 'searchTerm',
        languageKey: 'searchLog-searchTerm',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'startDate',
        dataIndex: 'startDate',
        languageKey: 'searchLog-startDate',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: DATE
    },
    {
        key: 'endDate',
        dataIndex: 'endDate',
        languageKey: 'searchLog-endDate',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: DATE
    },
    {
        key: 'searchHit',
        dataIndex: 'searchHit',
        languageKey: 'searchLog-searchHit',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: INT
    },
    {
        key: 'count',
        dataIndex: 'count',
        languageKey: 'searchLog-count',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: INT
    },
    {
        key: 'details',
        dataIndex: ['details', 'id'],
        languageKey: '',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: INT,
        isMandatory:true,
        isHiddenFromTableDisplay:true,
    },
    {
        key: 'details',
        dataIndex: ['details', 'vat'],
        languageKey: '',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: STRING,
        isMandatory:true,
        isHiddenFromTableDisplay:true,
    },
    {
        key: 'details',
        dataIndex: ['details', 'ts'],
        languageKey: '',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: STRING,
        isMandatory:true,
        isHiddenFromTableDisplay:true,
    },
    {
        key: 'details',
        dataIndex: ['details', 'hits'],
        languageKey: '',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: INT,
        isMandatory:true,
        isHiddenFromTableDisplay:true,
    },
];
