import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

//import Backend from 'i18next-xhr-backend';
//To load the translation files

i18n.on('languageChanged', function (lng) {
  localStorage.setItem("lng", lng);
})

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false
    },
    debug: process.env.NODE_ENV === 'development' ? true : false,
    fallbackLng: 'en',
    whitelist: ['en', 'se'],
    interpolation: {
      escapeValue: false,
    },
    ns: ['translation'],
    backend: {
      loadPath: `${process.env.REACT_APP_TRANSLATION_PATH}/{{lng}}/{{ns}}.json`,
      addPath: `${process.env.PUBLIC_URL}/locales/add/{{lng}}/{{ns}}.json`,
    },
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'lng',
      checkWhitelist: true
    },
    saveMissing: false,
    saveMissingTo: 'all',
  });

export default i18n;