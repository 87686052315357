import React from "react";
import { useTranslation } from "react-i18next";
import { MenuOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popover, Row } from "antd";

import { HeaderLogo } from "./HeaderLogo";
import { PrimaryNav } from "../Navbar/PrimaryNav";
import { SecondaryNav } from "../Navbar/SecondaryNav";
import { ThemeSwitch } from "../ThemeSwitch/ThemeSwitch";
import { LanguageSelector } from "./LanguageSelector";
import { TRANSLATION_KEY } from "../../../constants/commonConstants";


export const Header: React.FC = () => {
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);

    const menu = () => {
        return (
            <>
                <table className="header-menu-table">
                    <tbody>
                        <tr>
                            <td><span className="mr-4">{t('common-theme')}</span></td>
                            <td className="text-right">   <ThemeSwitch /></td>
                        </tr>
                        <Divider />
                        <tr>
                            <td>{t('common-language')}</td>
                            <td className="text-right">   <LanguageSelector /></td>
                        </tr>
                        <Divider />
                    </tbody>
                </table>

            </>
        );
    }

    return (
        <>
            <Row className="primary-nav-row">
                <Col className="logo-container" span={1}>
                    <HeaderLogo />
                </Col>
                <Col span={21} className="pl-1 pr-1 primary-nav-container">
                    <PrimaryNav />
                </Col>
                <Col span={2} className="header-menu-button-container">
                    <Popover content={menu}>
                        <Button type="primary"><MenuOutlined /></Button>
                    </Popover>
                </Col>
            </Row>
            <Row className="secondary-nav-row">
                <Col span={24}>
                    <SecondaryNav />
                </Col>
            </Row>
        </>
    )
}