import React, { useEffect, useState } from "react";
import PubSub from "pubsub-js";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "antd";

import { ViewSelector } from "../../../Common/ViewSelector";
import { IDataTableColumn } from "../../../../types/DataTable.types";
import { ToolBoxSearch } from "../../../Common/DataTableToolBox/ToolBoxSearch";
import { ToolBoxCommonActions } from "../../../Common/DataTableToolBox/ToolBoxCommonActions";
import { ToolBoxColumnSelector } from "../../../Common/DataTableToolBox/ToolBoxColumnSelector";

import {
  WEBSITE_CONTENT_PAGES_VIEWS_ADMIN,
  WEBSITE_CONTENT_PAGES_VIEWS_EDITOR,
} from "../../../../constants/innerViews";
import { TRANSLATION_KEY } from "../../../../constants/commonConstants";
import { DATA_TABLE_ROW_SELECTION_CHANGED } from "../../../../constants/pubSubKeys";
import {
  PAGE_NOT_FOUND_ERRORS_DATATABLE_SETTINGS,
  SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_PAGE_NOT_FOUND_ERRORS_KEY,
} from "../../../../constants/pageNotFoundErrorsConstants";
import { DeleteRowTool } from "../../../Common/DataTableToolBox/DeleteRowTool";
import { CreateRedirectRowTool } from "./CreateRedirectRowTool";
import CommonMethods from "../../../../utils/commonMethods";

interface IPageNotFoundErrorsToolBoxProps {
  dataTableColumns: IDataTableColumn[];
}

export const PageNotFoundErrorsToolBox: React.FC<
  IPageNotFoundErrorsToolBoxProps
> = ({ dataTableColumns }) => {
  const { t } = useTranslation([TRANSLATION_KEY.toString()]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  useEffect(() => {
    let rowSelectionPubSub = PubSub.subscribe(
      DATA_TABLE_ROW_SELECTION_CHANGED,
      (msg: string, selectedRowKeys: number[]) => {
        setSelectedRowKeys(selectedRowKeys);
      }
    );
    return () => {
      PubSub.unsubscribe(rowSelectionPubSub);
    };
  }, []);

  return (
    <Card title={t("webSiteContent-pages-pageNotFoundErrors-tableName")}>
      <p>{t("webSiteContent-pages-pageNotFoundErrors-tableDescription")}</p>

      {selectedRowKeys.length > 0 && (
        <p>
          {t("dataTable-selected", "Selected")}{" "}
          <strong>{selectedRowKeys.length}</strong>{" "}
          {t("dataTable-items", "items")}
        </p>
      )}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ToolBoxSearch dataTableColumns={dataTableColumns} />
        </Col>
        <Col span={24}>
          <ToolBoxCommonActions
            isShowCopy={false}
            isShowCreateNew={false}
            isShowImport={false}
          />

          <DeleteRowTool
            className="mt-13-px"
            selectedRowKeys={selectedRowKeys}
            dataEndpoint={PAGE_NOT_FOUND_ERRORS_DATATABLE_SETTINGS.dataEndpoint}
          />

          <CreateRedirectRowTool
            className="mt-13-px"
            selectedRowKeys={selectedRowKeys}
          />
        </Col>

        <Col span={24}>
          {CommonMethods.IsAdminUser() ? (
            <ViewSelector views={WEBSITE_CONTENT_PAGES_VIEWS_ADMIN} />
          ) : (
            <ViewSelector views={WEBSITE_CONTENT_PAGES_VIEWS_EDITOR} />
          )}
        </Col>
      </Row>

      <ToolBoxColumnSelector
        dataTableColumns={dataTableColumns}
        settingsKey={
          SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_PAGE_NOT_FOUND_ERRORS_KEY
        }
      />
    </Card>
  );
};
