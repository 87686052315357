import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Select } from 'antd';
import { SwapOutlined } from '@ant-design/icons';

import { TRANSLATION_KEY } from '../../../constants/commonConstants';
import { DATA_TABLE_RELOAD } from '../../../constants/pubSubKeys';
import { FORM_STATUSES } from '../../../constants/formDataConstants';

import Notify from '../../../utils/notification-handler';
import CommonService from '../../../services/CommonService';
import { IBulkUpdateRequest } from '../../../types/Common.types';


export interface IUpdateFormDataStatusRowToolProps {
    selectedRowKeys: number[];
    className?: string;
}

export const UpdateFormDataStatusRowTool: React.FC<IUpdateFormDataStatusRowToolProps> = ({
    selectedRowKeys,
    className
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [isShowActionModal, setIsShowActionModal] = useState<boolean>(false);
    const [selectedStatus, setSelectedStatus] = useState<string>();


    const actionConfiremd = async () => {
        if (!selectedStatus) {
            Notify.error(
                t('externalCommunication-formData-pleaseSelectStatusToContinue')
            );
            return;
        }

        let model: IBulkUpdateRequest = {
            idList: selectedRowKeys,
            data: [{ propertyName: "status", value: selectedStatus }]
        }

        const odataResponse = await CommonService.post(`data/FormPostData/Bulk/Update`, model);

        if (odataResponse >= 0) {
            Notify.success(t('common-bulkUpdatedSuccessfully'));
        } else {
            Notify.error(t('common-bulkUpdatingFailed'));
        }

        closeModal();
        PubSub.publish(DATA_TABLE_RELOAD);
    }

    const statusChanged = (status: string) => {
        setSelectedStatus(status);
    }

    const actionBtnClicked = async () => {
        if (selectedRowKeys.length <= 0) {
            Notify.info(
                t('dataTable-notification-noItemsSelectedToDelete-content'),
                t('dataTable-notification-noItemsSelectedToDelete-title')
            );
            return;
        }
        setIsShowActionModal(true);
    }

    const closeModal = () => {
        setIsShowActionModal(false);
        setSelectedStatus('');
    }


    return (
        <>
            <Button onClick={actionBtnClicked} className={className}>
                <SwapOutlined className="text-blue" /> {t('externalCommunication-formData-updateStatus')}
            </Button>
            {
                <Modal title={t('externalCommunication-formData-updateStatus')}
                    visible={isShowActionModal} onOk={actionConfiremd} onCancel={closeModal}
                    okText={t('common-yes')} cancelText={t('common-no')}>
                    <p>{`${t('externalCommunication-formData-updateStatusOf')} ${selectedRowKeys.length} ${t('dataTable-items')} ?`}</p>

                    <Select allowClear onChange={statusChanged} className="w-100">
                        {
                            FORM_STATUSES.map(statusItem => {
                                return (
                                    <Select.Option key={statusItem.value} value={statusItem.value}>{t(statusItem.languageKey)}</Select.Option>
                                )
                            })
                        }
                    </Select>

                </Modal>
            }
        </>
    )
}