import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextArea from "antd/lib/input/TextArea";
import { Button, Col, Divider, Form, Input, Modal, Row, Select, Space } from "antd";

import DatePicker from "../../Common/DatePicker";
import DefaultDataHandler from "../../../utils/default-data-handler";
import { IPropertyChangeLog } from "../../../types/PropertyChangeLog.types";
import { ILanguageData } from "../../../types/LanguageData.types";

import { TRANSLATION_KEY } from "../../../constants/commonConstants";
import { DATA_TABLE_HIDE_UPDATE_MODAL, DATA_TABLE_SHOW_UPDATE_MODAL } from "../../../constants/pubSubKeys";


const { Option } = Select;

export interface IPropertyChangeLogUpdateModalProps {
    languageDataLookups: ILanguageData[];
}

export const PropertyChangeLogUpdateModal: React.FC<IPropertyChangeLogUpdateModalProps> = ({ languageDataLookups }) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [form] = Form.useForm<IPropertyChangeLog>();
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [updatingFormModel, setUpdatingFormModel] = useState<IPropertyChangeLog | null>(null);


    const closeUpdateModal = () => {
        form.resetFields();
        setUpdatingFormModel(null);
        setIsShowModal(false);
    }


    useEffect(() => {
        let showModalPubSub = PubSub.subscribe(DATA_TABLE_SHOW_UPDATE_MODAL, async (msg: string, updatingItem: IPropertyChangeLog | null) => {

            if (updatingItem === null) {
                updatingItem = DefaultDataHandler.newPropertyChangeLog();
            }
            else {
                if (updatingItem.published) { updatingItem.published = new Date(updatingItem.published); }
            }

            form.setFieldsValue(updatingItem);
            setUpdatingFormModel(updatingItem);
            setIsShowModal(true);

        });

        let hideModalPubSub = PubSub.subscribe(DATA_TABLE_HIDE_UPDATE_MODAL, () => {
            closeUpdateModal();
        })

        return () => {
            PubSub.unsubscribe(showModalPubSub);
            PubSub.unsubscribe(hideModalPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            {
                updatingFormModel &&
                <Modal
                    title={t('webSiteContent-propertyChangeLog-propertyChangeLogDetails')}
                    className="data-table-item-update-modal"
                    visible={isShowModal}
                    onCancel={closeUpdateModal} footer={null}>

                    <Form form={form}
                        name="data-table-item-update-form"
                        layout="vertical">

                        <Row gutter={24}>

                            <Col span={12}>
                                <Form.Item
                                    name="lang"
                                    label={t('propertyChangeLog-language')}>
                                    <Select className="w-100" disabled={true}>
                                        {
                                            languageDataLookups.map(languageDataLookup => {
                                                return <Option key={languageDataLookup.id} value={languageDataLookup.id}>{languageDataLookup.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="pageId"
                                    label={t('propertyChangeLog-pageId')}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    name="control"
                                    label={t('propertyChangeLog-control')}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    name="name"
                                    label={t('propertyChangeLog-name')}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    name="value"
                                    label={t('propertyChangeLog-value')}>
                                    <TextArea rows={4} disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="published"
                                    label={t('propertyChangeLog-date')}>
                                    <DatePicker className="w-100" disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="publishedBy"
                                    label={t('propertyChangeLog-user')}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>

                        </Row>

                        <Divider />

                        <Row justify="end">
                            <Col span={24}>
                                <Space className="data-table-item-update-modal-footer">
                                    <Button onClick={closeUpdateModal}>
                                        {t('common-cancel')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>

                </Modal>
            }
        </>
    )
}