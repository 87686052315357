import { notification } from "antd";
import i18next from 'i18next';

export default class Notify {

  public static success = (description?: string, title?: string,) => {
    notification.success({
      message: title ? title : i18next.t('common-notification-success-title'),
      description: description ? description : i18next.t('common-notification-success-body')
    });
  };

  public static error = (description?: string, title?: string) => {
    notification.error({
      message: title ? title : i18next.t('common-notification-error-title'),
      description: description ? description : i18next.t('common-notification-error-body')
    });
  };

  public static info = (description: string, title?: string,) => {
    notification.info({
      message: title,
      description: description
    });
  };

}