import { IViewSelectorItem } from "../types/Common.types";

export const WEBSITE_CONTENT_PAGES_VIEWS_ADMIN: IViewSelectorItem[] = [
  {
    languageKey: "tertiaryNavMenu-webPages",
    path: "/website-content/pages/web-pages",
  },
  {
    languageKey: "tertiaryNavMenu-redirects",
    path: "/website-content/pages/redirects",
  },
  {
    languageKey: "tertiaryNavMenu-redirectSettings",
    path: "/website-content/pages/redirect-settings",
  },
  {
    languageKey: "tertiaryNavMenu-pageNotFoundErrors",
    path: "/website-content/pages/page-not-found-errors",
  },
];

export const WEBSITE_CONTENT_PAGES_VIEWS_EDITOR: IViewSelectorItem[] = [
  {
    languageKey: "tertiaryNavMenu-webPages",
    path: "/website-content/pages/web-pages",
  },
  {
    languageKey: "tertiaryNavMenu-redirects",
    path: "/website-content/pages/redirects",
  },
  {
    languageKey: "tertiaryNavMenu-pageNotFoundErrors",
    path: "/website-content/pages/page-not-found-errors",
  },
];

export const WEBSITE_CONTENT_USERS_VIEWS: IViewSelectorItem[] = [
  {
    languageKey: "tertiaryNavMenu-users",
    path: "/website-content/users/users",
  },
  {
    languageKey: "tertiaryNavMenu-roles",
    path: "/website-content/users/roles",
  },
  {
    languageKey: "tertiaryNavMenu-userCreationKeys",
    path: "/website-content/users/user-creation-keys",
  },
];

export const EXTERNAL_COMMUNICATION_EMAIL_AND_SMS_VIEWS: IViewSelectorItem[] = [
  {
    languageKey: "tertiaryNavMenu-emailTemplates",
    path: "/external-communication/email-and-sms/email-templates",
  },
];

export const SYSTEM_GLOBAL_APP_SETTINGS_VIEWS: IViewSelectorItem[] = [
  {
    languageKey: "tertiaryNavMenu-siteSettings",
    path: "/system/global-app-settings/site-settings",
  },
];

export const SYSTEM_SEARCH_VIEWS: IViewSelectorItem[] = [
  {
    languageKey: "tertiaryNavMenu-searchLog",
    path: "/system/search/search-log",
  },
  {
    languageKey: "tertiaryNavMenu-searchSettings",
    path: "/system/search/search-settings",
  },
];
