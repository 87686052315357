import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ChartData, ScatterDataPoint,  registerables} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from "react-i18next";

import CommonService from "../../services/CommonService";
import { getParamsByAnlyticType } from "../../constants/googleAnalytics";
import { TRANSLATION_KEY } from "../../constants/commonConstants";

ChartJS.register(...registerables);


export interface ITopPagesProps {
    fromDate: string;
    toDate: string;
    analyticsType: string;
}

export const TopPages: React.FC<ITopPagesProps> = ({
    fromDate,
    toDate,
    analyticsType
}) => {
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [isShowError, setIsShowError] = useState<boolean>(false);
    const [graphData, setGraphData] = useState<ChartData<"bar", (number | ScatterDataPoint | null)[], unknown> | null>(null);

    const options = {
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: t('googleAnalytics-topPages')
            }
        },
    };

    useEffect(() => {

        const getGraphData = async () => {
            let labels: any[] = [];
            let data: any[] = [];
            var params = getParamsByAnlyticType(analyticsType);
            
            let url = `api/analytics?fromDate=${fromDate}&toDate=${toDate}&dimension=${params.DIMENSIONS.PAGE_TITLE}&metric=${params.METRICS.PAGE_VIEWS}`
            let response = await CommonService.get(url, false, true);
    
            if (response.status === 500) {
                setIsShowError(true);
                setGraphData(null);
                return;
            }
    
            if (response.data.rows) {
                response.data.rows.forEach((element: any) => {
                    labels.push(element.dimensions[0]);
                    data.push(element.metrics[0].values[0]);
                });
            }
    
            let graphData = {
                labels: labels,
                datasets: [{
                    data,
                    color: 'rgb(156, 194, 55)',
                }],
    
            }
    
            setGraphData(graphData);
        }

        getGraphData();
    }, [fromDate,toDate,analyticsType])


    return (
        <>
            {
                   !isShowError && graphData !== null &&
                <Bar options={options} data={graphData} />
            }
             {
                isShowError &&
                <p className="mt-3 ml-3 mr-3 text-center">{t('googleAnalytics-notConfiguredErrorMessage')}</p>
            }
        </>
    )
}