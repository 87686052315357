import { IDataTableColumn, IDataTableSettings } from "../types/DataTable.types";
import { DATE, INT, STRING } from "./commonConstants";


export const SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_FORM_DATA_KEY: string = 'SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_FORM_DATA';


export const FORM_DATA_DATATABLE_SETTINGS: IDataTableSettings = {
    defaultSortColumn: 'id',
    dataEndpoint: 'FormPostData',
    staticFilters: []
}


export const FORM_DATA_DATATABLE_COLUMNS: IDataTableColumn[] = [
    {
        key: 'id',
        dataIndex: 'id',
        languageKey: 'formData-id',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        isMandatory: true,
        dataType: INT
    },
    {
        key: 'status',
        dataIndex: 'status',
        languageKey: 'formData-status',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: INT
    },
    {
        key: 'date',
        dataIndex: 'posted',
        languageKey: 'formData-date',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: DATE
    },
    {
        key: 'ip',
        dataIndex: 'ip',
        languageKey: 'formData-ip',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: STRING
    }
];

export const FORM_DATA_DATATABLE_DEFAULT_COLUMNS: IDataTableColumn[] = [
    {
        key: 'id',
        dataIndex: 'id',
        languageKey: 'formData-id',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        isMandatory: true,
        dataType: INT
    },
    {
        key: 'status',
        dataIndex: 'status',
        languageKey: 'formData-status',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: INT
    },
    {
        key: 'date',
        dataIndex: 'posted',
        languageKey: 'formData-date',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: DATE
    },
    {
        key: 'ip',
        dataIndex: 'ip',
        languageKey: 'formData-ip',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: STRING
    }
];


export const FORM_STATUSES = [
    { value: 'New', languageKey: 'formData-status-new' },
    { value: 'Processed', languageKey: 'formData-status-processed' },
];