export const THEME_LIGHT: string = "light";
export const THEME_DARK: string = "dark";
export const ALL_THEMES: string[] = [THEME_LIGHT, THEME_DARK];
export const DEFAUL_THEME: string = THEME_LIGHT;

export const TRANSLATION_KEY: string = "translation";
export const DATATABLE_DEFAULT_ITEMS_PER_PAGE: number = 30;

export const AUTH_SIGN_KEY: string = "sign";
export const AUTH_TOKEN_KEY: string = "token";

// Sorting related keywords
export const ASCEND: string = "ascend";
export const DESCEND: string = "descend";
export const ASC: string = "asc";
export const DESC: string = "desc";

// Navigation paths
export const DASHBOARD_PATH: string = "controlpanel";

// Data types
export const STRING: string = "String";
export const INT: string = "Int";
export const BOOLEAN: string = "Boolean";
export const DATE: string = "Date";
export const DATETIME: string = "DateTime";
export const ARRAY: string = "Array";

// Languages
export const LANG_ENGLISH: string = "en";
export const LANG_SWEDISH: string = "sv";
export const LANG_SWEDISH_NEW_SYSTEM: string = "se";


export const PAGE_STATUSES_UNPUBLISHED: string = 'page-status-unpublished';
export const PAGE_STATUSES_READYFORPUBLISH: string = 'page-status-readyForPubish';
export const PAGE_STATUSES_PUBLISHED: string = 'page-status-published';
export const PAGE_STATUSES_EDITED: string = 'page-status-edited';