import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { ContainerOutlined } from '@ant-design/icons';

import Loader from '../../../../utils/loader-handler';
import ExcelHandler from '../../../../utils/excel-handler';
import CommonService from '../../../../services/CommonService';
import { TRANSLATION_KEY } from '../../../../constants/commonConstants';


export interface IMissingTargetRedirectsReportRowToolProps {
className?:string;
}

export const MissingTargetRedirectsReportRowTool: React.FC<IMissingTargetRedirectsReportRowToolProps> = ({
    className
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);

 const   actionBtnClicked = async () => {
        Loader.show();
        let excelDisplayData = await getMissingRedirects();
        ExcelHandler.writeToFile(excelDisplayData, t('webSiteContent-pages-redirects-missingTargetRedirects'));
        Loader.hide();
    }

    const getMissingRedirects = async () => {
        const response = await CommonService.get(`data/RedirectData/MissingRedirects`);
        return response;
    }


    return (
        <>
            <Button onClick={actionBtnClicked} className={className}>
                <ContainerOutlined className="text-blue" /> {t('webSiteContent-pages-redirects-missingTargetRedirects')}
            </Button>
           
        </>
    )
}