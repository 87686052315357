import { IDirectoryDataUpdateModel } from "../types/DirectoryData.types";
import { IFileData } from "../types/FileData.types";
import { IFormPostData } from "../types/FormData.types";
import { IPropertyChangeLog } from "../types/PropertyChangeLog.types";
import { IPage, IPageUpdateFormModel } from "../types/Page.types";
import { IPageNotFoundError } from "../types/PageNotFoundErrors.types";
import { IRedirect } from "../types/Redirects.types";
import { IRole } from "../types/Role.types";
import { IMembership, IProfile, IUser, IUserUpdateFormModel } from "../types/User.types";
import { IUserKey, IUserKeyUpdateFormModel } from "../types/UserKey.types";

export default class DefaultDataHandler {

    public static newUser = (): IUser => {
        return {
            id: 0,
            name: '',
            membership: DefaultDataHandler.newMembership(),
            profile: DefaultDataHandler.newProfile(),
            userRoles: [],
        };
    };

    public static newProfile = (): IProfile => {
        return {
            firstName: '',
            lastName: '',
            personalId: '',
            pageId: 0,
            phone: '',
            zip: '',
            address: '',
            city: '',
        }
    };

    public static newMembership = (): IMembership => {
        return {
            email: '',
            lastLoginDate: null,
            creationDate: null,
            isLockedOut: false
        }
    }


    public static newPage = (): IPage => {
        return {
            id: 0,
            name: '',
            pageStatus: 0,
            virtualUrl: '',
            parentId: 0,
            published: false,
            publishDate: null,
            lastPublish: null,
            unPublishDate: null,
            edited: false,
            lastEdited: null,
            readyForPublish: false,
            metadata: '',
            createdBy: '',
            created: null,
            editedBy: '',
            requiredRole: '',
            editRole: '',
            publishRole: '',
            hidden: false,
            seoword: '',
            seoscore: '',
            description: '',
            page: '',
            title: '',
            inTrash: false,
            excludeFromIndex: false
        };
    };

    public static newPageUpdateFormModel = (): IPageUpdateFormModel => {
        return {
            id: 0,
            name: '',
            pageStatus: 0,
            virtualUrl: '',
            parentId: 0,
            published: false,
            publishDate: null,
            lastPublish: null,
            unPublishDate: null,
            edited: false,
            lastEdited: null,
            readyForPublish: false,
            metadata: '',
            createdBy: '',
            created: null,
            editedBy: '',
            requiredRole: '',
            editRole: '',
            publishRole: '',
            hidden: false,
            seoword: '',
            seoscore: '',
            description: '',
            page: '',
            title: '',
            inTrash: false,
            excludeFromIndex: false
        };
    };

    public static newUserUpdateFormModel = (): IUserUpdateFormModel => {
        return {
            id: 0,
            name: '',
            membership: DefaultDataHandler.newMembership(),
            profile: DefaultDataHandler.newProfile(),
            userRoles: [],
            confirmPassword: '',
            roleIds: []
        };
    };


    public static newUserKey = (): IUserKey => {
        return {
            id: 0,
            signKey: '',
            expiryDate: null,
            regDate: null,
            url: '',
            roles: '',
            email: '',
            status: ''
        }
    }

    public static newUserKeyUpdateModel = (): IUserKeyUpdateFormModel => {
        return {
            id: 0,
            signKey: '',
            expiryDate: null,
            regDate: null,
            url: '',
            roles: [],
            email: '',
            status: ''
        }
    }

    public static newRole = (): IRole => {
        return {
            id: 0,
            name: '',
            applicationId: 1,
            userRoles: []
        };
    };

    public static newFileData = (): IFileData => {
        return {
            id: 0,
            path: '',
            title: '',
            uploaded: new Date(),
            lastEdited: new Date(),
            fileHash: '',
            fileName: ''
        };
    };

    public static newDirectoryDataUpdateModel = (): IDirectoryDataUpdateModel => {
        return {
            id: 0,
            path: '',
            title: '',
            roles: '',
            roleNames: []
        };
    };

    public static newPropertyChangeLog = (): IPropertyChangeLog => {
        return {
            id: 0,
            lang: 0,
            pageId: 0,
            control: '',
            name: '',
            value: '',
            published: new Date(),
            publishedBy: '',
        };
    };

    public static newPageNotFoundError = (): IPageNotFoundError => {
        return {
            id: 0,
            url: '',
            noi: 0,
            lastAccess: new Date()
        };
    };

    public static newRedirect = (): IRedirect => {
        return {
            id: 0,
            pageId: 0,
            customUrl: '',
            fromUrl: ''
        };
    };

    public static newFormPostData = (): IFormPostData => {
        return {
            id: 0,
            status: '',
            posted: new Date(),
            ip: '',
            formId:0,
            details:[]
        };
    };
}