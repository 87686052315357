import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Space } from "antd";

import Notify from "../../../../utils/notification-handler";
import CommonService from "../../../../services/CommonService";
import DefaultDataHandler from "../../../../utils/default-data-handler";
import { IRedirect } from "../../../../types/Redirects.types";

import { TRANSLATION_KEY } from "../../../../constants/commonConstants";
import { REDIRECTS_DATATABLE_SETTINGS } from "../../../../constants/redirectsConstants";
import { DATA_TABLE_HIDE_UPDATE_MODAL, DATA_TABLE_RELOAD, DATA_TABLE_SHOW_UPDATE_MODAL } from "../../../../constants/pubSubKeys";



export interface IRedirectsUpdateModalProps {
}

export const RedirectsUpdateModal: React.FC<IRedirectsUpdateModalProps> = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [form] = Form.useForm<IRedirect>();
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [updatingFormModel, setUpdatingFormModel] = useState<IRedirect | null>(null);
    const [isShowTargetPageId, setIsShowTargetPageId] = useState<boolean>();
    const [isShowTargetUrl, setIsShowTargetUrl] = useState<boolean>();


    const closeUpdateModal = () => {
        form.resetFields();
        setUpdatingFormModel(null);
        setIsShowModal(false);
    }


    useEffect(() => {
        let showModalPubSub = PubSub.subscribe(DATA_TABLE_SHOW_UPDATE_MODAL, async (msg: string, updatingItem: IRedirect | null) => {

            if (updatingItem === null) {
                updatingItem = DefaultDataHandler.newRedirect();
                setIsShowTargetPageId(true);
                setIsShowTargetUrl(true);
            }
            else {
                if (updatingItem.pageId && updatingItem.pageId !== 0) {
                    setIsShowTargetPageId(true);
                } else {
                    setIsShowTargetPageId(false);
                }

                if (updatingItem.customUrl) {
                    setIsShowTargetUrl(true);
                } else {
                    setIsShowTargetUrl(false);
                }
            }

            setUpdatingFormModel(updatingItem);
            form.setFieldsValue(updatingItem);
            setIsShowModal(true);

        });

        let hideModalPubSub = PubSub.subscribe(DATA_TABLE_HIDE_UPDATE_MODAL, () => {
            closeUpdateModal();
        })

        return () => {
            PubSub.unsubscribe(showModalPubSub);
            PubSub.unsubscribe(hideModalPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onFinish = (updatingItem: IRedirect) => {
        if (!updatingFormModel) {
            return;
        }
        updatingItem.id = updatingFormModel.id;

        if (updatingItem.pageId && updatingItem.pageId !== 0) {
            updatingItem.customUrl = '';
        } 
        if (updatingItem.customUrl) {
           updatingItem.pageId = 0;
        } 

        if (updatingItem.id !== 0) {
            edit(updatingItem);
            return;
        }
        add(updatingItem);
    };

    const add = async (updatingItem: IRedirect) => {
        setIsLoading(true);
        const odataResponse = await CommonService.post(`data/${REDIRECTS_DATATABLE_SETTINGS.dataEndpoint}`, updatingItem);
        if (odataResponse) {
            Notify.success(t('common-itemAddedSuccessfully'));
        }
        PubSub.publish(DATA_TABLE_RELOAD);
        closeUpdateModal();
        setIsLoading(false);
    }


    const edit = async (updatingItem: IRedirect) => {
        setIsLoading(true);

        let url = `data/${REDIRECTS_DATATABLE_SETTINGS.dataEndpoint}/${updatingItem.id}`
        const odataResponse = await CommonService.patch(url, updatingItem);

        if (odataResponse === 0 || odataResponse === 1) {
            Notify.success(t('common-itemUpdatedSuccessfully'));
        }

        PubSub.publish(DATA_TABLE_RELOAD);
        closeUpdateModal();
        setIsLoading(false);
    }


    const onTargetPageIdChange = (value: number) => {
        if (value !== null && value !== 0) {
            form.setFieldsValue({ customUrl: '' });
            setIsShowTargetUrl(false);
        } else {
            setIsShowTargetUrl(true);
        }
    }

    const onTargetPageUrlChange = (value: string) => {
        if (value !== '') {
            form.setFieldsValue({ pageId: 0 });
            setIsShowTargetPageId(false);
        } else {
            setIsShowTargetPageId(true);
        }
    }

    return (
        <>
            {
                updatingFormModel &&
                <Modal
                    title={(updatingFormModel.id !== 0) ? t('webSiteContent-pages-redirects-redirectsEditModalTitle') : t('webSiteContent-pages-redirects-redirectsAddModalTitle')}
                    className="data-table-item-update-modal"
                    visible={isShowModal}
                    onCancel={closeUpdateModal} footer={null}>

                    <Form form={form}
                        name="data-table-item-update-form"
                        layout="vertical"
                        onFinish={onFinish}>

                        <Row gutter={24}>

                            {
                                isShowTargetPageId &&
                                <Col span={24}>
                                    <Form.Item
                                        name="pageId"
                                        label={t('redirects-targetPageId')}
                                        required={true}
                                        rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                        <InputNumber onChange={onTargetPageIdChange} className="w-100" />
                                    </Form.Item>
                                </Col>
                            }
                            {
                                isShowTargetUrl &&
                                <Col span={24}>
                                    <Form.Item
                                        name="customUrl"
                                        label={t('redirects-targetUrl')}
                                        required={true}
                                        rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                        <Input onChange={(e) => onTargetPageUrlChange(e.target.value)} />
                                    </Form.Item>
                                </Col>
                            }

                            <Col span={24}>
                                <Form.Item
                                    name="fromUrl"
                                    label={t('redirects-url')}
                                    required={true}
                                    rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                    <Input />
                                </Form.Item>
                            </Col>

                        </Row>

                        <Divider />

                        <Row justify="end">
                            <Col span={24}>
                                <Space className="data-table-item-update-modal-footer">
                                    <Button onClick={closeUpdateModal}>
                                        {t('common-cancel')}
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={isLoading}>
                                        {t('common-save')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>

                </Modal>
            }
        </>
    )
}