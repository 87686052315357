import React, { useEffect, useState } from "react";
import List from "antd/lib/list";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import { ProfileOutlined } from '@ant-design/icons';

import Notify from "../../utils/notification-handler";
import axiosInstance from "../../utils/axios/axiosInstance";
import { INews } from "../../types/News.types";
import { TRANSLATION_KEY } from "../../constants/commonConstants";
import { Link } from "react-router-dom";


export const NewsFeedWidget: React.FC = () => {
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [news, setNews] = useState<INews[]>([]);
    const NEWS_ITEM_COUNT = 10;

    useEffect(() => {
        const getNews = async () => {
            axiosInstance({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/emporiapi/newsarchive?count=${NEWS_ITEM_COUNT}`,
            })
                .then((response: AxiosResponse) => {
                    if (!response || !response.data || !response.data.newsItems) {
                        Notify.error(t('common-dataGetFailed'));
                    }
                    setNews(response.data.newsItems);

                }, () => {
                    // do not display error message because dev environment & qa environment does not have new feed implemented
                    console.log('News feed load failed');
                });
        }
        getNews();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="widget-container">
            <div>
                <h2><ProfileOutlined className="text-primary-contrast mr-2" /> {t('dashboard-emporiNewsFeed')}</h2>
                {
                    news && news.length > 0 &&
                    <Link to={{ pathname: `${process.env.REACT_APP_BASE_URL}/nyheter` }} target="_blank"  >
                        <span className="widget-flag" >{t('dashboard-readAllNews')} </span>
                    </Link>
                }
            </div>
            <List
                bordered={true}
                dataSource={news}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            title={<a target="_blank" rel="noopener noreferrer" href={item.link}>{item.title}</a>}
                            description={item.preamble}
                        />
                    </List.Item>
                )}
            />
        </div>
    )
}