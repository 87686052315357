export const PRIMARY_NAV_CLICKED: string = 'PRIMARY_NAV_CLICKED';
export const SECONDARY_NAV_CLICKED: string = 'SECONDARY_NAV_CLICKED';
export const TERTIARY_NAV_CLICKED: string = 'TERTIARY_NAV_CLICKED';

export const SHOW_HIDE_LOADER: string = 'show_hide_loader';

export const DATA_TABLE_RELOAD: string = 'data_table_reload';
export const DATA_TABLE_RESET_AND_RELOAD: string = 'data_table_reset_and_reload';
export const DATA_TABLE_COLUMN_FILTER: string = 'data_table_column_filter';
export const DATA_TABLE_SEARCH_TEXT_FILTER: string = 'data_table_search_text_filter';
export const DATA_TABLE_STATIC_FILTER: string = 'data_table_static_filter';
export const DATA_TABLE_UPDATE_COLUMN_VISIBILITY: string = 'data_table_update_column_visibility';
export const DATA_TABLE_ROW_SELECTION_CHANGED: string = 'data_table_row_selection_changed';
export const DATA_TABLE_EXPORT: string = 'data_table_export';
export const DATA_TABLE_SHOW_HIDE_IMPORT: string = 'data_table_show_hide_import';
export const DATA_TABLE_SHOW_HIDE_COLUMN_SELECTOR: string = 'data_table_show_hide_column_selector';
export const DATA_TABLE_COPY_ITEM: string = 'data_table_copy_item';
export const DATA_TABLE_BULK_UPDATE: string = 'data_table_bulk_update';
export const DATA_TABLE_RESET_TOOL_BOX_SEARCH: string = 'data_table_reset_search_text_filter';
export const DATA_TABLE_RESET_STATIC_FILTER: string = 'data_table_reset_static_filter';
export const DATA_TABLE_SHOW_UPDATE_MODAL: string = 'data_table_show_update_modal';
export const DATA_TABLE_HIDE_UPDATE_MODAL: string = 'data_table_hide_update_modal';
export const DATA_TABLE_SHOW_HIDE_COLUMN: string = 'data_table_show_hide_column';

export const OPEN_SEARCH_LOG_DETAILS: string = 'open_search_log_details';
