import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_KEY } from '../../constants/commonConstants';

const JOB_SCHEDULER_URL = process.env.REACT_APP_JOB_SCHEDULER_SOURCE;

const JobScheduler: React.FC = () => {
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (JOB_SCHEDULER_URL && token) {
            let jobSchedularUrl = (new URL(JOB_SCHEDULER_URL));
            let urlSeperated = jobSchedularUrl.hostname.split('.');
            let domain = `.${urlSeperated[urlSeperated.length-2]}.${urlSeperated[urlSeperated.length-1]}`;
            document.cookie = `authToken=${token};domain=${domain};path=/`;
        }
    }, []);


    return (
        <div><h1>{t('jobScheduler-jobSchedulerDescription')}</h1>
            <iframe
                src={process.env.REACT_APP_JOB_SCHEDULER_SOURCE}
                title="jobs"
                id="jobFrame"
                width="100%"
                height="800"
                scrolling="no"
                frameBorder="0"
            ></iframe>
        </div>

    );
};

export default JobScheduler;
