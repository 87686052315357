import React, { useEffect, useState } from "react";
import { Tag } from "antd";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { UserOutlined } from '@ant-design/icons';

import CommonService from "../../services/CommonService";
import { ISystemUserInfo } from "../../types/Dashboard.types";
import { TRANSLATION_KEY } from "../../constants/commonConstants";
import CommonMethods from "../../utils/commonMethods";

const currentUsername = CommonMethods.getCurrentUsersUsername();

export const UserDataWidget: React.FC = () => {
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [systemUserInfo, setSystemUserInfo] = useState<ISystemUserInfo>();

    useEffect(() => {
        // Getting user data is skipped because 'wd.admin' is a hardcoded user in cms 8 control panel, this user is not in database
        // Hence no actual user data available
        if (currentUsername !== 'wd.admin') {
            getUserdata();
        }

    }, []);

    const getUserdata = async () => {
        const odataResponse = await CommonService.get('api/system/information');
        if (odataResponse) {
            setSystemUserInfo(odataResponse);
        }
    }

    const getRolesDisplay = (rolesString?: string) => {
        let rolesDisplay: JSX.Element[] = [];

        if (rolesString) {
            rolesString.split(',').forEach((role: string) => {
                rolesDisplay.push(<Tag key={role} className="roles-tag">{role}</Tag>)
            })
        }

        return rolesDisplay;
    }


    return (
        <div className="widget-container user-data-widget">
            <div className="mb-4">
                <h2><UserOutlined className="text-primary-contrast mr-2" /> {t('dashboard-welcome')}, {systemUserInfo?.name}</h2>
            </div>

            <table className="table-borderless">
                <tbody>
                    <tr>
                        <td className="roles-column">{t('dashboard-roles')}</td>
                        <td>{getRolesDisplay(systemUserInfo?.roles)}</td>
                    </tr>
                    <tr>
                        <td>{t('dashboard-pagesPublished')}</td>
                        <td>{systemUserInfo?.publishedPages}</td>
                    </tr>
                    <tr>
                        <td>{t('dashboard-pagesNotPublished')}</td>
                        <td>{systemUserInfo?.notPublishedPages}</td>
                    </tr>
                    <tr>
                        <td>{t('dashboard-pagesPublishedByYou')}</td>
                        <td>{systemUserInfo?.publishedByUser}</td>
                    </tr>
                    <tr>
                        <td>{t('dashboard-passwordLastChanged')}</td>
                        <td>{systemUserInfo?.lastPasswordChangedDate ? (format(new Date(systemUserInfo.lastPasswordChangedDate as Date), 'yyyy-MM-dd HH:mm')) : ''}</td>
                    </tr>
                </tbody>
            </table>
            {
                   currentUsername === 'wd.admin'&&
                   <div className="text-center mt-2 text-red"><small>This section data is not available for 'wd.admin' user</small></div>
               } 
        </div>
    )
}