import React from 'react';
import { Layout as AntLayout } from 'antd';

const { Footer } = AntLayout;

interface IBasicFooterProps {
    footerTitle: string;
}

const BasicFooter: React.FC<IBasicFooterProps> = ({ footerTitle }) => (
    <Footer style={{ textAlign: 'center' }}>{footerTitle}</Footer>
);

export default BasicFooter;
