import React, { useState } from 'react';
import AuthContext from '../utils/auth-context';

const AuthProvider: React.FC = ({ children }) => {
  const [auth] = useState(false);
  const [authData] = useState();

  return (
    <AuthContext.Provider value={{ isAuth: auth, data: authData }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
