import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

import DataTableHelper from "../../components/DataTable/DataTable.helper";
import CommonService from "../../services/CommonService";
import { IPropertyChangeLog } from "../../types/PropertyChangeLog.types";
import { DataTable } from "../../components/DataTable/DataTable";
import { PropertyChangeLogToolBox } from "../../components/WebsiteContent/PropertyChangeLog/PropertyChangeLogToolBox";
import { PropertyChangeLogUpdateModal } from "../../components/WebsiteContent/PropertyChangeLog/PropertyChangeLogUpdateModal";
import { IDataTableColumn, IDataTableSettings, IShowHideColumnPubSubKey } from "../../types/DataTable.types";

import { ILanguageData } from "../../types/LanguageData.types";

import { FilterDropdownProps } from "antd/lib/table/interface";
import { PropertyChangeLogLangColumnFilter } from "../../components/WebsiteContent/PropertyChangeLog/PropertyChangeLogLangColumnFilter";

import { TRANSLATION_KEY } from "../../constants/commonConstants";
import { DATA_TABLE_SHOW_HIDE_COLUMN } from "../../constants/pubSubKeys";
import { PROPERTY_CHANGE_LOG_DATATABLE_COLUMNS, PROPERTY_CHANGE_LOG_DATATABLE_SETTINGS, SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_PROPERTY_CHANGE_LOG_KEY } from "../../constants/propertyChangeLogConstants";



export const PropertyChangeLog: React.FC = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [dataTableSettings] = useState<IDataTableSettings>(PROPERTY_CHANGE_LOG_DATATABLE_SETTINGS);
    const [dataTableColumns, setDataTableColumns] = useState<IDataTableColumn[]>(PROPERTY_CHANGE_LOG_DATATABLE_COLUMNS);
    const [languageDataLookups, setLanguageDataLookups] = useState<ILanguageData[]>([]);


    useEffect(() => {
        const init = async () => {
            let visibleColumns = await getVisibleColumnsFromSettings();
            resetColumnVisibilityWithSavedSettings(visibleColumns);
            await getLanguageDataLookups();
        }
        init();

        let showHideColumnsPubSub = PubSub.subscribe(
            DATA_TABLE_SHOW_HIDE_COLUMN,
            (msg: string, model: IShowHideColumnPubSubKey) => {
                let _dataTableColumns = DataTableHelper.resetSingleColumnVisibility(
                    [...dataTableColumns],
                    model.columnKey,
                    model.isChecked
                );
                setDataTableColumns(_dataTableColumns);
            });
        return () => {
            PubSub.unsubscribe(showHideColumnsPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetColumnVisibilityWithSavedSettings = (visibleColumnsFromSettngs: string[]) => {
        let _dataTableColumns = DataTableHelper.resetColumnVisibility(dataTableColumns, visibleColumnsFromSettngs);
        if (_dataTableColumns) {
            setDataTableColumns(_dataTableColumns);
        }
    }

    const getVisibleColumnsFromSettings = async () => {
        return await DataTableHelper.getVisibleColumnsFromSettings(SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_PROPERTY_CHANGE_LOG_KEY);
    }

    const getLanguageDataLookups = async () => {
        const odataResponse = await CommonService.get('data/LanguageData?$select=id,name');
        setLanguageDataLookups(odataResponse);
    }

    const generateLanguageCustomDisplay = (value: any) => {
        let id = value as number;
        let matchingLanguage = languageDataLookups.find(s => s.id === id);

        if (matchingLanguage) {
            return <>{matchingLanguage.name}</>;
        }
        return <></>;
    }

    const generateCustomColumnDisplays = (dataIndex: string, value: any) => {
        switch (dataIndex) {
            case 'lang':
                return generateLanguageCustomDisplay(value);
        }
        return <></>
    }

    const generateCustomFilters = (dataIndex: string, column: IDataTableColumn, filterDropdownProps: FilterDropdownProps) => {
        switch (dataIndex) {
            case 'lang':
                return (
                    <PropertyChangeLogLangColumnFilter dataIndex={dataIndex} filterDropdownProps={filterDropdownProps} languageDataLookups={languageDataLookups}/>
                );
        }
        return <></>
    }



    return (
        <>
            <Row gutter={16}>
                <Col span={5} className="gutter-row">
                    <PropertyChangeLogToolBox dataTableColumns={dataTableColumns}></PropertyChangeLogToolBox>
                </Col>
                <Col span={19} className="gutter-row">
                    {
                        <DataTable<IPropertyChangeLog>
                            dataTableSettings={dataTableSettings}
                            dataTableColumns={dataTableColumns}
                            cutomDisplays={generateCustomColumnDisplays}
                            customFilters={generateCustomFilters}
                            editColumnIcon="EyeOutlined"
                            lang={t}></DataTable>
                    }
                </Col>
            </Row>

            <PropertyChangeLogUpdateModal languageDataLookups={languageDataLookups}></PropertyChangeLogUpdateModal>

        </>
    )
}