import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Form, Input, InputNumber, Row, Switch, } from 'antd';

import { SYSTEM_SEARCH_VIEWS, } from '../../constants/innerViews';
import { TRANSLATION_KEY } from '../../constants/commonConstants';
import { ADDITIONAL_STOP_WORDS, END_INGRESS_ON_COMPLETE_WORD, INDEX_INGRESS_LENGTH } from '../../constants/settingsConstant';

import CommonService from '../../services/CommonService';
import Loader from '../../utils/loader-handler';
import Notify from '../../utils/notification-handler';
import { ViewSelector } from '../../components/Common/ViewSelector';
import { ISettingsItem, ISettingsItemUpdateModel } from '../../types/Common.types';
const { TextArea } = Input;


export const SearchSettings: React.FC = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [form] = Form.useForm();

    useEffect(() => {
        getSearchSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSearchSettings = async () => {
        let response = await CommonService.post(`api/system/settingsList`,
            [INDEX_INGRESS_LENGTH, END_INGRESS_ON_COMPLETE_WORD, ADDITIONAL_STOP_WORDS]);

        let formModel: any = {};

        formModel[INDEX_INGRESS_LENGTH] = getSettingsValueByKey(response, INDEX_INGRESS_LENGTH);
        formModel[END_INGRESS_ON_COMPLETE_WORD] = getSettingsValueByKey(response, END_INGRESS_ON_COMPLETE_WORD);
        formModel[ADDITIONAL_STOP_WORDS] = getSettingsValueByKey(response, ADDITIONAL_STOP_WORDS);

        form.setFieldsValue(formModel);
    }

    const getSettingsValueByKey = (settings: ISettingsItem[], key: string) => {
        let matchingItem = settings.find(s => s.key === key);

        if (!matchingItem) {
            return null;
        }

        switch (matchingItem.type) {
            case 'Boolean':
                return matchingItem.value === 'True';
            case 'String':
                return matchingItem.value;
            case 'Int32':
                return +matchingItem.value;
            default:
                return matchingItem.value;
        }
    }

    const onFinish = async (updatingItem: any) => {
        Loader.show();

        let settingsUpdateModel: ISettingsItemUpdateModel[] = [];
        Object.keys(updatingItem).forEach((e: string) => {
            let value = updatingItem[e];

            if (typeof value == "boolean") {
                value = value === true ? "True": "False";
            }
            if (typeof value == "number") {
                value = value.toString();
            }

            settingsUpdateModel.push(
                {
                    key: e,
                    value: value,
                }
            );
        });

        let response = await CommonService.post(`api/system/settings`, settingsUpdateModel);
        if (response === true) {
            Notify.success(t('common-itemUpdatedSuccessfully'));
        } else {
            Notify.error(t('common-itemUpdatingFailed'));
        }

        Loader.hide();
    }


    return (
        <>
            <Row gutter={16}>
                <Col span={5} className="gutter-row">

                    <Card title='Search settings'>
                        <p>{t('searchSettings-description')}</p>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <ViewSelector views={SYSTEM_SEARCH_VIEWS} />
                            </Col>
                        </Row>
                    </Card>

                </Col>
                <Col span={19} className="gutter-row">

                    <Form
                        form={form}
                        onFinish={onFinish}
                        name="data-table-item-update-form"
                        layout="vertical">

                        <Card title={t('searchSettings')}
                            extra={<Button type="primary" htmlType="submit">{t('common-save')}</Button>}>

                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        name={INDEX_INGRESS_LENGTH}
                                        label={t('searchSettings-lengthOfTheStringInIngress')}>
                                        <InputNumber className="w-100"/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item valuePropName="checked"
                                        name={END_INGRESS_ON_COMPLETE_WORD}
                                        label={t('searchSettings-disableWordBreaksInIngress')}>
                                        <Switch />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name={ADDITIONAL_STOP_WORDS}
                                        label={t('searchSettings-additionalStopWords')}>
                                        <TextArea />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Form>
                </Col>
            </Row>

        </>
    )
};


