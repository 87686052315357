import React from "react";
import { useTranslation } from "react-i18next";
import { SettingOutlined, BulbOutlined } from '@ant-design/icons';
import { TRANSLATION_KEY } from "../../constants/commonConstants";


export const HelpWidget: React.FC = () => {
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);

    return (
        <div id="helpWidget" className="widget-container">
            <div className="widget-container">
                <div className="mb-4">
                    <h2><SettingOutlined className="text-primary-contrast mr-2" /> {t('dashboard-help-title1')}</h2>
                </div>
                <p>{t('dashboard-help-description1')}</p>

                <div className="mb-4 mt-4">
                    <h2><BulbOutlined className="text-primary-contrast mr-2" /> {t('dashboard-help-title2')}</h2>
                </div>
                <p>{t('dashboard-help-description2')}</p>
            </div>

        </div>
    )
}