import { IDataTableColumn, IDataTableSettings } from "../types/DataTable.types";
import { DATE, INT, STRING } from "./commonConstants";


export const SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_USERKEYS_KEY: string = 'SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_USERKEYS';

export const USERKEYS_DATATABLE_SETTINGS: IDataTableSettings = {
    defaultSortColumn: 'id',
    dataEndpoint: 'UserKeyData',
    staticFilters: []
}


export const USERKEYS_DATATABLE_COLUMNS: IDataTableColumn[] = [
    {
        key: 'id',
        dataIndex: 'id',
        languageKey: '',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: INT,
        isMandatory:true,
        isHiddenFromTableDisplay:true,
    },
    {
        key: 'email',
        dataIndex: 'email',
        languageKey: 'userkey-emailAddress',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING,
    },
    {
        key: 'url',
        dataIndex: 'url',
        languageKey: 'userkey-link',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING,
    },
    {
        key: 'roles',
        dataIndex: 'roles',
        languageKey: 'userkey-roles',
        isVisible: true,
        isSortable: false,
        isSearchable: false,
        dataType: STRING,
    },
    {
        key: 'regDate',
        dataIndex: 'regDate',
        languageKey: 'userkey-createdDate',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: DATE,
    },
    {
        key: 'expiryDate',
        dataIndex: 'expiryDate',
        languageKey: 'userkey-expireDate',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: DATE,
    },
    {
        key: 'status',
        dataIndex: 'status',
        languageKey: 'userkey-status',
        isVisible: true,
        isSortable: false,
        isSearchable: false,
        dataType: STRING,
    },
    {
        key: 'signKey',
        dataIndex: 'signKey',
        languageKey: '',
        isVisible: false,
        isSearchable: false,
        isHiddenFromTableDisplay:true,
        isMandatory:true,
        dataType: STRING,
    }
];