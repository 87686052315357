import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Divider, Form, Input, Modal, Row, Space, Select } from "antd";

import DatePicker from "../../Common/DatePicker";
import { IFormPostData } from "../../../types/FormData.types";

import { TRANSLATION_KEY } from "../../../constants/commonConstants";
import { DATA_TABLE_HIDE_UPDATE_MODAL, DATA_TABLE_RELOAD, DATA_TABLE_SHOW_UPDATE_MODAL } from "../../../constants/pubSubKeys";
import { FORM_STATUSES } from "../../../constants/formDataConstants";
import CommonService from "../../../services/CommonService";
import Notify from "../../../utils/notification-handler";


export interface IFormDataUpdateModalProps {
}

export const FormDataUpdateModal: React.FC<IFormDataUpdateModalProps> = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [form] = Form.useForm<IFormPostData>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [updatingFormModel, setUpdatingFormModel] = useState<IFormPostData | null>(null);


    useEffect(() => {
        let showModalPubSub = PubSub.subscribe(DATA_TABLE_SHOW_UPDATE_MODAL, async (msg: string, updatingItem: IFormPostData) => {

            if (updatingItem.posted) { updatingItem.posted = new Date(updatingItem.posted); }

            form.setFieldsValue(updatingItem);
            setUpdatingFormModel(updatingItem);
            setIsShowModal(true);

        });

        let hideModalPubSub = PubSub.subscribe(DATA_TABLE_HIDE_UPDATE_MODAL, () => {
            closeUpdateModal();
        })

        return () => {
            PubSub.unsubscribe(showModalPubSub);
            PubSub.unsubscribe(hideModalPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const closeUpdateModal = () => {
        form.resetFields();
        setUpdatingFormModel(null);
        setIsShowModal(false);
    }

    const onFinish = async (updatingItem: any) => {
        setIsLoading(true);
        let url = `data/FormPostData/${updatingItem.id}`;

        const odataResponse = await CommonService.patch(url, { status: updatingItem.status });

        if (odataResponse >= 0) {
            Notify.success(t('common-itemUpdatedSuccessfully'));
        } else {
            Notify.error(t('common-itemUpdatingFailed'));
        }

        PubSub.publish(DATA_TABLE_RELOAD);
        closeUpdateModal();
        setIsLoading(false);
    }


    return (
        <>
            {
                updatingFormModel &&
                <Modal
                    title={t('externalCommunication-formData-formDataDetails')}
                    className="data-table-item-update-modal"
                    visible={isShowModal}
                    onCancel={closeUpdateModal} footer={null} width={800}>

                    <Form form={form}
                        onFinish={onFinish}
                        name="data-table-item-update-form"
                        layout="vertical">

                        <Row gutter={24}>

                            <Col span={12}>
                                <Form.Item
                                    name="id"
                                    label={t('formData-id')}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="status"
                                    label={t('formData-status')}
                                    required={true}
                                    rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                    <Select allowClear className="w-100">
                                        {
                                            FORM_STATUSES.map(statusItem => {
                                                return (
                                                    <Select.Option key={statusItem.value} value={statusItem.value}>{t(statusItem.languageKey)}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="posted"
                                    label={t('formData-date')}>
                                    <DatePicker className="w-100" disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="ip"
                                    label={t('formData-ip')}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>

                            {
                                Object.keys(updatingFormModel).filter(k=>k !== 'id' && k !== 'status' && k !== 'posted' && k !== 'ip').map((key:string) => {
                                    return (
                                        <Col span={8}>
                                            <Form.Item
                                                name={key}
                                                label={key}>
                                                <Input disabled={true} />
                                            </Form.Item>
                                        </Col>
                                    )
                                })
                            }

                        </Row>

                        <Divider />

                        <Row justify="end">
                            <Col span={24}>
                                <Space className="data-table-item-update-modal-footer">
                                    <Button onClick={closeUpdateModal}>
                                        {t('common-cancel')}
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={isLoading} >
                                        {t('common-save', 'Save')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>

                </Modal>
            }
        </>
    )
}