export const GOOGLE_ANALYTICS_TYPE_UA = 'ua';
export const GOOGLE_ANALYTICS_TYPE_GA4 = 'ga4';

export const UA_PARAMS = {
    DIMENSIONS: {
        DATE: 'date',
        PAGE_TITLE: 'pageTitle',
        SOURCE: 'source'
    },
    METRICS: {
        USERS: 'users',
        PAGE_VIEWS: 'pageviews',
    }
};

export const GA4_PARAMS = {
    DIMENSIONS: {
        DATE: 'date',
        PAGE_TITLE: 'pageTitle',
        SOURCE: 'firstUserSource'
    },
    METRICS: {
        USERS: 'totalUsers',
        PAGE_VIEWS: 'screenPageViews',
    }
};

export const getParamsByAnlyticType = (analyticType: string) => {
    switch (analyticType) {
        case GOOGLE_ANALYTICS_TYPE_UA:
            return UA_PARAMS;

        case GOOGLE_ANALYTICS_TYPE_GA4:
            return GA4_PARAMS;

        default:
            return UA_PARAMS;
    }
}