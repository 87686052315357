import React, { useEffect, useState } from "react";
import PubSub from "pubsub-js";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "antd";

import { WebPagesRowTools } from "./WebPagesRowTools";
import { IDataTableColumn } from "../../../../types/DataTable.types";
import { ToolBoxSearch } from "../../../Common/DataTableToolBox/ToolBoxSearch";
import { ToolBoxCommonActions } from "../../../Common/DataTableToolBox/ToolBoxCommonActions";
import { ToolBoxColumnSelector } from "../../../Common/DataTableToolBox/ToolBoxColumnSelector";
import { ToolBoxStaticFilters } from "../../../Common/DataTableToolBox/ToolBoxStaticFilters";

import { TRANSLATION_KEY } from "../../../../constants/commonConstants";
import { DATA_TABLE_ROW_SELECTION_CHANGED } from "../../../../constants/pubSubKeys";
import {
  WEB_PAGES_DATATABLE_SETTINGS,
  SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_WEB_PAGES_KEY,
} from "../../../../constants/webPagesConstants";
import { ViewSelector } from "../../../Common/ViewSelector";
import {
  WEBSITE_CONTENT_PAGES_VIEWS_ADMIN,
  WEBSITE_CONTENT_PAGES_VIEWS_EDITOR,
} from "../../../../constants/innerViews";
import CommonMethods from "../../../../utils/commonMethods";

interface IWebPagesToolBoxProps {
  dataTableColumns: IDataTableColumn[];
}

export const WebPagesToolBox: React.FC<IWebPagesToolBoxProps> = ({
  dataTableColumns,
}) => {
  const { t } = useTranslation([TRANSLATION_KEY.toString()]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  useEffect(() => {
    let rowSelectionPubSub = PubSub.subscribe(
      DATA_TABLE_ROW_SELECTION_CHANGED,
      (msg: string, selectedRowKeys: number[]) => {
        setSelectedRowKeys(selectedRowKeys);
      }
    );
    return () => {
      PubSub.unsubscribe(rowSelectionPubSub);
    };
  }, []);

  return (
    <Card title={t("webSiteContent-pages-webPages-tableName")}>
      <p>{t("webSiteContent-pages-webPages-tableDescription")}</p>

      {selectedRowKeys.length > 0 && (
        <p>
          {t("dataTable-selected", "Selected")}{" "}
          <strong>{selectedRowKeys.length}</strong>{" "}
          {t("dataTable-items", "items")}
        </p>
      )}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ToolBoxSearch dataTableColumns={dataTableColumns} />
        </Col>
        <Col span={24}>
          <ToolBoxCommonActions />
        </Col>

        <Col span={24}>
          {CommonMethods.IsAdminUser() ? (
            <ViewSelector views={WEBSITE_CONTENT_PAGES_VIEWS_ADMIN} />
          ) : (
            <ViewSelector views={WEBSITE_CONTENT_PAGES_VIEWS_EDITOR} />
          )}
        </Col>
      </Row>

      <ToolBoxColumnSelector
        dataTableColumns={dataTableColumns}
        settingsKey={SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_WEB_PAGES_KEY}
      />
      <ToolBoxStaticFilters
        tableDisplayName={t("webSiteContent-pages-webPages-tableName")}
        dataTableSettings={WEB_PAGES_DATATABLE_SETTINGS}
      />
      <WebPagesRowTools selectedRowKeys={selectedRowKeys} />
    </Card>
  );
};
