import React, { useState } from 'react';
import { Spin } from 'antd';

import { SHOW_HIDE_LOADER } from '../../constants/pubSubKeys';


export const Loader: React.FC = () => {
    const [isShow, setIsShow] = useState<boolean>(false);

    PubSub.subscribe(SHOW_HIDE_LOADER,(msg:string, isShow:boolean)=>{
        setIsShow(isShow);
    });


    return (
        isShow ?
            <div className="loader-container">
                <Spin size="large" />
            </div> : <></>
    )
}
