import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DatabaseOutlined } from '@ant-design/icons';

import OldApiService from "../../services/OldApiService";
import { ISystemInfo } from "../../types/Dashboard.types";
import { AUTH_SIGN_KEY, TRANSLATION_KEY } from "../../constants/commonConstants";



export const SystemWidget: React.FC = () => {
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [systemInfo, setSystemInfo] = useState<ISystemInfo>();

    useEffect(() => {
        getSystemInformation();
    }, []);

    const getSystemInformation = async () => {
        let systemInfo = await OldApiService.post(`${process.env.REACT_APP_BASE_URL}/api/edit/GetSystemInformation`,
            {
                "sign": localStorage.getItem(AUTH_SIGN_KEY)
            }
        );
        setSystemInfo(systemInfo);
    }


    return (
        <div id="systemWidget" className="widget-container">
            <div className="mb-4">
                <h2><DatabaseOutlined className="text-primary-contrast mr-2" />{t('dashboard-system')}</h2>
            </div>

            {
                systemInfo &&

                <table className="table-borderless">
                    <tbody>
                        <tr>
                            <td>{t('dashboard-cmsVersion')}</td>
                            <td>{systemInfo.CMSVersion}</td>
                        </tr>
                        <tr>
                            <td>{t('dashboard-systemFileSize')}</td>
                            <td>{(systemInfo.SystemFilesSize/1000000).toFixed(2)} MB</td>
                        </tr>
                        <tr>
                            <td>{t('dashboard-resourceFileSize')}</td>
                            <td>{(systemInfo.ResourceFilesSize/1000000).toFixed(2)} MB</td>
                        </tr>
                        <tr>
                            <td>{t('dashboard-totalSize')}</td>
                            <td> {((systemInfo.SystemFilesSize + systemInfo.ResourceFilesSize)/1000000).toFixed(2)} MB / {(systemInfo.AllocatedSize/1000000).toFixed(2)} MB</td>
                        </tr>
                        <tr>
                            <td>{t('dashboard-resourceFileCount')}</td>
                            <td>{systemInfo.ResourceFileCount}</td>
                        </tr>
                        <tr>
                            <td>{t('dashboard-databaseSize')}</td>
                            <td>{(systemInfo.DatabaseSize/1000000).toFixed(2)} MB</td>
                        </tr>
                        <tr>
                            <td>{t('dashboard-serverIpAddress')}</td>
                            <td>{systemInfo.ServerIP}</td>
                        </tr>
                    </tbody>
                </table>

            }

        </div>
    )
}