import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { EmailSettings } from "../pages/ExternalCommunication/EmailSettings";
import { FormData } from "../pages/ExternalCommunication/FormData";
import { UserRole } from "../types/enum/UserRole.enum";
import { PrivateRoute } from "./private-route";

export const ExternalCommunication: React.FC = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute
        path={`${path}/form-data`}
        component={FormData}
        userRoles={[UserRole.Admin, UserRole.Editor]}
      />
      <PrivateRoute
        path={`${path}/email-and-sms/email-templates`}
        component={EmailSettings}
        userRoles={[UserRole.Admin, UserRole.Editor]}
      />
    </Switch>
  );
};
