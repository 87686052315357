import React, {  useState } from 'react';
import { Card, Col, Row, Select, } from 'antd';
import { useTranslation } from 'react-i18next';

import { TRANSLATION_KEY } from '../../constants/commonConstants';
import { UniqueVisitors } from '../../components/GoogleAnalyticsCharts/UniqueVisitors';
import { TopPages } from '../../components/GoogleAnalyticsCharts/TopPages';
import { TopReferrals } from '../../components/GoogleAnalyticsCharts/TopReferrals';
import { TotalVisits } from '../../components/GoogleAnalyticsCharts/TotalVisits';
import { GOOGLE_ANALYTICS_TYPE_UA } from '../../constants/googleAnalytics';

const { Option } = Select;


const GRAPH_UNIQUE_VISITORS = 'unique_visitors';
const GRAPH_TOTAL_VISITS = 'total_visits';
const GRAPH_TOP_PAGES = 'top_pages';
const GRAPH_TOP_REFERRALS = 'top_referrals';

const TIME_SPAN_1_YEAR = '1_year';
const TIME_SPAN_6_MONTHS = '6_months';
const TIME_SPAN_3_MONTHS = '3_months';
const TIME_SPAN_30_DAYS = '30_days';
const TIME_SPAN_7_DAYS = '7_days';
const TIME_SPAN_1_DAY = '1_day';


export const GoogleAnalytics: React.FC = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [isShowError] = useState<boolean>(false);
    const [selectedGraph, setSelectedGraph] = useState<string>(GRAPH_UNIQUE_VISITORS);
    const [selectedTimeSpan, setSelectedTimeSpan] = useState<string>(TIME_SPAN_30_DAYS);


    const graphChanged = (graph: string) => {
        setSelectedGraph(graph);
    }

    const timeSpanChanged = (timeSpan: string) => {
        setSelectedTimeSpan(timeSpan);
    }

    const getStartDate = (selectedTimeSpan: string) => {
        switch (selectedTimeSpan) {
            case TIME_SPAN_1_YEAR:
                return '365daysAgo';

            case TIME_SPAN_6_MONTHS:
                return '270daysAgo';

            case TIME_SPAN_3_MONTHS:
                return '90daysAgo';

            case TIME_SPAN_30_DAYS:
                return '30daysAgo';

            case TIME_SPAN_7_DAYS:
                return '7daysAgo';

            case TIME_SPAN_1_DAY:
                return '1daysAgo';

            default:
                return '30daysAgo';
        }
    }

    const getEndDate = (selectedTimeSpan: string) => {
        switch (selectedTimeSpan) {
            case TIME_SPAN_1_YEAR:
            case TIME_SPAN_6_MONTHS:
            case TIME_SPAN_3_MONTHS:
            case TIME_SPAN_30_DAYS:
            case TIME_SPAN_7_DAYS:
            case TIME_SPAN_1_DAY:
                return 'today';
            default:
                return 'today';
        }
    }

    const renderGraph = () => {
        let startDate = getStartDate(selectedTimeSpan);
        let endDate = getEndDate(selectedTimeSpan);

        switch (selectedGraph) {
            case GRAPH_UNIQUE_VISITORS:
                return <UniqueVisitors fromDate={startDate} toDate={endDate} analyticsType={GOOGLE_ANALYTICS_TYPE_UA} />;

            case GRAPH_TOTAL_VISITS:
                return <TotalVisits fromDate={startDate} toDate={endDate}  analyticsType={GOOGLE_ANALYTICS_TYPE_UA} />;

            case GRAPH_TOP_PAGES:
                return <TopPages fromDate={startDate} toDate={endDate} analyticsType={GOOGLE_ANALYTICS_TYPE_UA} />;

            case GRAPH_TOP_REFERRALS:
                return <TopReferrals fromDate={startDate} toDate={endDate}  analyticsType={GOOGLE_ANALYTICS_TYPE_UA} />;
        }
    }


    return (
        <>
            <Row gutter={16}>
                <Col span={5} className="gutter-row">

                    <Card title={t('googleAnalytics')}>
                        <p>{t('googleAnalytics-description')}</p>
                    </Card>

                </Col>
                <Col span={19} className="gutter-row">

                    <Card title={t('googleAnalytics')}>

                        <Row>
                            <Col span={24}>
                                {t('googleAnalytics-graph')} &nbsp;&nbsp;&nbsp;
                                <Select value={selectedGraph} style={{ width: 300 }} onSelect={graphChanged}>
                                    <Option value={GRAPH_UNIQUE_VISITORS}>{t('googleAnalytics-uniqueVisitors')}</Option>
                                    <Option value={GRAPH_TOTAL_VISITS}>{t('googleAnalytics-totalVisits')}</Option>
                                    <Option value={GRAPH_TOP_PAGES}>{t('googleAnalytics-topPages')}</Option>
                                    <Option value={GRAPH_TOP_REFERRALS}>{t('googleAnalytics-topReferrals')}</Option>
                                </Select> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                {t('googleAnalytics-timeSpan')} &nbsp;&nbsp;
                                <Select value={selectedTimeSpan} style={{ width: 300 }} onChange={timeSpanChanged}>
                                    <Option value={TIME_SPAN_1_YEAR}>{t('googleAnalytics-1year')}</Option>
                                    <Option value={TIME_SPAN_6_MONTHS}>{t('googleAnalytics-6months')}</Option>
                                    <Option value={TIME_SPAN_3_MONTHS}>{t('googleAnalytics-3months')}</Option>
                                    <Option value={TIME_SPAN_30_DAYS}>{t('googleAnalytics-30days')}</Option>
                                    <Option value={TIME_SPAN_7_DAYS}>{t('googleAnalytics-7days')}</Option>
                                    <Option value={TIME_SPAN_1_DAY}>{t('googleAnalytics-1day')}</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row>
                            {
                                !isShowError &&
                                <Col span={24} className="mt-3">
                                    {
                                        renderGraph()
                                    }
                                </Col>
                            }
                            {
                                isShowError &&
                                <Col span={24} className="mt-3">
                                    <p className="text-center">{t('googleAnalytics-notConfiguredErrorMessage')}</p>
                                </Col>
                            }
                        </Row>

                    </Card>
                </Col>
            </Row>

        </>
    )
};


