import React, { useState } from "react";
import { Switch } from "antd";
import { useTranslation } from "react-i18next";

import { THEME_KEY } from "../../../constants/storageKeys";
import { ALL_THEMES, THEME_DARK, THEME_LIGHT, TRANSLATION_KEY } from "../../../constants/commonConstants";


export const ThemeSwitch: React.FC = () => {
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [theme, setTheme] = useState(localStorage[THEME_KEY] || THEME_LIGHT);

    const downloadTheme = (theme: string) => {
        return import(`../../../styles/ecms.${theme}.less`);
    }

    const updateBodyClass = (themeToAdd: string) => {
        ALL_THEMES.forEach((theme: string) => {
            document.body.classList.remove(theme);
        });
        document.body.classList.add(themeToAdd);
    }

    const changeTheme = (isDarkTheme: boolean) => {
        let newTheme = isDarkTheme ? THEME_DARK : THEME_LIGHT;
        downloadTheme(newTheme).then(() => {
            setTheme(newTheme);
            updateBodyClass(newTheme);
            localStorage[THEME_KEY] = newTheme;
        });
    }


    return (
        <Switch checkedChildren={t('common-light')} unCheckedChildren={t('common-dark')} checked={theme === THEME_DARK} onChange={changeTheme} />
    );

}