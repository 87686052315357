import React, { useEffect, useState } from "react";
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Button, List, Modal } from "antd";
import { DiffOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import Notify from "../../utils/notification-handler";
import CommonMethods from "../../utils/commonMethods";
import CommonService from "../../services/CommonService";
import { IPage } from "../../types/Page.types";
import { TRANSLATION_KEY } from "../../constants/commonConstants";



const { confirm } = Modal;

export const RecentlyUpdatedPagesWidget: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [webPages, setWebPages] = useState<IPage[]>([]);

    useEffect(() => {
        getWebpages();
    }, []);

    const getWebpages = async () => {
        const response = await CommonService.get(`data/PageData?$top=10&$select=id,name,title,description,lastEdited,edited,published,readyForPublish`);
        setWebPages(response);
    }

    const navigateToWebPages = () => {
        history.push('/website-content/pages/web-pages');
    }

    const publishWebPage = (updatingItem: IPage) => {
        if (updatingItem.published === true) {
            Notify.info(t('dashboard-pageIsAlreadyPublished'));
            return;
        }

        confirm({
            title: t('dashboard-publishWebpages'),
            icon: <ExclamationCircleOutlined />,
            content: `${t('dashboard-publishWebpagesConfirmation')} ?`,
            okText: t('common-yes', 'Yes'),
            okType: 'primary',
            cancelText: t('common-no', 'No'),
            onOk: () => publishWebPageConfirmed(updatingItem.id)
        });
    }

    const publishWebPageConfirmed = async (updatingItemId: number) => {
        let url = `data/PageData/${updatingItemId}`;
        const odataResponse = await CommonService.patch(url, { published: true });

        if (odataResponse === 0 || odataResponse === 1) {
            getWebpages();
            Notify.success(t('common-webpagePublishedSuccessfully'));
        } else {
            Notify.error(t('common-webpagePublishingFailed'));
        }

    }

    return (
        <div id="recentlyUpdatedPagesWidget" className="widget-container">
            <div>
                <h2><DiffOutlined className="text-primary-contrast mr-2" /> {t('dashboard-recentlyUpdatedWebPages')}</h2>
                <span className="widget-flag" onClick={navigateToWebPages}>{t('dashboard-showAllPages')}</span>
            </div>

            <List
                bordered={true}
                dataSource={webPages}
                renderItem={item => (
                    <List.Item>
                        <div className="mr-3 date">{item.lastEdited ? (format(new Date(item.lastEdited as Date), 'yyyy-dd-MM')) : ''}</div>
                        <List.Item.Meta
                            title={item.title}
                            description={
                                <>
                                    <p className="mb-0"><b>{item.name}</b> | {CommonMethods.getWebPageStatusTag(item)}</p>
                                    <p>{item.description}</p>
                                </>
                            }
                        />
                        <div><Button size="small" onClick={() => publishWebPage(item)} type="default">{t('dashboard-publish')}</Button></div>
                    </List.Item>
                )}
            />
        </div>
    )
}