import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Collapse, Divider, Space } from "antd";

import DynamicIconHandler from "../../../utils/dynamic-icon-handler";
import { IDataTableSettings, IStaticFilter, IStaticFilterPubSubData } from "../../../types/DataTable.types";
import { TRANSLATION_KEY } from "../../../constants/commonConstants";
import { DATA_TABLE_STATIC_FILTER } from "../../../constants/pubSubKeys";


const { Panel } = Collapse;


interface IToolBoxStaticFiltersProps {
    tableDisplayName: string;
    dataTableSettings: IDataTableSettings;
}

export const ToolBoxStaticFilters: React.FC<IToolBoxStaticFiltersProps> = ({
    tableDisplayName,
    dataTableSettings
}) => {
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [activeFilter, setActiveFilter] = useState<IStaticFilter | null>(null);


    const staticFilterClicked = (filter: IStaticFilter) => {
        setActiveFilter(filter);
        let staticFilterPubSubData: IStaticFilterPubSubData = {
            filter: {
                columnName: filter.propertyDataIndex,
                value: filter.propertyValue
            }
        };
        PubSub.publish(DATA_TABLE_STATIC_FILTER, staticFilterPubSubData);
    }


    const isFilterActive = (filterItem: IStaticFilter) => {
        if (
            activeFilter !== null &&
            activeFilter.propertyDataIndex === filterItem.propertyDataIndex &&
            activeFilter.propertyValue === filterItem.propertyValue
        ) {
            return true;
        }
        return false;
    }

    return (
        <>
            {
                dataTableSettings.staticFilters && dataTableSettings.staticFilters.length > 0 &&
                <>
                    <Divider />
                    <Collapse>
                        <Panel header={`${t('dataTable-advancedFiltersFor', 'Advanced filters for')} ${tableDisplayName}`} key='1' className="toolbox-collapse-panel">
                            <Space size={[9, 13]} wrap>
                                {
                                    dataTableSettings.staticFilters.map((filterItem: IStaticFilter, index: number) => {

                                        return (
                                            <Button key={index}
                                                type={isFilterActive(filterItem) === true ? 'primary' : 'default'}
                                                onClick={() => staticFilterClicked(filterItem)}>
                                                {DynamicIconHandler.AntIcon({ type: filterItem.iconName, className: (isFilterActive(filterItem) === true ? 'text-white' : 'text-blue') })}
                                                {t(filterItem.languageKey)}
                                            </Button>
                                        )
                                    })
                                }
                            </Space>
                        </Panel>
                    </Collapse>
                </>


            }

        </>
    );

}