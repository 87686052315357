import { IViewSelectorItem } from "../types/Common.types";
import { IDataTableColumn, IDataTableSettings } from "../types/DataTable.types";
import { ARRAY, BOOLEAN, DATETIME, INT, STRING } from "./commonConstants";

export const SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_USERS_KEY: string = 'SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_USERS';


export const USERS_DATATABLE_SETTINGS: IDataTableSettings = {
    defaultSortColumn: 'id',
    dataEndpoint: 'UserData',
    staticFilters: [
        {
            languageKey: "pages-filter-viewAll",
            iconName: "TableOutlined",
            propertyDataIndex: "",
            propertyValue: null
        },
    ]
}

export const USERS_DATATABLE_COLUMNS: IDataTableColumn[] = [
    {
        key: 'id',
        dataIndex: 'id',
        languageKey: '',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: INT,
        isMandatory: true,
        isHiddenFromTableDisplay: true,
        isNotExport: true
    },
    {
        key: 'name',
        dataIndex: 'name',
        languageKey: 'user-username',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING,
        isImportable: true,
        isRequiredOnImport: true
    },
    {
        key: 'email',
        dataIndex: ['membership', 'email'],
        languageKey: 'user-emailAddress',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING,
        isImportable: true,
        isRequiredOnImport: true
    },
    {
        key: 'firstName',
        dataIndex: ['profile', 'firstName'],
        languageKey: 'user-firstName',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING,
        isImportable: true,
        isRequiredOnImport: true
    },
    {
        key: 'lastName',
        dataIndex: ['profile', 'lastName'],
        languageKey: 'user-surName',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING,
        isImportable: true,
        isRequiredOnImport: true
    },
    {
        key: 'roleIds',
        dataIndex: 'roleIds',
        languageKey: 'user-roles',
        isVisible: true,
        isSortable: false,
        isSearchable: false,
        dataType: ARRAY,
        isCustomDisplay: true,
        isCustomDisplayExcel: true,
        isMandatory: false,
        isImportable: true,
        isRequiredOnImport: true
    },
    {
        key: 'personalId',
        dataIndex: ['profile', 'personalId'],
        languageKey: 'user-personalId',
        isVisible: false,
        isSortable: true,
        isSearchable: true,
        dataType: STRING,
        isImportable: true
    },
    {
        key: 'pageId',
        dataIndex: ['profile', 'pageId'],
        languageKey: 'user-homepageId',
        isVisible: false,
        isSortable: true,
        isSearchable: true,
        dataType: INT,
        isImportable: true
    },
    {
        key: 'phone',
        dataIndex: ['profile', 'phone'],
        languageKey: 'user-mobileNumber',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING,
        isImportable: true
    },
    {
        key: 'zip',
        dataIndex: ['profile', 'zip'],
        languageKey: 'user-postalcode',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING,
        isImportable: true
    },
    {
        key: 'address',
        dataIndex: ['profile', 'address'],
        languageKey: 'user-address',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING,
        isImportable: true
    },
    {
        key: 'city',
        dataIndex: ['profile', 'city'],
        languageKey: 'user-city',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING,
        isImportable: true
    },
    {
        key: 'lastLoginDate',
        dataIndex: ['membership', 'lastLoginDate'],
        languageKey: 'user-lastLoggedIn',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: DATETIME
    },
    {
        key: 'creationDate',
        dataIndex: ['membership', 'creationDate'],
        languageKey: 'user-created',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: DATETIME
    },
    {
        key: 'isLockedOut',
        dataIndex: ['membership', 'isLockedOut'],
        languageKey: 'user-locked',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: BOOLEAN
    },
    {
        key: 'userRoles_roleId',
        dataIndex: ['userRoles', 'roleId'],
        languageKey: 'user-roles',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: ARRAY,
        isMandatory: true,
        isNotExport: true,
        isImportable:false
    },
];


export const USERS_VIEWS: IViewSelectorItem[] = [
    { languageKey: 'secondaryNavMenu-users', path: '/site-content/users' },
    { languageKey: 'secondaryNavMenu-userKeys', path: '/site-content/user-keys' },
    { languageKey: 'secondaryNavMenu-roles', path: '/site-content/roles' },
]