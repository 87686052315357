import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Space } from 'antd';
import { SwapOutlined } from '@ant-design/icons';

import Notify from '../../../../utils/notification-handler';
import { TRANSLATION_KEY } from '../../../../constants/commonConstants';
import { IRedirect } from '../../../../types/Redirects.types';
import CommonService from '../../../../services/CommonService';
import { Redirect } from 'react-router';
import { REDIRECTS_DATATABLE_SETTINGS } from '../../../../constants/redirectsConstants';


export interface ICreateRedirectRowToolProps {
    selectedRowKeys: number[];
    className?: string;
}

export const CreateRedirectRowTool: React.FC<ICreateRedirectRowToolProps> = ({
    selectedRowKeys,
    className
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [form] = Form.useForm<IRedirect>();
    const [isShowActionModal, setIsShowActionModal] = useState<boolean>(false);
    const [isLoading] = useState<boolean>(false);

    const [isShowTargetPageId, setIsShowTargetPageId] = useState<boolean>(true);
    const [isShowTargetUrl, setIsShowTargetUrl] = useState<boolean>(true);


    const onFinish = async (updatingItem: IRedirect) => {
        let redirects: Redirect[] = [];

        selectedRowKeys.forEach(element => {
            
        });

        bulkInsert(redirects);

    };


    const bulkInsert = async (model: Redirect[]) => {
        const odataResponse = await CommonService.post(`data​/${REDIRECTS_DATATABLE_SETTINGS.dataEndpoint}/Bulk​/Insert`, model);
        debugger
        if (odataResponse === true) {
            //  Notify.success(t('dataTable-dataDeletedSuccessfully', 'Data deleted successfully'));
        } else {
            // Notify.error(t('dataTable-dataDeletingFailed', 'Data deleting failed'));
        }
    }

    const actionBtnClicked = async () => {
        if (selectedRowKeys.length <= 0) {
            Notify.info(
                t('dataTable-notification-noItemsSelectedToDelete-content'),
                t('dataTable-notification-noItemsSelectedToDelete-title')
            );
            return;
        }
        setIsShowActionModal(true);
    }

    const closeModal = () => {
        setIsShowActionModal(false);
    }

    const onTargetPageIdChange = (value: number) => {
        if (value !== null && value !== 0) {
            form.setFieldsValue({ customUrl: '' });
            setIsShowTargetUrl(false);
        } else {
            setIsShowTargetUrl(true);
        }
    }

    const onTargetPageUrlChange = (value: string) => {
        if (value !== '') {
            form.setFieldsValue({ pageId: 0 });
            setIsShowTargetPageId(false);
        } else {
            setIsShowTargetPageId(true);
        }
    }


    return (
        <>
            <Button onClick={actionBtnClicked} className={className}>
                <SwapOutlined className="text-blue" /> {t('webSiteContent-pages-pageNotFoundErrors-createRedirect')}
            </Button>
            {
                <Modal title={t('externalCommunication-formData-updateStatus')}
                    visible={isShowActionModal} footer={null} onCancel={closeModal}
                    okText={t('common-yes')} cancelText={t('common-no')}>

                    <Form form={form}
                        name="data-table-item-update-form"
                        layout="vertical"
                        onFinish={onFinish}>

                        <Row gutter={24}>

                            {
                                isShowTargetPageId &&
                                <Col span={24}>
                                    <Form.Item
                                        name="pageId"
                                        label={t('redirects-targetPageId')}
                                        required={true}
                                        rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                        <InputNumber onChange={onTargetPageIdChange} className="w-100" />
                                    </Form.Item>
                                </Col>
                            }
                            {
                                isShowTargetUrl &&
                                <Col span={24}>
                                    <Form.Item
                                        name="customUrl"
                                        label={t('redirects-targetUrl')}
                                        required={true}
                                        rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                        <Input onChange={(e) => onTargetPageUrlChange(e.target.value)} />
                                    </Form.Item>
                                </Col>
                            }

                        </Row>

                        <Divider />

                        <Row justify="end">
                            <Col span={24}>
                                <Space className="data-table-item-update-modal-footer">
                                    <Button onClick={closeModal}>
                                        {t('common-cancel')}
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={isLoading}>
                                        {t('common-save')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>

                </Modal>
            }
        </>
    )
}