import { Checkbox, Table } from "antd";
import React, { useEffect, useState } from "react";
import Moment from 'moment';
import { useTranslation } from "react-i18next";
import { Key, SortOrder } from 'antd/lib/table/interface';

import CommonService from "../../../services/CommonService";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import Loader from "../../../utils/loader-handler";
import ExcelHandler from "../../../utils/excel-handler";
import { IFormPostData, IFormPostDetailsData } from "../../../types/FormData.types";

import { ARRAY, ASCEND, BOOLEAN, DATE, DATETIME, DESCEND, INT, STRING, TRANSLATION_KEY } from "../../../constants/commonConstants";
import {
    DATA_TABLE_EXPORT, DATA_TABLE_RELOAD, DATA_TABLE_RESET_AND_RELOAD, DATA_TABLE_RESET_TOOL_BOX_SEARCH, DATA_TABLE_ROW_SELECTION_CHANGED,
    DATA_TABLE_SEARCH_TEXT_FILTER, DATA_TABLE_SHOW_UPDATE_MODAL
} from "../../../constants/pubSubKeys";
import Highlighter from "react-highlight-words";


export interface IFormDataTableColumn {
    title: string;
    dataIndex: string;
    className?: string;
    sorter?: (a: any, b: any) => any;
    onFilter?: any;
    dataType?: string;
}

export interface IFormDataTableProps {
    selectedFormId: number;
}

export const FormDataTable: React.FC<IFormDataTableProps> = ({ selectedFormId }) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [dataTableColumns, setDataTableColumns] = useState<IFormDataTableColumn[]>([]);
    const [dataTableData, setDataTableData] = useState<any[]>([]);
    const [dataTableDataFiltered, setDataTableDataFiltered] = useState<any[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [searchText, setSearchText] = useState<string>('');


    const rowSelection = {
        selectedRowKeys: selectedRowKeys, onChange: (selectedRowKeys: Key[]) => {
            PubSub.publish(DATA_TABLE_ROW_SELECTION_CHANGED, selectedRowKeys as number[]);
            setSelectedRowKeys(selectedRowKeys);
        }
    };


    useEffect(() => {
        setSearchText('');
        PubSub.publish(DATA_TABLE_RESET_TOOL_BOX_SEARCH, {});
        generateTableColumnsAndData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFormId]);


    useEffect(() => {
        const reloadTable = async () => { 
            setSearchText('');
            PubSub.publish(DATA_TABLE_RESET_TOOL_BOX_SEARCH, {});
            setDataTableDataFiltered(dataTableData);
        }

        const filterTableBySearchTextFilters = (_msg: string, searchTextFilterValue: string) => {
            setSearchText(searchTextFilterValue);
            let filteredTableDta = dataTableData.filter(o =>
                Object.keys(o).some(k => o[k]?.toString().toLowerCase().includes(searchTextFilterValue.toLowerCase())));
            setDataTableDataFiltered(filteredTableDta);
        }

        let reloadTablePubSub = PubSub.subscribe(DATA_TABLE_RELOAD, reloadTable);
        let resetAndReloadPubSub = PubSub.subscribe(DATA_TABLE_RESET_AND_RELOAD, reloadTable);
        let searchTextFilterPubSub = PubSub.subscribe(DATA_TABLE_SEARCH_TEXT_FILTER, filterTableBySearchTextFilters);
        let exportTablePubSub = PubSub.subscribe(DATA_TABLE_EXPORT, () => {
            Loader.show();
            ExcelHandler.writeToFile(dataTableDataFiltered, `table-data`);
            Loader.hide();
        });


        return () => {
            PubSub.unsubscribe(reloadTablePubSub);
            PubSub.unsubscribe(resetAndReloadPubSub);
            PubSub.unsubscribe(searchTextFilterPubSub);
            PubSub.unsubscribe(exportTablePubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });


    const generateTableColumnsAndData = async () => {
        let formPostData = await getFormPostData();
        generateDataTableColumns(formPostData);
        generateDataTableData(formPostData);
    }

    const generateDataTableColumns = (formPostData: IFormPostData[]) => {

        let dataTableColumns: IFormDataTableColumn[] = [

            {
                title: t('formData-id'),
                dataIndex: 'id',
                className: 'data-display-column',
                sorter: (a, b) => a.id - b.id,
                onFilter: (value: any, record: any): any => record.name.includes(value),
                dataType: INT
            },
            {
                title: t('formData-status'),
                dataIndex: 'status',
                className: 'data-display-column',
                sorter: (a, b) => {
                    if (a.status < b.status) { return -1; }
                    if (a.status > b.status) { return 1; }
                    return 0;
                },
                dataType: STRING
            },
            {
                title: t('formData-date'),
                dataIndex: 'posted',
                className: 'data-display-column',
                sorter: (a, b) => {
                    if (a.posted < b.posted) { return -1; }
                    if (a.posted > b.posted) { return 1; }
                    return 0;
                },
                dataType: DATE
            },
            {
                title: t('formData-ip'),
                dataIndex: 'ip',
                className: 'data-display-column',
                sorter: (a, b) => {
                    if (a.ip < b.ip) { return -1; }
                    if (a.ip > b.ip) { return 1; }
                    return 0;
                },
            }
        ];

        if (formPostData.length > 0 && formPostData[0].details.length > 0) {

            formPostData[0].details.forEach((detail: IFormPostDetailsData) => {

                let newDataTableColumn: IFormDataTableColumn = {
                    title: detail.name,
                    dataIndex: detail.name,
                    className: 'data-display-column',
                    sorter: (a, b) => {
                        if (a[detail.name] < b[detail.name]) { return -1; }
                        if (a[detail.name] > b[detail.name]) { return 1; }
                        return 0;
                    },
                }
                dataTableColumns.push(newDataTableColumn);

            });
        }

        setDataTableColumns(dataTableColumns);
    }


    const generateDataTableData = (formPostData: IFormPostData[]) => {
        let dataTableData: any[] = [];

        formPostData.forEach((formPostDataItem: IFormPostData) => {

            let newdataTableDataItem: any = {
                id: formPostDataItem.id,
                status: formPostDataItem.status,
                posted: formPostDataItem.posted,
                ip: formPostDataItem.ip,
                formId: formPostDataItem.formId
            }

            formPostDataItem.details.forEach((detail: IFormPostDetailsData) => {
                newdataTableDataItem[detail.name] = detail.value;
            });

            dataTableData.push(newdataTableDataItem);

        });

        setDataTableData(dataTableData);
        setDataTableDataFiltered(dataTableData);
    }

   const getColumnRender = (value: any, sourceDataType?: string) => {
        let displayValue: string = '';

        switch (sourceDataType) {
            case BOOLEAN:
                return (value != null) ? <Checkbox checked={value} disabled></Checkbox> : <></>;
            case DATE:
                displayValue = value ? (Moment(value).format('YYYY-MM-DD')) : '';
                break;
            case DATETIME:
                displayValue = value ? (Moment(value).format('YYYY-MM-DD, HH:mm')) : '';
                break;
            case ARRAY:
                return (value) ? value.length : <></>;
            default:
                displayValue = value;
        }

        return <Highlighter
            highlightClassName="text-highlight"
            searchWords={[searchText]}
            autoEscape
            textToHighlight={displayValue ? displayValue.toString() : ''}
        />
    }

    const rowClicked = (updatingItemId: number) => {
        let updatingItem = dataTableData.find((d: any) => d.id === updatingItemId);
        if (updatingItem) {
            PubSub.publish(DATA_TABLE_SHOW_UPDATE_MODAL, updatingItem);
        }
    }

    const getFormPostData = async () => {
        let url = `data/FormPostData?$filter=formId eq ${selectedFormId}&$expand=details`;
        const response = await CommonService.get(url);
        return response;
    }

    return (
        <>
            {
                dataTableColumns && dataTableDataFiltered &&
                <div className="data-table-container">
                    <Table
                        rowKey="id"
                        scroll={{ x: 'max-content', y: 'calc(100vh - 360px)' }}
                        rowSelection={rowSelection}
                        dataSource={dataTableDataFiltered} >

                        <Table.Column title={''} dataIndex="edit-icon-column"
                            render={(text: string, record: any) => <EditOutlined className="text-blue" onClick={() => rowClicked(record.id)} />}
                        />

                        {
                            dataTableColumns.map((item: IFormDataTableColumn, index: number) => {
                                return (
                                    <Table.Column key={index}
                                        title={item.title}
                                        dataIndex={item.dataIndex}
                                        className="data-display-column"
                                        sorter={item.sorter}
                                        render={(value: any) => getColumnRender(value, item.dataType)}
                                        sortDirections={[DESCEND, ASCEND] as SortOrder[]}
                                    />
                                );
                            })
                        }
                    </Table>
                </div>
            }
        </>
    );

}

