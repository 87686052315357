import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Space } from 'antd';

import { CommonRowToolItem } from '../../../Common/DataTableToolBox/CommonRowToolItem';
import { DeleteRowTool } from '../../../Common/DataTableToolBox/DeleteRowTool';
import { TRANSLATION_KEY } from '../../../../constants/commonConstants';
import { WEB_PAGES_DATATABLE_SETTINGS } from '../../../../constants/webPagesConstants';
import { WebPageMoveRowTool } from './WebPageMoveRowTool';


const { Panel } = Collapse;

export interface IWebPagesRowToolsProps {
    selectedRowKeys: number[];
}

export const WebPagesRowTools: React.FC<IWebPagesRowToolsProps> = ({
    selectedRowKeys
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);


    return (
        <Collapse>
            <Panel header={`${t('rowTool-title-rowToolsFor')} ${t('webSiteContent-pages-webPages-tableName')}`} key='1' className="toolbox-collapse-panel">
                <Space size={[9, 13]} wrap>
                    <CommonRowToolItem
                        bulkAction={{
                            languageKey: "pages-rowtool-removeWebPages",
                            iconName: "StopOutlined",
                            descriptionLanguageKey: "pages-rowtool-removeWebPages-description",
                            properties: [
                                { propertyDataIndex: "inTrash", propertyValue: true }
                            ]
                        }}
                        selectedRowKeys={selectedRowKeys}
                        dataEndpoint={WEB_PAGES_DATATABLE_SETTINGS.dataEndpoint} />

                    <WebPageMoveRowTool selectedRowKeys={selectedRowKeys} />

                    <CommonRowToolItem
                        bulkAction={{
                            languageKey: "pages-rowtool-publishWebPages",
                            iconName: "CheckCircleOutlined",
                            descriptionLanguageKey: "pages-rowtool-publishWebPages-description",
                            properties: [
                                { propertyDataIndex: "published", propertyValue: true }
                            ]
                        }}
                        selectedRowKeys={selectedRowKeys}
                        dataEndpoint={WEB_PAGES_DATATABLE_SETTINGS.dataEndpoint} />

                    <CommonRowToolItem
                        bulkAction={{
                            languageKey: "pages-rowtool-unPublishWebPages",
                            iconName: "CloseCircleOutlined",
                            descriptionLanguageKey: "pages-rowtool-unPublishWebPages-description",
                            properties: [
                                { propertyDataIndex: "published", propertyValue: false }
                            ]
                        }}
                        selectedRowKeys={selectedRowKeys}
                        dataEndpoint={WEB_PAGES_DATATABLE_SETTINGS.dataEndpoint} />

                    <DeleteRowTool selectedRowKeys={selectedRowKeys} dataEndpoint={WEB_PAGES_DATATABLE_SETTINGS.dataEndpoint} />

                    <CommonRowToolItem
                        bulkAction={{
                            languageKey: "pages-rowtool-updateHideInMenus",
                            iconName: "EyeInvisibleOutlined",
                            descriptionLanguageKey: "pages-rowtool-updateHideInMenus-description",
                            properties: [
                                { propertyDataIndex: "hidden", propertyValue: true }
                            ]
                        }}
                        selectedRowKeys={selectedRowKeys}
                        dataEndpoint={WEB_PAGES_DATATABLE_SETTINGS.dataEndpoint} />



                </Space>
            </Panel>
        </Collapse>
    )
}