import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'antd';
import PubSub from 'pubsub-js';

import { ViewSelector } from "../../../Common/ViewSelector";
import { IDataTableColumn } from "../../../../types/DataTable.types";
import { ToolBoxSearch } from "../../../Common/DataTableToolBox/ToolBoxSearch";
import { DeleteRowTool } from "../../../Common/DataTableToolBox/DeleteRowTool";
import { ToolBoxCommonActions } from "../../../Common/DataTableToolBox/ToolBoxCommonActions";
import { ToolBoxColumnSelector } from "../../../Common/DataTableToolBox/ToolBoxColumnSelector";

import { TRANSLATION_KEY } from '../../../../constants/commonConstants';
import { WEBSITE_CONTENT_USERS_VIEWS } from "../../../../constants/innerViews";
import { DATA_TABLE_ROW_SELECTION_CHANGED } from "../../../../constants/pubSubKeys";
import { ROLES_DATATABLE_SETTINGS, SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_ROLES_KEY } from "../../../../constants/rolesConstants";



interface IRolesToolBoxProps {
    dataTableColumns: IDataTableColumn[];
}


export const RolesToolBox: React.FC<IRolesToolBoxProps> = ({
    dataTableColumns
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);


    useEffect(() => {
        let rowSelectionPubSub = PubSub.subscribe(DATA_TABLE_ROW_SELECTION_CHANGED, (msg: string, selectedRowKeys: number[]) => {
            setSelectedRowKeys(selectedRowKeys);
        });
        return () => {
            PubSub.unsubscribe(rowSelectionPubSub);
        }
    }, []);


    return (
        <Card title={t('webSiteContent-users-roles-tableName')}>
            <p>{t('webSiteContent-users-roles-tableDescription')}</p>

            {
                selectedRowKeys.length > 0 &&
                <p>{t('dataTable-selected')} <strong>{selectedRowKeys.length}</strong> {t('dataTable-items')}</p>
            }

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <ToolBoxSearch dataTableColumns={dataTableColumns} />
                </Col>
                <Col span={24}>
                    <ToolBoxCommonActions isShowExport={false} isShowImport={false} isShowCopy={false}/>
                    <DeleteRowTool className="mt-13-px" selectedRowKeys={selectedRowKeys} dataEndpoint={ROLES_DATATABLE_SETTINGS.dataEndpoint} />
                </Col>

                <Col span={24}>
                    <ViewSelector views={WEBSITE_CONTENT_USERS_VIEWS} />
                </Col>

            </Row>

            <ToolBoxColumnSelector dataTableColumns={dataTableColumns} settingsKey={SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_ROLES_KEY}/>

        </Card>
    );

}

