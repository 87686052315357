import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';

import CommonService from '../services/CommonService';
import { NewsFeedWidget } from '../components/Dashboard/NewsFeedWidget';
import { UserDataWidget } from '../components/Dashboard/UserDataWidget';
import { RecentlyUpdatedPagesWidget } from '../components/Dashboard/RecentlyUpdatedPagesWidget';
import { SystemWidget } from '../components/Dashboard/SystemWidget';
import { HelpWidget } from '../components/Dashboard/HelpWidget';
import { GoogleAnalyticsWidget } from '../components/Dashboard/GoogleAnalyticsWidget';
import { ISettingsItem } from '../types/Common.types';

import { SITE_URL } from '../constants/settingsConstant';


export const Dashboard: React.FC = () => {
    const [siteUrl, setSiteUrl] = useState<string>('');

    useEffect(() => {
        const getAndSetSiteUrl = async () => {
            let response: ISettingsItem[] = await CommonService.post(`api/system/settingsList`, [SITE_URL]);
            if (response && response[0].value) {
                setSiteUrl(response[0].value);
            }
        }
        getAndSetSiteUrl();
    }, []);


    return (
        <div className="spaced-container">
            <Row gutter={[20, 20]} className="dashboard-header-row">
                <Col span={6}>
                    <h1>{siteUrl}</h1>
                </Col>
            </Row>
            <Row gutter={[20, 20]}>
                <Col span={8}><UserDataWidget /></Col>
                <Col span={16}><GoogleAnalyticsWidget /> </Col>
                <Col span={8}><HelpWidget /></Col>
                <Col span={16}><RecentlyUpdatedPagesWidget /></Col>
                <Col span={8}><SystemWidget /></Col>
                <Col span={16}><NewsFeedWidget /></Col>
            </Row>

        </div>
    )
};


