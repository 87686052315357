import React from "react";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from '@ant-design/icons';
import { Button, Select, Space } from "antd";

import { TRANSLATION_KEY } from "../../../constants/commonConstants";
import { DATA_TABLE_COLUMN_FILTER } from "../../../constants/pubSubKeys";
import { IColumnFilterPubSubData } from "../../../types/DataTable.types";
import { FilterConfirmProps, FilterDropdownProps } from "antd/lib/table/interface";
import { ILanguageData } from "../../../types/LanguageData.types";


const { Option } = Select;

export interface IPropertyChangeLogLangColumnFilter {
  dataIndex: string;
  filterDropdownProps: FilterDropdownProps;
  languageDataLookups:ILanguageData[];
}

export const PropertyChangeLogLangColumnFilter: React.FC<IPropertyChangeLogLangColumnFilter> = ({
  dataIndex,
  filterDropdownProps,
  languageDataLookups
}) => {

  const { t } = useTranslation([TRANSLATION_KEY.toString()]);


  const handleSearch = (selectedKeys: React.Key[], confirm: (param?: FilterConfirmProps | undefined) => void) => {
    confirm();
    let columnFilterPubSubData: IColumnFilterPubSubData = {
        filter: {
            columnName: dataIndex,
            value: (selectedKeys[0] !== undefined) ? selectedKeys[0] : null
        }
    };
    PubSub.publish(DATA_TABLE_COLUMN_FILTER, columnFilterPubSubData);
}


const handleReset = (clearFilters: (() => void) | undefined) => {
    if (clearFilters) {
        clearFilters();
    }

    let columnFilterPubSubData: IColumnFilterPubSubData = {
        filter: {
            columnName: dataIndex,
            value: null
        }
    };
    PubSub.publish(DATA_TABLE_COLUMN_FILTER, columnFilterPubSubData);
}

const customFilterOnChange =(value:any)=>{
  filterDropdownProps.setSelectedKeys(value ? [value] : []);
}

  return (
    <div className="data-table-column-filter-container">
      <Select className="display-block mb-4" placeholder={`${t('common-search')} ${t('page-status')}`}
       value={filterDropdownProps.selectedKeys[0]}  onChange={(e) => customFilterOnChange(e)}>
        {
          languageDataLookups.map(language => {
            return <Option key={language.id} value={language.id}>{language.name}</Option>
          })
        }
      </Select>

      <Space>
        <Button type="primary" className="search-btn" size="small" icon={<SearchOutlined />}
          onClick={() => handleSearch(filterDropdownProps.selectedKeys, filterDropdownProps.confirm)}>
          {t('common-search', 'Search')}
        </Button>
        <Button className="reset-btn" size="small" onClick={() => handleReset(filterDropdownProps.clearFilters)} >
          {t('common-reset', 'Reset')}
        </Button>
      </Space>
    </div>
  )
}