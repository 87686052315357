import { IDataTableColumn, IDataTableSettings } from "../types/DataTable.types";
import CommonMethods from "../utils/commonMethods";
import { BOOLEAN, DATETIME, INT, STRING } from "./commonConstants";


export const SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_WEB_PAGES_KEY: string = 'SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_WEB_PAGES';


export const WEB_PAGES_DATATABLE_SETTINGS: IDataTableSettings = {
    defaultSortColumn: 'id',
    dataEndpoint: 'PageData',
    staticFilters: [
        {
            languageKey: "pages-filter-viewAll",
            iconName: "TableOutlined",
            propertyDataIndex: "",
            propertyValue: null
        },
        {
            languageKey: "pages-filter-trashed",
            iconName: "DeleteOutlined",
            propertyDataIndex: "inTrash",
            propertyValue: true
        },
        {
            languageKey: "pages-filter-hidden",
            iconName: "EyeInvisibleOutlined",
            propertyDataIndex: "hidden",
            propertyValue: true
        },
        {
            languageKey: "pages-filter-edited",
            iconName: "EditOutlined",
            propertyDataIndex: "edited",
            propertyValue: true
        },
        {
            languageKey: "pages-filter-unpublished",
            iconName: "StopOutlined",
            propertyDataIndex: "published",
            propertyValue: false
        },
        {
            languageKey: "pages-filter-published",
            iconName: "CheckOutlined",
            propertyDataIndex: "published",
            propertyValue: true

        },
        {
            languageKey: "pages-filter-myPages",
            iconName: "UserAddOutlined",
            propertyDataIndex: "createdBy",
            propertyValue: CommonMethods.getCurrentUsersUsername()

        }
    ]
}


export const WEB_PAGES_DATATABLE_COLUMNS: IDataTableColumn[] = [
    {
        key: 'id',
        dataIndex: 'id',
        languageKey: 'page-id',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: INT
    },
    {
        key: 'name',
        dataIndex: 'name',
        languageKey: 'page-name',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        isImportable: true,
        dataType: STRING
    },
    {
        key: 'pageStatus',
        dataIndex: 'pageStatus',
        languageKey: 'page-status',
        isVisible: true,
        isSortable: false,
        isSearchable: false,
        dataType: STRING,
        isCustomDisplay: true,
        isCustomDisplayExcel: true,
        isCustomFilter:true,
        isMandatory: false,
        isOverrideOdataQueries:{ isOverrideFilterQuery:true}
    },
    {
        key: 'parentId',
        dataIndex: 'parentId',
        languageKey: 'page-parentId',
        isVisible: true,
        isSortable: false,
        isSearchable: false,
        isImportable: true,
        dataType: INT,
        isCustomDisplay: true,
    },
    {
        key: 'published',
        dataIndex: 'published',
        languageKey: 'page-published',
        isVisible: true,
        isSortable: false,
        isSearchable: false,
        dataType: BOOLEAN,
        isMandatory:true
    },
    {
        key: 'publishDate',
        dataIndex: 'publishDate',
        languageKey: 'page-publishedDate',
        isVisible: true,
        isSortable: false,
        isSearchable: false,
        dataType: DATETIME
    },
    {
        key: 'lastPublish',
        dataIndex: 'lastPublish',
        languageKey: 'page-lastPublishedDate',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: DATETIME
    },
    {
        key: 'unPublishDate',
        dataIndex: 'unPublishDate',
        languageKey: 'page-unpublishedDate',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: DATETIME
    },
    {
        key: 'edited',
        dataIndex: 'edited',
        languageKey: 'page-edited',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: BOOLEAN,
        isMandatory:true
    },
    {
        key: 'lastEdited',
        dataIndex: 'lastEdited',
        languageKey: 'page-lastEdited',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: DATETIME
    },
    {
        key: 'readyForPublish',
        dataIndex: 'readyForPublish',
        languageKey: 'page-readyForPublish',
        isVisible: true,
        isSortable: false,
        isSearchable: false,
        dataType: BOOLEAN
    },
    {
        key: 'metadata',
        dataIndex: 'metadata',
        languageKey: 'page-metadata',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        isImportable: true,
        isRequiredOnImport: true,
        dataType: STRING
    },
    {
        key: 'createdBy',
        dataIndex: 'createdBy',
        languageKey: 'page-createdBy',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'created',
        dataIndex: 'created',
        languageKey: 'page-dateCreated',
        isVisible: true,
        isSortable: false,
        isSearchable: false,
        dataType: DATETIME
    },
    {
        key: 'editedBy',
        dataIndex: 'editedBy',
        languageKey: 'page-editedBy',
        isVisible: false,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'requiredRole',
        dataIndex: 'requiredRole',
        languageKey: 'page-requiredRole',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: STRING
    },
    {
        key: 'editRole',
        dataIndex: 'editRole',
        languageKey: 'page-editRole',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: STRING
    },
    {
        key: 'publishRole',
        dataIndex: 'publishRole',
        languageKey: 'page-publishedRole',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: STRING
    },
    {
        key: 'hidden',
        dataIndex: 'hidden',
        languageKey: 'page-hideInMenus',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        isImportable: true,
        dataType: BOOLEAN
    },
    {
        key: 'seoword',
        dataIndex: 'seoword',
        languageKey: 'page-seoWords',
        isVisible: false,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'seoscore',
        dataIndex: 'seoscore',
        languageKey: 'page-seoScore',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: STRING
    },
    {
        key: 'description',
        dataIndex: 'description',
        languageKey: 'page-description',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        isImportable: true,
        dataType: STRING
    },
    {
        key: 'page',
        dataIndex: 'page',
        languageKey: 'page-template',
        isVisible: false,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'virtualUrl',
        dataIndex: 'virtualUrl',
        languageKey: 'page-friendlyUrl',
        isVisible: false,
        isSortable: true,
        isSearchable: true,
        isImportable: true,
        dataType: STRING
    },
    {
        key: 'title',
        dataIndex: 'title',
        languageKey: 'page-title',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        isImportable: true,
        dataType: STRING
    },
    {
        key: 'excludeFromIndex',
        dataIndex: 'excludeFromIndex',
        languageKey: 'page-excludeFromSearchIndex',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        isImportable: true,
        dataType: BOOLEAN
    },
    {
        key: 'inTrash',
        dataIndex: 'inTrash',
        languageKey: 'page-inTrash',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: BOOLEAN
    },
    {
        key: 'readyForPublish',
        dataIndex: 'readyForPublish',
        languageKey: '',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: BOOLEAN,
        isMandatory:true
    }
];
