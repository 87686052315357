import React from 'react';
import * as XLSX from 'xlsx';
import { TFunction } from 'react-i18next';
import { Checkbox, Col, Row, Select, Space, Upload, Button } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';

import DataTableHelper from './DataTable.helper';
import Loader from '../../utils/loader-handler';
import CommonService from '../../services/CommonService';
import Notify from '../../utils/notification-handler';
import { IBase } from '../../types/Common.types';
import { IBindedColumnModel, IDataTableColumn, IDataTableSettings, IOverrideImportExcelResult } from '../../types/DataTable.types';



const { Option } = Select;
const { Dragger } = Upload;


export interface IDataTableImportExcelProps {
    dataTableSettings: IDataTableSettings;
    dataTableColumns: IDataTableColumn[];
    lang: TFunction<string[]>;
    showHideExcelImport?: (isShow: boolean) => void;
    overrideImport?: (tableData: any[]) => Promise<IOverrideImportExcelResult>;
}

export interface IDataTableImportExcelState<T> {
    showUploadContainer: boolean;
    selectedFile: Blob | null; // uploaded file
    fileData: any[]; // data extracted from the uploaded file
    selectedFileDataRowIndexes: number[]; // indexes of selected items from extracted file data, to import
    tableData: T[]; // converted table data that can send to API 
    bindedColumns: IBindedColumnModel[]; // table columns that bind with file data 
    validationErrors: string[];

}


// This component handle import data into datatable via file functionality.
// User can upload excel/csv file, map columns into table columns & send map data to API to save
// Tip for readability: 
// variable name 'file data' is used to identify data extracted from uploaed file, 
// variable name 'table data' is used to identify data derived from 'file data' which is ready to upload into API
export class DataTableImportExcel<T extends IBase> extends React.Component<IDataTableImportExcelProps, IDataTableImportExcelState<T>> {


    constructor(props: IDataTableImportExcelProps) {
        super(props);

        this.state = this.getDefaultStateObject();
    }

    getDefaultStateObject = () => {
        return {
            showUploadContainer: true,
            selectedFile: null,
            fileData: [],
            selectedFileDataRowIndexes: [],
            tableData: [],
            bindedColumns: [],
            validationErrors: []
        };
    }

    cancelImport = () => {
        this.resetToDefault(() => {
            if (this.props.showHideExcelImport) {
                this.props.showHideExcelImport(false);
            }
        });
    }

    backToFileUpload = () => {
        this.resetToDefault();
    }

    resetToDefault = (callBack?: () => void) => {
        this.setState(this.getDefaultStateObject(), () => {
            if (callBack) {
                callBack();
            }
        });
    }

    onFileUpload = (file: UploadFile<any>) => {
        if (!file || !file.originFileObj) {
            return;
        }

        this.setState({ selectedFile: file.originFileObj }, async () => {
            try {
                let extractedFileData = await this.extractFileDataFromFile();

                this.setState({ fileData: extractedFileData }, async () => {
                    let defaultSelectedFileDataRowIndexes = await this.getDefaultSelectedFileDataRowIndexes();
                    this.setState({
                        selectedFileDataRowIndexes: defaultSelectedFileDataRowIndexes,
                        showUploadContainer: false
                    })
                });

            } catch (error: any) {
                let errorMessage: string = this.props.lang('dataTable-readingUploadedFileFailed');

                if ((typeof error) === 'string') {
                    errorMessage = error;
                }

                Notify.error(
                    errorMessage,
                    this.props.lang('dataTable-excelImportFailed')
                );
            }
        });
    }

    extractFileDataFromFile = (): Promise<any[]> => {
        return new Promise((resolve, reject) => {
            if (this.state.selectedFile === null) {
                reject(this.props.lang('dataTable-pleaseUploadAFileToContinue'));
                return;
            }

            var reader = new FileReader();
            reader.onload = function (e: any) {
                var data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, defval: "" });
                resolve(dataParse);
            };
            reader.onerror = function (e) {
                reject(e);
            }
            reader.readAsBinaryString(this.state.selectedFile);
        });
    }


    getDefaultSelectedFileDataRowIndexes = () => {
        let defaultSelectedFileDataRowIndexes: any[] = [];

        this.state.fileData.forEach((rowItem: any[], index: number) => {

            // skip 1st data row assuming header is available in uploaded file
            if (index === 0) {
                return;
            }

            defaultSelectedFileDataRowIndexes.push(index);
        });

        return defaultSelectedFileDataRowIndexes;
    }


    columnSelected = async (selectedColumnKey: string | undefined, fileDataColumnIndex: number) => {
        if (!selectedColumnKey) {
            return;
        }

        let matchingBindedColumnIndex = this.state.bindedColumns.findIndex(b => b.fileDataColumnIndex === fileDataColumnIndex);
        let matchingColumn = this.props.dataTableColumns.find(c => c.key === selectedColumnKey);
        let matchingColumnDataIndex = matchingColumn ? matchingColumn.dataIndex : [];

        if (matchingBindedColumnIndex === -1) {
            let newBindedColumn: IBindedColumnModel = {
                columnKey: selectedColumnKey,
                columnDataIndex: matchingColumnDataIndex,
                fileDataColumnIndex: fileDataColumnIndex
            };
            this.setState({
                bindedColumns: [...this.state.bindedColumns, newBindedColumn]
            })

        } else {
            this.setState(({ bindedColumns }) => ({
                bindedColumns: [
                    ...bindedColumns.slice(0, matchingBindedColumnIndex),
                    {
                        ...bindedColumns[matchingBindedColumnIndex],
                        columnKey: selectedColumnKey,
                        columnDataIndex: matchingColumnDataIndex,
                    },
                    ...bindedColumns.slice(2)
                ]
            }));
        }
    }

    getTableDataFromSelectedFileDataRowIndexes = async () => {
        let newTableData: T[] = [];

        if (this.state.selectedFileDataRowIndexes.length === 0) {
            return newTableData;
        }

        this.state.selectedFileDataRowIndexes.forEach(async (fileDataRowIndex) => {
            let newTableDataItem = await DataTableHelper.getNewTableItem(this.props.dataTableColumns);

            this.state.bindedColumns.forEach((bindedColumn: IBindedColumnModel) => {

                let valueFromExcel = this.state.fileData[fileDataRowIndex][bindedColumn.fileDataColumnIndex];

                if (typeof bindedColumn.columnDataIndex === 'string' || bindedColumn.columnDataIndex instanceof String) {
                    newTableDataItem[bindedColumn.columnDataIndex as string] = valueFromExcel;
                }
                else {
                    let updatingItem = newTableDataItem;
                    bindedColumn.columnDataIndex.forEach((dataIndex: string, index: number) => {
                        if (index < (bindedColumn.columnDataIndex.length - 1)) {
                            updatingItem = updatingItem[dataIndex];
                        } else {
                            updatingItem[dataIndex] = valueFromExcel;
                        }
                    });
                }

            });

            newTableData.push(newTableDataItem);

        });

        return newTableData;
    }

    selectDeselectRowItem = (isSelected: boolean, rowIndex: number) => {
        let matchingSelectedFileDataRowIndex = this.state.selectedFileDataRowIndexes.findIndex(i => i === rowIndex);

        if (isSelected === true && matchingSelectedFileDataRowIndex === -1) {
            this.setState({
                selectedFileDataRowIndexes: [...this.state.selectedFileDataRowIndexes, rowIndex]
            });
            return;
        }

        if (isSelected === false && matchingSelectedFileDataRowIndex !== -1) {
            this.setState((prevState) => ({
                selectedFileDataRowIndexes: [
                    ...prevState.selectedFileDataRowIndexes.slice(0, matchingSelectedFileDataRowIndex),
                    ...prevState.selectedFileDataRowIndexes.slice(matchingSelectedFileDataRowIndex + 1)
                ]
            }));
            return;
        }
    }

    selectDeselectAllRows = (isSelected: boolean) => {
        let selectedFileDataRowIndexes: number[] = [];

        if (isSelected === true) {
            this.state.fileData.forEach((fileDataRow: any[], index: number) => {
                selectedFileDataRowIndexes.push(index);
            })
        }
        this.setState({ selectedFileDataRowIndexes });
    }

    isRowItemSelected = (rowIndex: number) => {
        return this.state.selectedFileDataRowIndexes.some(i => i === rowIndex);
    }

    isAllRowItemsSelected = () => {
        return this.state.selectedFileDataRowIndexes.length === this.state.fileData.length;
    }

    setValidationErrors = (callBack?: () => void) => {
        let newErrors: string[] = [];

        this.setState({ validationErrors: [] }, () => {
            newErrors = [...newErrors,
            ...this.getRequiredColumnValidationErrors(),
            ...this.getDuplicateColumnValidationErrors()];

            this.setState({ validationErrors: newErrors }, () => {
                if (callBack) {
                    callBack();
                }
            });
        })

    }

    getRequiredColumnValidationErrors = () => {
        let errors: string[] = [];
        this.props.dataTableColumns.forEach((column: IDataTableColumn) => {

            if (column.isRequiredOnImport === true && this.state.bindedColumns.every(b => b.columnKey !== column.key)) {
                let newError = '"' + this.props.lang(column.languageKey) + '" ' +
                    this.props.lang('dataTable-importExcelErrorIsARequired', 'is Required. Please bind a column to continue import');
                errors.push(newError);
            }

        });
        return errors;
    }

    getDuplicateColumnValidationErrors = () => {
        let errors: string[] = [];
        this.state.bindedColumns.filter((item, index) => this.state.bindedColumns.indexOf(item) !== index)
            .forEach((bindedColumn: IBindedColumnModel) => {

                let matchingColumn = this.props.dataTableColumns.find(c => c.key === bindedColumn.columnKey);
                if (matchingColumn) {
                    let newError = '"' + this.props.lang(matchingColumn.languageKey) + '" ' +
                        this.props.lang('dataTable-importExcelErrorDuplicate', 'is binded with more than one column. Please remove duplicates to continue import');
                    errors.push(newError);
                }

            });
        return errors;
    }


    importData = async () => {
        if (this.state.selectedFileDataRowIndexes.length === 0) {
            Notify.info(this.props.lang('dataTable-excelImportNoDataSelectedError', 'You have not selected any data to import, Please select rows you want to import'));
            return;
        }

        let tableData = await this.getTableDataFromSelectedFileDataRowIndexes();

        this.setState({ tableData }, () => {

            this.setValidationErrors(async () => {

                if (this.state.validationErrors.length > 0) {
                    Notify.error(this.props.lang('dataTable-excelImportValidationError', 'Please fix below validation errors to continue'));
                    Loader.hide();
                    return
                }

                if (!this.props.overrideImport) {
                    Loader.show();
                    let url = `data/${this.props.dataTableSettings.dataEndpoint}/Bulk/Insert`;
                    const odataResponse = await CommonService.post(url, this.state.tableData);

                    //TO DO: Proper error handling
                    if (odataResponse) {
                        this.cancelImport();
                        Notify.success(this.props.lang('dataTable-dataImportedSuccesfully', 'Data imported successfully'));
                    }
                    Loader.hide();

                } else {
                    this.props.overrideImport(this.state.tableData).then((result: IOverrideImportExcelResult) => {
                        this.cancelImport();
                    })
                        .catch((result: IOverrideImportExcelResult) => {
                            if (result.errorText) {
                                this.setState({ validationErrors: [...this.state.validationErrors, result.errorText] });
                            }
                        });
                }

            });
        })

    }


    render() {
        return (
            <div className="data-table-import-excel-container">
                {
                    this.state.showUploadContainer === true &&
                    <Row gutter={[16, 16]} >
                        <Col span={24} className="cancel-btn-container">
                            <Button onClick={this.cancelImport}>
                                {this.props.lang('dataTable-cancelImport')}
                            </Button>
                        </Col>
                        <Col span={24} className="upload-area-container">
                            <Dragger name="file" multiple={false} maxCount={1} customRequest={() => { return false }}
                                onChange={(e) => this.onFileUpload(e.file)} showUploadList={false}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    {this.props.lang('dataTable-fileUploadAreaText')}
                                </p>
                                <p className="ant-upload-hint">
                                    {this.props.lang('dataTable-fileUploadAreaSubText')}
                                </p>
                            </Dragger>
                        </Col>
                    </Row>
                }
                {
                    this.state.showUploadContainer === false && this.state.fileData.length > 0 &&
                    <Row gutter={[16, 16]}>
                        <Col span={20}>
                            <p>
                                {
                                    `${this.props.lang('dataTable-selected')} ${this.state.selectedFileDataRowIndexes.length} 
                            ${this.props.lang('dataTable-itemsOutOf')} ${this.state.fileData.length} ${this.props.lang('dataTable-forImport')}`
                                }
                            </p>
                            {
                                this.state.validationErrors.map((error: string, index: number) => {
                                    return (<p key={index} className="text-red">{error}</p>);
                                })
                            }
                        </Col>
                        <Col span={4} className="import-btn-container">
                            <Space>
                                <Button onClick={this.cancelImport}>
                                    {this.props.lang('dataTable-cancelImport')}
                                </Button>
                                <Button onClick={this.backToFileUpload}>
                                    {this.props.lang('dataTable-backToFileUpload')}
                                </Button>
                                <Button type="primary" onClick={this.importData}>
                                    {this.props.lang('dataTable-import')}
                                </Button>
                            </Space>
                        </Col>

                        <Col span={24}>
                            <div className="table-container">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="action-column row-selector-column">
                                                <Checkbox checked={this.isAllRowItemsSelected()}
                                                    onChange={(e) => this.selectDeselectAllRows(e.target.checked)}></Checkbox>
                                            </td>
                                            {
                                                this.state.fileData[0].map((rowItem: any[], index: number) => {

                                                    return (
                                                        <td key={index} className="action-column">
                                                            <Select
                                                                placeholder={this.props.lang('common-pleaseSelect')}
                                                                onChange={(e) => this.columnSelected(e?.toString(), index)}
                                                                showSearch className="column-selector">
                                                                {
                                                                    this.props.dataTableColumns
                                                                        .filter(c => c.isImportable === true)
                                                                        .sort((a, b) => this.props.lang(a.languageKey) > this.props.lang(b.languageKey) ? 1 : -1)
                                                                        .map((column: IDataTableColumn) => {
                                                                            return (
                                                                                <Option key={column.key} value={column.key}>{this.props.lang(column.languageKey)}
                                                                                    {
                                                                                        column.isRequiredOnImport === true &&
                                                                                        <span className="text-red">&nbsp;&nbsp;*</span>
                                                                                    }
                                                                                </Option>
                                                                            )
                                                                        })
                                                                }
                                                            </Select>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                        {
                                            this.state.fileData.map((rowItem: any[], index: number) => {
                                                return <tr key={index}>
                                                    <td className="action-column row-selector-column">
                                                        <Checkbox checked={this.isRowItemSelected(index)}
                                                            onChange={(e) => this.selectDeselectRowItem(e.target.checked, index)}></Checkbox>
                                                    </td>
                                                    {
                                                        rowItem.map((colItem: any, colItemIndex: number) => {
                                                            return <td key={colItemIndex} className="data-column">
                                                                {colItem}
                                                            </td>
                                                        })
                                                    }
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                }


            </div>
        );
    }
}