import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "antd";

import { FormDataTable } from "../../components/ExternalCommunication/FormData/FormDataTable";
import { FormSelector } from "../../components/ExternalCommunication/FormData/FormSelector";
import { FormDataToolBox } from "../../components/ExternalCommunication/FormData/FormDataToolBox";
import { FormDataUpdateModal } from "../../components/ExternalCommunication/FormData/FormDataUpdateModal";

import { TRANSLATION_KEY } from "../../constants/commonConstants";
import { FORM_DATA_DATATABLE_DEFAULT_COLUMNS } from "../../constants/formDataConstants";


export const FormData: React.FC = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [selectedFormId, setSelectedFormId] = useState<number | null>(null);

    const formSelected = async (formId: number) => {
        setSelectedFormId(formId);
    }


    return (
        <>
            <Row gutter={16}>
                <Col span={5} className="gutter-row">

                    {
                        selectedFormId === null &&
                        <Card title={t('externalCommunication-formData-tableName')}>
                            <p>{t('externalCommunication-formData-tableDescription')}</p>
                        </Card>
                    }

                    {
                        selectedFormId !== null &&
                        <FormDataToolBox dataTableColumns={FORM_DATA_DATATABLE_DEFAULT_COLUMNS}></FormDataToolBox>
                    }

                </Col>
                <Col span={19} className="gutter-row">

                    <div className="mb-3">
                        <FormSelector formSelected={formSelected} />
                    </div>

                    {
                        selectedFormId !== null &&
                        <FormDataTable selectedFormId={selectedFormId}/>
                    }

                </Col>
            </Row>
            {
                selectedFormId !== null &&
                <FormDataUpdateModal></FormDataUpdateModal>
            }
        </>
    )
}