import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CommonMethods from "../../../utils/commonMethods";
import DynamicIconHandler from "../../../utils/dynamic-icon-handler";
import { IPrimaryNavItem } from "../../../types/Layout.types";
import { DASHBOARD_PATH, TRANSLATION_KEY } from "../../../constants/commonConstants";
import { NAVIGATION_ITEMS } from "../../../constants/navigations";
import { PRIMARY_NAV_CLICKED } from "../../../constants/pubSubKeys";


export const PrimaryNav: React.FC = () => {
  const { t } = useTranslation([TRANSLATION_KEY.toString()]);
  const location = useLocation();


  useEffect(() => {
    if (location.pathname === '/' || location.pathname === `/${DASHBOARD_PATH}`) {
      PubSub.publish(PRIMARY_NAV_CLICKED, { languageKey: 'dashboard', path: `/${DASHBOARD_PATH}`, secondaryNavItems: [] });
    }

    let matchingNavItem = NAVIGATION_ITEMS.find(n =>
      (CommonMethods.removeFirstAndLastSlash(n.path)).split('/')[0] === (CommonMethods.removeFirstAndLastSlash(location.pathname)).split('/')[0]);
    if (matchingNavItem) {
      navItemClicked(matchingNavItem);
    }
  });


  const navItemClicked = (primaryNavItem: IPrimaryNavItem) => {
    PubSub.publish(PRIMARY_NAV_CLICKED, primaryNavItem);
  }

  const isActivePrimaryNav = (path: string) => {
    if (location.pathname === '/' && path === NAVIGATION_ITEMS[0].path) {
      return true;
    }

    let currentPath = CommonMethods.removeFirstAndLastSlash(location.pathname);
    let navItemPath = CommonMethods.removeFirstAndLastSlash(path);

    if (currentPath.split('/')[0] === navItemPath.split('/')[0]) {
      return true;
    }

    return false;
  }



  return (
    <>
      {
        NAVIGATION_ITEMS.map((navigationItem: IPrimaryNavItem, index: number) => {
          return (
            <Link key={index}
              to={navigationItem.path} className={`primary-nav-item  ${(isActivePrimaryNav(navigationItem.path)) ? 'active' : ''}`}
              onClick={() => navItemClicked(navigationItem)}>
              {DynamicIconHandler.AntIcon({ type: navigationItem.iconName, className: `primary-nav-item-icon ${navigationItem.iconClassName}` })}
              {t(navigationItem.languageKey)}
            </Link>
          )
        })
      }
    </>
  );
}