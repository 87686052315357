import { IDataTableColumn, IDataTableSettings } from "../types/DataTable.types";
import { INT, STRING } from "./commonConstants";


export const SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_DIRECTORY_DATA_KEY: string = 'SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_DIRECTORY_DATA';


export const DIRECTORY_DATA_DATATABLE_SETTINGS: IDataTableSettings = {
    defaultSortColumn: 'id',
    dataEndpoint: 'DirectoryData',
    staticFilters: []
}


export const DIRECTORY_DATA_DATATABLE_COLUMNS: IDataTableColumn[] = [
    {
        key: 'id',
        dataIndex: 'id',
        languageKey: 'directoryData-id',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: INT
    },
    {
        key: 'title',
        dataIndex: 'title',
        languageKey: 'directoryData-title',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'path',
        dataIndex: 'path',
        languageKey: 'directoryData-path',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'roles',
        dataIndex: 'roles',
        languageKey: 'directoryData-roles',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    }
];