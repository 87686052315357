/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// import React from 'react';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import axiosInstance from '../utils/axios/axiosInstance';
import { ENDPOINTS } from './endpoints';

class SettingsService {


    public static async getSystemSetting(key: string): Promise<AxiosResponse> {
       
        const requestConfig: AxiosRequestConfig = {
            method: 'post',
            url: `${ENDPOINTS.Invoke}`,
            headers: {
                'Content-Type': 'application/json',
             //   'Access-Control-Allow-Origin': '*',
            },
            data: {
                "sign":localStorage.getItem("sign"),
                "method": "GetConfig",
                "url" : "/api/controlpanel",
                "args" : { "key" : key}
            }
            
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async setSystemSetting(key: string, value:string): Promise<AxiosResponse> {
       
        const requestConfig: AxiosRequestConfig = {
            method: 'post',
            url: `${ENDPOINTS.Invoke}`,
            headers: {
                'Content-Type': 'application/json',
              //  'Access-Control-Allow-Origin': '*',
            },
            data: {
                "sign":localStorage.getItem("sign"),
                "method": "SetConfig",
                "url" : "/api/controlpanel",
                "args" : { "key" : key, "value": value}
            }
            
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }

    public static async deleteTempFiles(): Promise<AxiosResponse> {

        const requestConfig: AxiosRequestConfig = {
            method: 'post',
            url: `${ENDPOINTS.DeleteTempfiles}`,
            headers: {
                'Content-Type': 'application/json',
               // 'Access-Control-Allow-Origin': '*',
            },
            data: {
                "sign": localStorage.getItem("sign")
            }

        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }
}
export default SettingsService;
