import React from "react";
import { Route } from "react-router-dom";
import { UserRole } from "../types/enum/UserRole.enum";

import CommonMethods from "../utils/commonMethods";

export const PrivateRoute = ({ component, ...rest }: any) => {
  let isTokenValid = CommonMethods.isJWTTokenValid();
  const routeComponent = (props: any) => React.createElement(component, props);
  let allowedUserRoles = rest.userRoles;

  const isUserHasAcess = () => {
    let userRoles = CommonMethods.getCurrentUserRoles();
    let isAllowed: boolean = false;
    if (allowedUserRoles && userRoles && userRoles.length > 0) {
      allowedUserRoles.map((a: any) => {
        let role = userRoles.find((u: String) => u.toLowerCase() === (UserRole[a]).toLowerCase());
        if (role) {
          isAllowed = true;
        }

        return isAllowed;
      });
    }
    return isAllowed;
  };

  if (isTokenValid && isUserHasAcess()) {
    return <Route {...rest} render={routeComponent} />;
  } else {
    return (
      <Route
        component={() => {
          window.location.href = process.env.REACT_APP_LOGIN_URL!;
          return null;
        }}
      />
    );
  }
};
