import React, { useEffect, useState } from "react";
import { Tag } from "antd";

import {
  LANG_ENGLISH,
  LANG_SWEDISH,
  LANG_SWEDISH_NEW_SYSTEM,
} from "../../../constants/commonConstants";
import i18n from "../../../i18n";

export const LanguageSelector: React.FC = () => {
  const [currentActiveLanguage, setCurrentActiveLanguage] =
    useState<string>("");

  useEffect(() => {
    let currentLang = localStorage.getItem("lng");
    if (!currentLang) {
      currentLang = LANG_ENGLISH;
    }
    setCurrentActiveLanguage(currentLang);
    console.log(currentLang);
  }, []);

  const languageChanged = (lang: string) => {
    localStorage.setItem("lng", lang);
    if (lang === LANG_SWEDISH) {
      i18n.changeLanguage(LANG_SWEDISH_NEW_SYSTEM);
      // i18n.reloadResources("se");
    } else {
      i18n.changeLanguage(lang);
      // i18n.reloadResources(["en", "se"]);
    }

    setCurrentActiveLanguage(lang);
  };

  return (
    <>
      <Tag
        className="lang-tag"
        onClick={() => languageChanged(LANG_ENGLISH)}
        color={currentActiveLanguage === LANG_ENGLISH ? "success" : "default"}
      >
        {LANG_ENGLISH}
      </Tag>
      <Tag
        className="lang-tag"
        onClick={() => languageChanged(LANG_SWEDISH)}
        color={
          currentActiveLanguage === LANG_SWEDISH ||
          currentActiveLanguage === LANG_SWEDISH_NEW_SYSTEM
            ? "success"
            : "default"
        }
      >
        {LANG_SWEDISH}
      </Tag>
    </>
  );
};
