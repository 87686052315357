import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, } from 'antd';

import { TRANSLATION_KEY } from '../../constants/commonConstants';

import { DataTable } from '../../components/DataTable/DataTable';
import { SEARCH_LOG_DATATABLE_COLUMNS, SEARCH_LOG_DATATABLE_SETTINGS } from '../../constants/searchLogConstants';
import { IDataTableColumn } from '../../types/DataTable.types';
import { ISearchLog } from '../../types/SearchLog.types';
import { SearchLogToolBox } from '../../components/System/SearchLog/SearchLogToolBox';
import { OPEN_SEARCH_LOG_DETAILS } from '../../constants/pubSubKeys';
import { SearchLogDetails } from '../../components/System/SearchLog/SearchLogDetails';


export const SearchLog: React.FC = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [dataTableColumns] = useState<IDataTableColumn[]>(SEARCH_LOG_DATATABLE_COLUMNS);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onRowClick = (clickedRecord: ISearchLog) => {
        PubSub.publish(OPEN_SEARCH_LOG_DETAILS, clickedRecord);
    }



    return (
        <>
            <Row gutter={16}>
                <Col span={5} className="gutter-row">
                    <SearchLogToolBox dataTableColumns={dataTableColumns}></SearchLogToolBox>
                </Col>
                <Col span={19} className="gutter-row">
                    {
                        <DataTable<ISearchLog>
                            lang={t}
                            tableKey="searchTerm"
                            dataTableSettings={SEARCH_LOG_DATATABLE_SETTINGS}
                            dataTableColumns={dataTableColumns}
                            onRowClick={onRowClick}
                            onRowSelectPassFullItem={true}
                            hideEditColumn={true}
                            isDisableSelectQuery={true}
                            isDisableExpandQuery={true}>
                        </DataTable>
                    }
                </Col>
            </Row>
            <SearchLogDetails />
        </>
    )
};


