import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import AuthContext from "../utils/auth-context";

import SystemSettings from "../pages/System/SystemSettings";
import { Resources } from "./resources.routes";
import { WebsiteContent } from "./website-content-routes";
import { ExternalCommunication } from "./external-communication.routes";
import { EmporiAdvanced } from "./empori-advanced.routes";
import { System } from "./system.routes";
import { Landing } from "../pages/Landing";
import { Dashboard } from "../pages/Dashboard";

import { PrivateRoute } from "./private-route";
import { UserRole } from "../types/enum/UserRole.enum";

export const MainRoutes: React.FC = () => {
  const auth = React.useContext(AuthContext);

  const ProtectedRoute = () => (
    <Route
      render={({ location }) => (
        <Redirect
          to={{
            pathname: "/",
            state: { from: location },
          }}
        />
      )}
    />
  );

  return (
    <>
      <Switch>
        <Route exact path="/" component={Landing} />
        <PrivateRoute
          exact
          path="/controlpanel"
          component={Dashboard}
          userRoles={[UserRole.Admin, UserRole.Editor]}
        />
        <PrivateRoute path="/empori-advanced" component={EmporiAdvanced} />
        <PrivateRoute
          path="/website-content"
          component={WebsiteContent}
          userRoles={[UserRole.Admin, UserRole.Editor]}
        />
        <PrivateRoute
          path="/external-communication"
          component={ExternalCommunication}
          userRoles={[UserRole.Admin, UserRole.Editor]}
        />
        <PrivateRoute
          path="/system"
          component={System}
          userRoles={[UserRole.Admin, UserRole.Editor]}
        />
        <PrivateRoute
          path="/resources"
          component={Resources}
          userRoles={[UserRole.Admin, UserRole.Editor]}
        />
        <PrivateRoute
          exact
          path="/system"
          component={auth ? SystemSettings : ProtectedRoute}
          userRoles={[UserRole.Admin, UserRole.Editor]}
        />
      </Switch>
    </>
  );
};
