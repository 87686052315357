import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosInstance from '../utils/axios/axiosInstance';
import Notify from '../utils/notification-handler';


export default class OldApiService {

    public static async get(url: string,  getFullResponse?: boolean, notHandleError?: boolean): Promise<any> {
        const requestConfig: AxiosRequestConfig = {
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json',
               // 'Access-Control-Allow-Origin': '*',
            },
        };

        return axiosInstance(requestConfig)
            .then((response: AxiosResponse) => {

                if (getFullResponse) {
                    return response;
                }

                if (!response || !response.data || !response.data.d) {
                    Notify.error('Sorry an error occured get data failed');
                }

                return response.data.d

            }, (error) => {

                if (notHandleError) {
                    return error;
                }

                OldApiService.handleErrors(error);
            });
    }

    public static async post(url: string, data: any, getFullResponse?: boolean, notHandleError?: boolean): Promise<any> {
        const requestConfig: AxiosRequestConfig = {
            method: 'post',
            url: url,
            headers: {
                'Content-Type': 'application/json',
               // 'Access-Control-Allow-Origin': '*',
            },
            data: data
        };

        return axiosInstance(requestConfig)
            .then((response: AxiosResponse) => {

                if (getFullResponse) {
                    return response;
                }

                if (!response || !response.data || !response.data.d) {
                    Notify.error('Sorry an error occured post data failed');
                }

                return response.data.d

            }, (error) => {

                if (notHandleError) {
                    return error;
                }

                OldApiService.handleErrors(error);
            });
    }



    public static handleErrors = (error: any) => {
        //TOD: handle unauthorized error

        let errorText: string = '';

        if (error &&
            error.data &&
            error.data.responseException &&
            error.data.responseException.exceptionMessage &&
            error.data.responseException.exceptionMessage.title
        ) {
            errorText = error.data.responseException.exceptionMessage.title;
        }

        Notify.error(`Sorry an error occured request failed. ${errorText}`);
    }

}
