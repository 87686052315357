import { SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import { ASC, ASCEND, BOOLEAN, DATETIME, DESC, INT, STRING } from "../constants/commonConstants";
import { IDataTableColumn, IFilter, IOverrideOdataQueries, IQueryBuilderModel, ITableColumnGrouped } from "../types/DataTable.types";

export default class OdataQueryBuilder {

    public static generateFullOdataQueryString = async (model: IQueryBuilderModel) => {
        let queryString = '';

        if (!model.isDisablePaginationQuery) {
            let paginationQueryString = OdataQueryBuilder.getPaginationOdataQueryString(model.paginationConfig, model.hidePagination);
            if (paginationQueryString !== '') {
                queryString = queryString + ((queryString !== '') ? '&' : '') + paginationQueryString;
            }
        }

        let sortingQueryString = OdataQueryBuilder.getSortingOdataQueryString(model.sorter);
        if (sortingQueryString !== '') {
            queryString = queryString + ((queryString !== '') ? '&' : '') + sortingQueryString;
        }

        let searchTextFilteringQueryString = OdataQueryBuilder.getSearchTextFilteringOdataQueryString(model.dataTableColumns, model.searchTextFilterValue);
        if (searchTextFilteringQueryString !== '') {
            queryString = queryString + ((queryString !== '') ? '&' : '') + searchTextFilteringQueryString;
        }

        let columnFilteringQueryString = OdataQueryBuilder.getColumnFilteringOdataQueryString(model.dataTableColumns, model.columnFilters, model.overrideOdataQueries);
        if (columnFilteringQueryString !== '') {
            queryString = queryString + ((queryString !== '') ? '&' : '') + columnFilteringQueryString;
        }

        let staticFilteringQueryString = OdataQueryBuilder.getStaticFilteringOdataQueryString(model.dataTableColumns, model.staticFilter);
        if (staticFilteringQueryString !== '') {
            queryString = queryString + ((queryString !== '') ? '&' : '') + staticFilteringQueryString;
        }

        if (!model.isDisableSelectQuery) {
            let selectQueryString = OdataQueryBuilder.getSelectQueryString(model.dataTableColumns);
            if (selectQueryString !== '') {
                queryString = queryString + ((queryString !== '') ? '&' : '') + selectQueryString;
            }
        }

        if (!model.isDisableExpandQuery) {
            let selectExpandString = await OdataQueryBuilder.getExpandQueryString(model.dataTableColumns, false);
            if (selectExpandString !== '') {
                queryString = queryString + ((queryString !== '') ? '&' : '') + selectExpandString;
            }
        }


        return queryString;
    }


    public static getSelectQueryString = (dataTableColumns: IDataTableColumn[], isGetHiddenColumns: boolean = false) => {
        let queryString = '$select=';

        dataTableColumns.forEach((column: IDataTableColumn) => {
            if (column.isMandatory === false ||
                (column.isMandatory === undefined && isGetHiddenColumns === false && column.isVisible === false)) {
                return;
            }

            let propertyNames = (Array.isArray(column.dataIndex) === true) ? column.dataIndex : [column.dataIndex];

            if (propertyNames.length === 1) {
                queryString = (queryString !== '') ? queryString : '$select=';
                queryString = queryString + propertyNames[0] + ',';
            }
        });

        queryString = OdataQueryBuilder.removeLastCommaFromQuery(queryString);
        return queryString;
    }



    public static getExpandQueryString = async (dataTableColumns: IDataTableColumn[], isGetHiddenColumns: boolean = false) => {
        let queryString = '';

        let groupedColumns: ITableColumnGrouped[] = await OdataQueryBuilder.getColumnsGroupedByNestedProps(dataTableColumns);

        groupedColumns.forEach((groupedColumn: ITableColumnGrouped, index: number) => {

            let groupedColumnFullQueryString = OdataQueryBuilder.getGroupedColumnFullQueryString(groupedColumn, index, isGetHiddenColumns);
            queryString = queryString + groupedColumnFullQueryString + ',';

        });

        queryString = OdataQueryBuilder.removeLastCommaFromQuery(queryString);
        return queryString;
    }



    public static getPaginationOdataQueryString = (paginationConfig: TablePaginationConfig | false, hidePagination?: boolean) => {
        let queryString = '';

        if (hidePagination === false && paginationConfig !== false &&
            paginationConfig.current && paginationConfig.pageSize
        ) {
            queryString = queryString + '$count=true&$top=' +
                paginationConfig.pageSize + '&$skip=' +
                ((paginationConfig.current - 1) * paginationConfig.pageSize);
        }

        return queryString;
    }



    public static getSortingOdataQueryString = (sorter: SorterResult<any> | SorterResult<any>[] | null) => {
        let queryString = '';

        if (sorter === null) {
            return queryString;
        }

        if (sorter instanceof Array) {
            let sorterLength = (sorter as SorterResult<any>[]).length;

            sorter.forEach((item: SorterResult<any>, index: number) => {
                queryString = queryString + '$orderby=' + OdataQueryBuilder.getSortKeyName(item) + ' ' +
                    (item.order === ASCEND ? ASC : DESC) +
                    ((sorterLength - 1) > index) ? ',' : '';
            });
        }
        else {
            queryString = queryString + '$orderby=' + OdataQueryBuilder.getSortKeyName(sorter) + ' ' +
                (sorter.order === ASCEND ? ASC : DESC);
        }

        return queryString;
    }


    private static getSortKeyName = (sorter: SorterResult<any>) => {
        if (!sorter.field) {
            return sorter.columnKey;
        }

        if (Array.isArray(sorter.field)) {
            return sorter.field.join('/');
        }
        else {
            return sorter.field;
        }
    }


    public static getSearchTextFilteringOdataQueryString = (dataTableColumns: IDataTableColumn[], searchTextFilterValue: string) => {
        let queryString = '';

        if (searchTextFilterValue === '') {
            return queryString;
        }

        dataTableColumns.forEach((column: IDataTableColumn) => {
            if (column.isSearchable === false) {
                return;
            }

            let columnDataIndex = OdataQueryBuilder.getDataIndexString(column.dataIndex);

            if (column.dataType === STRING) {
                queryString = queryString + ((queryString === '') ? "$filter=" : " or ") +
                    "contains(tolower(" + columnDataIndex + "),tolower('" + searchTextFilterValue + "'))";
            }

            if (column.dataType === INT) {
                queryString = queryString + ((queryString === '') ? "$filter=" : " or ") +
                    "contains(cast(" + columnDataIndex + ", 'Edm.String'),'" + searchTextFilterValue + "')";
            }
        });

        return queryString;
    }



    public static getColumnFilteringOdataQueryString = (dataTableColumns: IDataTableColumn[], columnFilters: IFilter[], overrideOdataQueries?: IOverrideOdataQueries) => {
        let queryString = '';

        columnFilters.forEach((filter: IFilter) => {

            let matchingColumn = dataTableColumns.find(c => OdataQueryBuilder.getDataIndexString(c.dataIndex) === filter.columnName);

            if (!matchingColumn || matchingColumn.canFilter === false) {
                return;
            }

            if (matchingColumn.isOverrideOdataQueries && matchingColumn.isOverrideOdataQueries.isOverrideFilterQuery &&
                overrideOdataQueries && overrideOdataQueries.overrideFilterQuery) {
                queryString = overrideOdataQueries.overrideFilterQuery(filter);
                return
            }

            let matchingColumnDataIndex = OdataQueryBuilder.getDataIndexString(matchingColumn.dataIndex);

            if (matchingColumn.dataType === STRING) {
                queryString = queryString + ((queryString === '') ? "$filter=" : " and ") +
                    "contains(tolower(" + matchingColumnDataIndex + "),tolower('" + filter.value + "'))";
            }

            if (matchingColumn.dataType === INT) {
                queryString = queryString + ((queryString === '') ? "$filter=" : " and ") +
                    "contains(cast(" + matchingColumnDataIndex + ", 'Edm.String'),'" + filter.value + "')";
            }

            if (matchingColumn.dataType === BOOLEAN) {
                queryString = queryString + ((queryString === '') ? "$filter=" : " and ") +
                    filter.columnName + " eq " + filter.value;
            }

            if (matchingColumn.dataType === DATETIME) {

                const dateRangeSplits = filter.value.split('|');
                if (dateRangeSplits.length !== 2) {
                    return;
                }

                queryString = queryString + ((queryString === '') ? "$filter=" : " and ") +
                    filter.columnName + " ge " + dateRangeSplits[0] + " and " + filter.columnName + " le " + dateRangeSplits[1];
            }
        })

        return queryString;
    }



    // Static filter will generate filter query regardless it's columns searchable state, so Searchable false columns also can be filtered
    public static getStaticFilteringOdataQueryString = (dataTableColumns: IDataTableColumn[], staticFilter: IFilter | null) => {
        let queryString = '';

        if (staticFilter === null || staticFilter.columnName === '') {
            return queryString;
        }

        let matchingColumn = dataTableColumns.find(c => OdataQueryBuilder.getDataIndexString(c.dataIndex) === staticFilter?.columnName);
        let value = staticFilter?.value;

        if (matchingColumn?.dataType === STRING) {
            value = "'" + value + "'";
        }

        queryString = queryString + ((queryString === '') ? "$filter=" : " or ")
            + staticFilter?.columnName + " eq " + value + "";

        return queryString;
    }



    private static getDataIndexString = (dataIndex: string | string[]) => {
        if (Array.isArray(dataIndex)) {
            return dataIndex.join('/');
        }
        else {
            return dataIndex;
        }
    }



    private static removeLastCommaFromQuery = (query: string) => {
        if (query.slice(-1) === ',') {
            query = query.slice(0, -1);
        }

        return query;
    }



    private static getColumnsGroupedByNestedProps = async (dataTableColumns: IDataTableColumn[]) => {
        let groupedColumns: ITableColumnGrouped[] = [];

        dataTableColumns.forEach((column: IDataTableColumn) => {

            let propertyNames = column.dataIndex;
            let closestParentGroup: ITableColumnGrouped | undefined = undefined;


            if (Array.isArray(propertyNames) === false || propertyNames.length === 1) {
                return;
            }

            for (let i = 0; i < propertyNames.length; i++) {

                if (closestParentGroup === undefined) {
                    let matchingParentGroup = groupedColumns.find((c: ITableColumnGrouped) => c.parentPropertyName === propertyNames[i]);
                    if (!matchingParentGroup) {
                        groupedColumns.push({
                            parentPropertyName: propertyNames[i],
                            childrenColumns: [],
                            nestedColumns: []
                        })
                    }

                    closestParentGroup = groupedColumns.find((c: ITableColumnGrouped) => c.parentPropertyName === propertyNames[i]);
                }
                else {
                    let matchingParentGroup = closestParentGroup.nestedColumns.find((c: ITableColumnGrouped) => c.parentPropertyName === propertyNames[i]);
                    if (!matchingParentGroup) {
                        closestParentGroup.nestedColumns.push({
                            parentPropertyName: propertyNames[i],
                            childrenColumns: [],
                            nestedColumns: []
                        })
                    }

                    closestParentGroup = closestParentGroup.nestedColumns.find((c: ITableColumnGrouped) => c.parentPropertyName === propertyNames[i]);
                }

                if (i === (propertyNames.length - 2) && closestParentGroup) {
                    (closestParentGroup.childrenColumns as IDataTableColumn[]).push(column);
                    break;
                }
            }

        })

        return groupedColumns;
    }



    private static getGroupedColumnSelectQueryString = (groupedColumn: ITableColumnGrouped, isGetHiddenColumns: boolean) => {
        if (groupedColumn.childrenColumns.length <= 0) {
            return '';
        }

        let queryString = '$select=';

        groupedColumn.childrenColumns.forEach((column: IDataTableColumn, index: number) => {

            if (column.isMandatory === false ||
                (column.isMandatory === undefined && isGetHiddenColumns === false && column.isVisible === false)) {
                return;
            }

            let propertyNames = column.dataIndex;
            queryString = queryString + propertyNames[propertyNames.length - 1] + ',';

        });

        queryString = OdataQueryBuilder.removeLastCommaFromQuery(queryString);
        return queryString;
    }



    private static getGroupedColumnFullQueryString = (groupedColumn: ITableColumnGrouped, index: number, isGetHiddenColumns: boolean) => {
        if (groupedColumn.childrenColumns.length <= 0 &&
            groupedColumn.nestedColumns.length <= 0) {
            return '';
        }

        let queryString = (index === 0 ? '$expand=' : '') + groupedColumn.parentPropertyName + '(';
        let nestedSelectQueryString = OdataQueryBuilder.getGroupedColumnSelectQueryString(groupedColumn, isGetHiddenColumns);

        let nestedExpandQueryString = '';

        groupedColumn.nestedColumns.forEach((groupedColumn: ITableColumnGrouped, index: number) => {
            let groupedColumnFullQueryString = OdataQueryBuilder.getGroupedColumnFullQueryString(groupedColumn, index, isGetHiddenColumns);
            nestedExpandQueryString = nestedExpandQueryString + (index !== 0 ? ',' : '') + groupedColumnFullQueryString;
        });

        queryString = queryString + nestedSelectQueryString + ((nestedSelectQueryString && nestedExpandQueryString) ? ';' : '') + nestedExpandQueryString + ')'
        return queryString;
    }

}