import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Alert, Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Select, Space, Switch } from "antd";

import { TRANSLATION_KEY } from "../../../../constants/commonConstants";
import { DATA_TABLE_RELOAD, DATA_TABLE_SHOW_UPDATE_MODAL } from "../../../../constants/pubSubKeys";

import CommonService from "../../../../services/CommonService";
import DefaultDataHandler from "../../../../utils/default-data-handler";
import Notify from "../../../../utils/notification-handler";
import { IUser, IUserUpdateFormModel } from "../../../../types/User.types";
import { IRole } from "../../../../types/Role.types";


export interface IUserUpdateModalProps {
    rolesLookups: IRole[];
}

export const UserUpdateModal: React.FC<IUserUpdateModalProps> = ({
    rolesLookups,
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowResetPassword, setIsShowResetPassword] = useState<boolean>(false);
    const [userUpdateFormModel, setUserUpdateFormModel] = useState<IUserUpdateFormModel | null>();
    const [errorText, setErrorText] = useState<string | null>(null);


    useEffect(() => {
        let showModalPubSub = PubSub.subscribe(DATA_TABLE_SHOW_UPDATE_MODAL, async (msg: string, updatingItem: IUser | null) => {
            let model: IUserUpdateFormModel;

            if (updatingItem === null) {
                model = DefaultDataHandler.newUserUpdateFormModel();
            }
            else {
                model = getModelFromEntity(updatingItem);
            }

            setUserUpdateFormModel(model);
            setIsShowModal(true);

        });

        return () => {
            PubSub.unsubscribe(showModalPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const getModelFromEntity = (entity: IUser) => {
        let userUpdateFormModel: IUserUpdateFormModel = {
            ...entity,
            confirmPassword: '',
            roleIds: []
        };

        let roleIds: number[] = [];
        userUpdateFormModel.userRoles.every(r => roleIds.push(r.roleId));
        userUpdateFormModel.roleIds = roleIds;

        if (userUpdateFormModel.membership && userUpdateFormModel.membership.lastLoginDate) {
            userUpdateFormModel.membership.lastLoginDate = moment(userUpdateFormModel.membership.lastLoginDate);
        }
        if (userUpdateFormModel.membership && userUpdateFormModel.membership.creationDate) {
            userUpdateFormModel.membership.creationDate = moment(userUpdateFormModel.membership.creationDate);
        }

        return userUpdateFormModel;
    }

    const getUserFromUserUpdatdFormModel = (userUpdateFormModel: IUserUpdateFormModel) => {
        let copyOfUserUpdateFormModel: any = { ...userUpdateFormModel };
        delete copyOfUserUpdateFormModel.confirmPassword;
        delete copyOfUserUpdateFormModel.roleIds;
        (copyOfUserUpdateFormModel as IUser).userRoles = [];

        userUpdateFormModel.roleIds.forEach((roleId) => {
            let matchingRole = rolesLookups.find(r => r.id === roleId);
            if (matchingRole) {
                (copyOfUserUpdateFormModel as IUser).userRoles.push({
                    roleId: matchingRole.id,
                    userId: copyOfUserUpdateFormModel.id
                })
            }
        });

        return copyOfUserUpdateFormModel as IUser;
    }

    const closeUpdateModal = () => {
        setErrorText(null);
        setUserUpdateFormModel(null);
        setIsShowModal(false);
    }

    const onFinish = (updatingItem: IUserUpdateFormModel) => {
        let updatedUser = getUserFromUserUpdatdFormModel(updatingItem);

        if (updatedUser.id !== null && updatedUser.id !== 0) {
            edit(updatedUser);
            return;
        }

        add(updatedUser);
    };


    const add = async (updatingItem: IUser) => {
        setIsLoading(true);
        let url = `data/UserData`;

        const response = await CommonService.post(url, updatingItem, false, true);

        if (response && response.value > 0) {
            PubSub.publish(DATA_TABLE_RELOAD);
            closeUpdateModal();
            Notify.success(t('common-itemAddedSuccessfully'));
        } else {
            Notify.error(t('common-itemAddingFailed'));

            if (response && response.data && response.data.isError) {
                if (response.data.responseException && response.data.responseException.exceptionMessage && response.data.responseException.exceptionMessage.value) {
                    if (response.data.responseException.exceptionMessage.value === 'Username already exists.') {
                        setErrorText(t('user-error-usernameAlreadyExist'));
                    } else {
                        setErrorText(response.data.responseException.exceptionMessage);
                    }
                }
            }
        }

        setIsLoading(false);
    }


    const edit = async (updatingItem: IUser) => {
        setIsLoading(true);
        let url = `data/UserData/Update/${updatingItem.id}`;

        const response = await CommonService.post(url, updatingItem, false, true);

        if (response >= 0) {
            PubSub.publish(DATA_TABLE_RELOAD);
            closeUpdateModal();
            Notify.success(t('common-itemUpdatedSuccessfully'));
        } else {
            Notify.error(t('common-itemUpdatingFailed'));

            if (response && response.data && response.data.isError) {
                if (response.data.responseException && response.data.responseException.exceptionMessage) {
                    if (response.data.responseException.exceptionMessage === 'Username already exists.') {
                        setErrorText(t('user-error-usernameAlreadyExist'));
                    } else {
                        setErrorText(response.data.responseException.exceptionMessage);
                    }
                }
            }
        }

        setIsLoading(false);
    }


    return (
        <>
            {
                userUpdateFormModel &&
                <Modal
                    title={(userUpdateFormModel.id !== 0) ? t('websiteContent-users-users-usersEditModalTitle') : t('websiteContent-users-users-usersAddModalTitle')}
                    className="data-table-item-update-modal"
                    visible={isShowModal && userUpdateFormModel !== null} width={1000}
                    onCancel={closeUpdateModal} footer={null}>

                    {
                        errorText &&
                        <Alert message={errorText} type="error" className="mb-3" />
                    }

                    <Form
                        name="data-table-item-update-form"
                        layout="vertical"
                        initialValues={userUpdateFormModel}
                        onFinish={onFinish}>

                        <Form.Item hidden={true} name="id" > <Input /> </Form.Item>

                        <Row gutter={24}>

                            <Col span={12}>
                                <Form.Item
                                    name="name"
                                    label={t('user-username')}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: `${t('common-validation-requiredValidationError')}`
                                        },
                                        {
                                            pattern: /^[a-z0-9_-]+$/,
                                            message: `${t('user-validation-lowercaseOnly')}`,
                                        }
                                    ]}>
                                    <Input disabled={userUpdateFormModel.id !== null && userUpdateFormModel.id !== 0} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name={['membership', 'email']}
                                    label={t('user-emailAddress')}
                                    required={true}
                                    rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                    <Input disabled={userUpdateFormModel.id !== null && userUpdateFormModel.id !== 0} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name={['profile', 'firstName']}
                                    label={t('user-firstName')}
                                    required={true}
                                    rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name={['profile', 'lastName']}
                                    label={t('user-surName')}
                                    required={true}
                                    rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name={['roleIds']}
                                    label={t('user-roles')}
                                    required={true}
                                    rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                    <Select mode="multiple" allowClear>
                                        {
                                            rolesLookups.map((userRolesLookup: IRole) => {
                                                return (<Select.Option key={userRolesLookup.id} value={userRolesLookup.id}>{userRolesLookup.name}</Select.Option>)
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name={['profile', 'personalId']}
                                    label={t('user-personalId')}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={['profile', 'pageId']}
                                    label={t('user-pageId')}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={['profile', 'phone']}
                                    label={t('user-mobileNumber')}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={['profile', 'address']}
                                    label={t('user-address')}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={['profile', 'zip']}
                                    label={t('user-postalcode')}>
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name={['profile', 'city']}
                                    label={t('user-city')}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            {
                                userUpdateFormModel.id !== 0 &&
                                <Col span={24}>
                                    <Button onClick={() => setIsShowResetPassword(!isShowResetPassword)}>
                                        {t('websiteContent-users-users-resetPassword')}
                                    </Button><br /><br />
                                </Col>
                            }

                            {
                                (isShowResetPassword === true || userUpdateFormModel.id === 0) &&
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            name={['membership', 'password']}
                                            label={t('user-newPassword')}
                                            required={true}
                                            rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name='confirmPassword'
                                            label={t('user-confirmPassword')}
                                            required={true}
                                            rules={[
                                                { required: true, message: `${t('common-validation-requiredValidationError')}` },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {

                                                        if (!value || getFieldValue(['membership', 'password']) === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                                    },
                                                }),
                                            ]}>
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                </>
                            }
                            {
                                userUpdateFormModel.id !== 0 &&
                                <Col span={8}>
                                    <Form.Item
                                        name={['membership', 'lastLoginDate']}
                                        label={t('user-lastLoggedIn')} >
                                        <DatePicker disabled={true} className="w-100" />
                                    </Form.Item>
                                </Col>
                            }
                            {
                                userUpdateFormModel.id !== 0 &&
                                <Col span={8}>
                                    <Form.Item
                                        name={['membership', 'creationDate']}
                                        label={t('user-created')} >
                                        <DatePicker disabled={true} className="w-100" />
                                    </Form.Item>
                                </Col>
                            }
                            {
                                userUpdateFormModel.id !== 0 &&
                                <Col span={8}>
                                    <Form.Item valuePropName="checked"
                                        name={['membership', 'isLockedOut']}
                                        label={t('user-locked')} >
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            }
                        </Row>

                        <Divider />

                        <Row justify="end">
                            <Col span={24}>
                                <Space className="data-table-item-update-modal-footer">
                                    <Button onClick={closeUpdateModal}>
                                        {t('common-cancel')}
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={isLoading} >
                                        {t('common-save')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            }
        </>
    )
}