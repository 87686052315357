import { IDataTableColumn, IDataTableSettings } from "../types/DataTable.types";
import {  DATE, INT, STRING } from "./commonConstants";


export const SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_PAGE_NOT_FOUND_ERRORS_KEY: string = 'SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_PAGE_NOT_FOUND_ERRORS';


export const PAGE_NOT_FOUND_ERRORS_DATATABLE_SETTINGS: IDataTableSettings = {
    defaultSortColumn: 'id',
    dataEndpoint: 'RedirectErrorData',
    staticFilters: []
}


export const PAGE_NOT_FOUND_ERRORS_DATATABLE_COLUMNS: IDataTableColumn[] = [
    {
        key: 'id',
        dataIndex: 'id',
        languageKey: '',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        isMandatory:true,
        isHiddenFromTableDisplay:true,
        dataType: INT
    },
    {
        key: 'url',
        dataIndex: 'url',
        languageKey: 'pageNotFoundErrors-url',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'count',
        dataIndex: 'noi',
        languageKey: 'pageNotFoundErrors-count',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: INT
    },
    {
        key: 'lastAccess',
        dataIndex: 'lastAccess',
        languageKey: 'pageNotFoundErrors-lastAccess',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: DATE
    }
];
