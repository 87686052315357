import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AUTH_SIGN_KEY } from '../constants/commonConstants';
import axiosInstance from '../utils/axios/axiosInstance';
import Notify from '../utils/notification-handler';


const API_URL = process.env.REACT_APP_NEW_API_ENDPOINT;

export default class CommonService {

    public static async get(url: string, getFullResponse?: boolean, notHandleError?: boolean): Promise<any> {
        const requestConfig: AxiosRequestConfig = {
            method: 'get',
            url: `${API_URL}/${url}`,
        };

        return axiosInstance(requestConfig)
            .then((response: AxiosResponse) => {

                if (getFullResponse) {
                    return response;
                }

                if (!response || !response.data || !response.data.value) {
                    Notify.error('Sorry an error occured data get failed');
                }

                return response.data.value

            }, (error) => {

                if (notHandleError) {
                    return error;
                }

                CommonService.handleErrors(error);
            });
    }

    public static async post(url: string, data: any, getFullResponse?: boolean, notHandleError?: boolean): Promise<any> {
        const requestConfig: AxiosRequestConfig = {
            method: 'post',
            url: `${API_URL}/${url}`,
            headers: {
                Accept: 'application/json;odata.metadata=none',
                sign: localStorage.getItem(AUTH_SIGN_KEY)
            },
            data: data
        };

        return axiosInstance(requestConfig)
            .then((response: AxiosResponse) => {

                if (getFullResponse) {
                    return response;
                }

                if (!response || !response.data || response.data.value === undefined) {
                    Notify.error('Sorry an error occured data post failed');
                }

                return response.data.value

            }, (error) => {

                if (notHandleError) {
                    return error;
                }

                CommonService.handleErrors(error);
            });
    }

    public static async put(url: string, data: any, getFullResponse?: boolean, notHandleError?: boolean): Promise<any> {
        const requestConfig: AxiosRequestConfig = {
            method: 'put',
            url: `${API_URL}/${url}`,
            data: data
        };
        return axiosInstance(requestConfig)
            .then((response: AxiosResponse) => {

                if (getFullResponse) {
                    return response;
                }

                if (!response || !response.data || response.data.value  === undefined) {
                    Notify.error('Sorry an error occured data updating failed');
                }

                return response.data.value

            }, (error) => {

                if (notHandleError) {
                    return error;
                }

                CommonService.handleErrors(error);
            });
    }

    public static async patch(url: string, data: any, getFullResponse?: boolean, notHandleError?: boolean): Promise<any> {
        const requestConfig: AxiosRequestConfig = {
            method: 'patch',
            url: `${API_URL}/${url}`,
            data: data
        };

        return axiosInstance(requestConfig)
            .then((response: AxiosResponse) => {
                if (getFullResponse) {
                    return response;
                }

                if (!response || !response.data || (!response.data.value && response.data.value !== 0)) {
                    Notify.error('Sorry an error occured data post failed');
                }

                return response.data.value

            }, (error) => {
                if (notHandleError) {
                    return error;
                }

                CommonService.handleErrors(error);
            });
    }

    public static async delete(url: string, getFullResponse?: boolean, notHandleError?: boolean): Promise<any> {
        const requestConfig: AxiosRequestConfig = {
            method: 'delete',
            url: `${API_URL}/${url}`
        };

        return axiosInstance(requestConfig)
            .then((response: AxiosResponse) => {
                if (getFullResponse) {
                    return response;
                }

                if (!response || !response.data || (!response.data.value && response.data.value !== 0)) {
                    Notify.error('Sorry an error occured data deleting failed');
                }

                return response.data.value

            }, (error) => {
                if (notHandleError) {
                    return error;
                }

                CommonService.handleErrors(error);
            });
    }

    public static handleErrors = (error: any) => {

        if (error && error.status === 401) {
            // In Development environment avoid redirecting
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                Notify.error(`Please login to continue`);
                return;
            }

            if (process.env.REACT_APP_BASE_URL) {
                window.location.href = process.env.REACT_APP_BASE_URL;
            }
            Notify.error(`Please login to continue`);
        }

        let errorText: string = '';

        if (error && 
            error.data &&
            error.data.responseException &&
            error.data.responseException.exceptionMessage
        ) {
            errorText = error.data.responseException.exceptionMessage.title ? error.data.responseException.exceptionMessage.title : error.data.responseException.exceptionMessage;
        }

        Notify.error(`Sorry an error occured request failed. ${errorText}`);
    }

}
