import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CommonMethods from './utils/commonMethods';
import AuthProvider from './providers/authProvider';
import { BasicLayout } from './components/Layout/BasicLayout/BasicLayout';
import { THEME_KEY } from './constants/storageKeys';
import { ALL_THEMES, THEME_LIGHT, TRANSLATION_KEY } from './constants/commonConstants';
import { NAVIGATION_ITEMS } from './constants/navigations';
import { PRIMARY_NAV_CLICKED } from './constants/pubSubKeys';



function App() {
  const { t } = useTranslation([TRANSLATION_KEY.toString()]);

  useEffect(() => {
    // set initial theme
    let currentTheme = localStorage[THEME_KEY] || THEME_LIGHT;
    import(`./styles/ecms.${currentTheme}.less`);
    updateBodyClass(currentTheme);

  });
  

  useEffect(() => {
    let primaryNavClickPubSub = PubSub.subscribe(PRIMARY_NAV_CLICKED, setHeaderTitle);
    let secondaryNavClickPubSub = PubSub.subscribe(PRIMARY_NAV_CLICKED, setHeaderTitle);
    let tertiaryNavClickPubSub = PubSub.subscribe(PRIMARY_NAV_CLICKED, setHeaderTitle);

  return () => {
      PubSub.unsubscribe(primaryNavClickPubSub);
      PubSub.unsubscribe(secondaryNavClickPubSub);
      PubSub.unsubscribe(tertiaryNavClickPubSub);
  }
 // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  


  const setHeaderTitle = ()=>{
    let headerTitle = '';
    let currentPath = CommonMethods.removeFirstAndLastSlash(window.location.pathname);

    let matchingPrimaryNavItem = NAVIGATION_ITEMS.find(n =>
      (CommonMethods.removeFirstAndLastSlash(n.path)).split('/')[0] ===
      currentPath.split('/')[0]);

    if (matchingPrimaryNavItem) {
      headerTitle = matchingPrimaryNavItem.languageKey;

      let matchingSecondaryNavItem = matchingPrimaryNavItem.secondaryNavItems.find(n =>
        (CommonMethods.removeFirstAndLastSlash(n.path)).split('/')[1] ===
        currentPath.split('/')[1]);

        if (matchingSecondaryNavItem) {
          headerTitle = matchingSecondaryNavItem.languageKey;
          
          let matchingTertiaryNavItem = matchingSecondaryNavItem.tertiaryNavItems?.find(n =>
            (CommonMethods.removeFirstAndLastSlash(n.path)).split('/')[2] ===
            currentPath.split('/')[2]);

            if (matchingTertiaryNavItem) {
              headerTitle = matchingTertiaryNavItem.languageKey;
            }
        }

        document.title =t(headerTitle);
    }
  }

  const updateBodyClass = (themeToAdd: string) => {
    ALL_THEMES.forEach((theme: string) => {
      document.body.classList.remove(theme);
    });

    document.body.classList.add(themeToAdd);
  }

  return (
    <BrowserRouter>
      <AuthProvider>
          <BasicLayout />
      </AuthProvider>
    </BrowserRouter>
  );

}

export default App;
