import React, { useEffect, useState } from "react";
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'antd';
import { TRANSLATION_KEY } from '../../../../constants/commonConstants';
import { DATA_TABLE_ROW_SELECTION_CHANGED } from "../../../../constants/pubSubKeys";

import { ViewSelector } from "../../../Common/ViewSelector";
import { IDataTableColumn } from "../../../../types/DataTable.types";
import { ToolBoxSearch } from "../../../Common/DataTableToolBox/ToolBoxSearch";
import { ToolBoxCommonActions } from "../../../Common/DataTableToolBox/ToolBoxCommonActions";
import { ToolBoxColumnSelector } from "../../../Common/DataTableToolBox/ToolBoxColumnSelector";
import { ToolBoxStaticFilters } from "../../../Common/DataTableToolBox/ToolBoxStaticFilters";

import { WEBSITE_CONTENT_USERS_VIEWS } from "../../../../constants/innerViews";
import { SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_USERKEYS_KEY, USERKEYS_DATATABLE_SETTINGS } from "../../../../constants/userKeys.constants";


interface IUserKeysToolBoxProps {
    dataTableColumns: IDataTableColumn[];
}


export const UserKeysToolBox: React.FC<IUserKeysToolBoxProps> = ({
    dataTableColumns
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);


    useEffect(() => {
        let rowSelectionPubSub = PubSub.subscribe(DATA_TABLE_ROW_SELECTION_CHANGED, (msg: string, selectedRowKeys: number[]) => {
            setSelectedRowKeys(selectedRowKeys);
        });
        return () => {
            PubSub.unsubscribe(rowSelectionPubSub);
        }
    }, []);


    return (
        <Card title={t('webSiteContent-users-userCreationKeys-tableName')}>
            <p>{t('webSiteContent-users-userCreationKeys-tableDescription')}</p>

            {
                selectedRowKeys.length > 0 &&
                <p>{t('dataTable-selected', 'Selected')} <strong>{selectedRowKeys.length}</strong> {t('dataTable-items', 'items')}</p>
            }

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <ToolBoxSearch dataTableColumns={dataTableColumns} />
                </Col>
                <Col span={24}>
                    <ToolBoxCommonActions isShowExport={false} isShowImport={false} isShowCopy={false}/>
                </Col>

                <Col span={24}>
                    <ViewSelector views={WEBSITE_CONTENT_USERS_VIEWS} />
                </Col>

            </Row>

            <ToolBoxColumnSelector dataTableColumns={dataTableColumns} settingsKey={SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_USERKEYS_KEY}/>
            <ToolBoxStaticFilters tableDisplayName={t('webSiteContent-users-userCreationKeys-tableName')} dataTableSettings={USERKEYS_DATATABLE_SETTINGS} />

        </Card>
    );

}

