import { IDataTableColumn, IDataTableSettings } from "../types/DataTable.types";
import { DATE, INT, STRING } from "./commonConstants";


export const SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_SETTINGS_CHANGE_LOG_KEY: string = 'SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_SETTINGS_CHANGE_LOG';


export const SETTINGS_CHANGE_LOG_DATATABLE_SETTINGS: IDataTableSettings = {
    defaultSortColumn: 'id',
    dataEndpoint: 'SettingsLogData',
    staticFilters: [],
    isShowEditColumn: false
}


export const SETTINGS_CHANGE_LOG_DATATABLE_COLUMNS: IDataTableColumn[] = [
    {
        key: 'id',
        dataIndex: 'id',
        languageKey: 'id',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: INT,
        isMandatory: true,
        isHiddenFromTableDisplay: true,
        isNotExport:true
    },
    {
        key: 'user',
        dataIndex: 'user',
        languageKey: 'settingsChangeLog-user',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'date',
        dataIndex: 'date',
        languageKey: 'settingsChangeLog-date',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: DATE
    },
    {
        key: 'settings_key',
        dataIndex: ['settings', 'key'],
        languageKey: 'settingsChangeLog-setting',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'newValue',
        dataIndex: 'newValue',
        languageKey: 'settingsChangeLog-newValue',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'oldValue',
        dataIndex: 'oldValue',
        languageKey: 'settingsChangeLog-oldValue',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    }
];
