import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Divider, Form, Input, Modal, Row, Space } from "antd";


import DatePicker from "../../../Common/DatePicker";
import DefaultDataHandler from "../../../../utils/default-data-handler";
import { IPageNotFoundError } from "../../../../types/PageNotFoundErrors.types";

import { TRANSLATION_KEY } from "../../../../constants/commonConstants";
import { DATA_TABLE_HIDE_UPDATE_MODAL, DATA_TABLE_SHOW_UPDATE_MODAL } from "../../../../constants/pubSubKeys";



export interface IPageNotFoundErrorsUpdateModalProps {
}

export const PageNotFoundErrorsUpdateModal: React.FC<IPageNotFoundErrorsUpdateModalProps> = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [form] = Form.useForm<IPageNotFoundError>();
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [updatingFormModel, setUpdatingFormModel] = useState<IPageNotFoundError | null>(null);


    const closeUpdateModal = () => {
        form.resetFields();
        setUpdatingFormModel(null);
        setIsShowModal(false);
    }


    useEffect(() => {
        let showModalPubSub = PubSub.subscribe(DATA_TABLE_SHOW_UPDATE_MODAL, async (msg: string, updatingItem: IPageNotFoundError | null) => {

            if (updatingItem === null) {
                updatingItem = DefaultDataHandler.newPageNotFoundError();
            }
            else {
                if (updatingItem.lastAccess) { updatingItem.lastAccess = new Date(updatingItem.lastAccess); }
            }

            form.setFieldsValue(updatingItem);
            setUpdatingFormModel(updatingItem);
            setIsShowModal(true);

        });

        let hideModalPubSub = PubSub.subscribe(DATA_TABLE_HIDE_UPDATE_MODAL, () => {
            closeUpdateModal();
        })

        return () => {
            PubSub.unsubscribe(showModalPubSub);
            PubSub.unsubscribe(hideModalPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            {
                updatingFormModel &&
                <Modal
                    title={t('webSiteContent-pages-pageNotFoundErrors-pageNotFoundErrorsDetails')}
                    className="data-table-item-update-modal"
                    visible={isShowModal}
                    onCancel={closeUpdateModal} footer={null}>

                    <Form form={form}
                        name="data-table-item-update-form"
                        layout="vertical">

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="url"
                                    label={t('pageNotFoundErrors-url')}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    name="noi"
                                    label={t('pageNotFoundErrors-count')}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    name="lastAccess"
                                    label={t('pageNotFoundErrors-lastAccess')}>
                                    <DatePicker className="w-100" disabled={true} />
                                </Form.Item>
                            </Col>

                        </Row>

                        <Divider />

                        <Row justify="end">
                            <Col span={24}>
                                <Space className="data-table-item-update-modal-footer">
                                    <Button onClick={closeUpdateModal}>
                                        {t('common-cancel')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>

                </Modal>
            }
        </>
    )
}