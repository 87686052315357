import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Space } from 'antd';

import { DeleteRowTool } from '../../../Common/DataTableToolBox/DeleteRowTool';
import { TRANSLATION_KEY } from '../../../../constants/commonConstants';
import { USERS_DATATABLE_SETTINGS } from '../../../../constants/usersConstants';
import { CommonRowToolItem } from '../../../Common/DataTableToolBox/CommonRowToolItem';
import { SendNewPasswordsRowTool } from './SendNewPasswordsRowTool';


const { Panel } = Collapse;

export interface IUsersRowToolsProps {
    selectedRowKeys: number[];
}

export const UsersRowTools: React.FC<IUsersRowToolsProps> = ({ selectedRowKeys }) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);


    return (
        <Collapse>
            <Panel header={`${t('rowTool-title-rowToolsFor')} ${t('webSiteContent-users-users-tableName')}`} key='1'>
                <Space size={[9, 13]} wrap>

                    <DeleteRowTool className="mt-13-px" selectedRowKeys={selectedRowKeys} dataEndpoint={USERS_DATATABLE_SETTINGS.dataEndpoint} />

                    <CommonRowToolItem
                        bulkAction={{
                            languageKey: "websiteContent-users-users-rowtool-unlockUsers",
                            iconName: "UnlockOutlined",
                            descriptionLanguageKey: "websiteContent-users-users-rowtool-unlockUsers-description",
                            properties: [
                                { propertyDataIndex: "isLockedOut", propertyValue: false }
                            ]
                        }}
                        selectedRowKeys={selectedRowKeys}
                        dataEndpoint={'MembershipData'} />

                    <SendNewPasswordsRowTool selectedRowKeys={selectedRowKeys} dataEndpoint={USERS_DATATABLE_SETTINGS.dataEndpoint} />
                </Space>
            </Panel>
        </Collapse>
    )
}