import { IPrimaryNavItem } from "../types/Layout.types";

export const NAVIGATION_ITEMS: IPrimaryNavItem[] = [
    {
        languageKey: 'primaryNavMenu-websiteContent',
        path: '/website-content/pages/web-pages',
        iconName: 'GoldFilled',
        iconClassName: 'text-red',
        secondaryNavItems: [
            {
                languageKey: 'secondaryNavMenu-pages',
                path: '/website-content/pages/web-pages',
                tertiaryNavItems: [
                    { languageKey: 'tertiaryNavMenu-webPages', path: '/website-content/pages/web-pages' },
                    { languageKey: 'tertiaryNavMenu-redirects', path: '/website-content/pages/redirects' },
                    { languageKey: 'tertiaryNavMenu-redirectSettings', path: '/website-content/pages/redirect-settings' },
                    { languageKey: 'tertiaryNavMenu-pageNotFoundErrors', path: '/website-content/pages/page-not-found-errors' }
                ]
            },
            {
                languageKey: 'secondaryNavMenu-propertyChangeLog',
                path: '/website-content/property-change-log'
            },
            {
                languageKey: 'secondaryNavMenu-users',
                path: '/website-content/users/users',
                tertiaryNavItems: [
                    { languageKey: 'tertiaryNavMenu-users', path: '/website-content/users/users' },
                    { languageKey: 'tertiaryNavMenu-roles', path: '/website-content/users/roles' },
                    { languageKey: 'tertiaryNavMenu-userCreationKeys', path: '/website-content/users/user-creation-keys' }
                ]
            },
        ]
    },
    {
        languageKey: 'primaryNavMenu-system',
        path: '/system/process-and-tasks',
        iconName: 'AppstoreOutlined',
        iconClassName: 'text-blue',
        secondaryNavItems: [
            {
                languageKey: 'secondaryNavMenu-processAndTasks',
                path: '/system/process-and-tasks'
            },
            {
                languageKey: 'secondaryNavMenu-googleAnalytics',
                path: '/system/google-analytics'
            },
            {
                languageKey: 'secondaryNavMenu-search',
                path: '/system/search/search-log',
                tertiaryNavItems: [
                    { languageKey: 'tertiaryNavMenu-searchLog', path: '/system/search/search-log' },
                    { languageKey: 'tertiaryNavMenu-searchSettings', path: '/system/search/search-settings' },
                ]
            },
            {
                languageKey: 'secondaryNavMenu-globalAppSettings',
                path: '/system/global-app-settings/site-settings',
                tertiaryNavItems: [
                    { languageKey: 'tertiaryNavMenu-siteSettings', path: '/system/seo/site-settings' }
                ]
            },
            {
                languageKey: 'secondaryNavMenu-settingsChangeLog',
                path: '/system/settings-change-log',
            }
        ]
    },
    {
        languageKey: 'primaryNavMenu-resources',
        path: '/resources/file-data',
        iconName: 'SnippetsOutlined',
        iconClassName: 'text-yellow',
        secondaryNavItems: [
            {
                languageKey: 'secondaryNavMenu-fileData',
                path: '/resources/file-data'
            },
            {
                languageKey: 'secondaryNavMenu-directoryData',
                path: '/resources/directory-data'
            }
        ]
    },

    // NOT VISIBLE FOR 1.0 - UNCOMMENT WHEN SECTION IS READY
    // {
    //     languageKey: 'primaryNavMenu-dealershipOnline',
    //     path: '/dealership-online',
    //     iconName: 'FireFilled',
    //     iconClassName: 'text-yellow',
    //     secondaryNavItems: [
    //         {
    //             languageKey: 'secondaryNavMenu-verendus',
    //             path: '/dealership-online/verendus'
    //         },
    //         {
    //             languageKey: 'secondaryNavMenu-onlineShowroom',
    //             path: '/dealership-online/online-showroom'
    //         },
    //     ]
    // },


    // NOT VISIBLE FOR 1.0 - UNCOMMENT WHEN SECTION IS READY
    // {
    //     languageKey: 'primaryNavMenu-webshop',
    //     path: '/webshop',
    //     iconName: 'SearchOutlined',
    //     iconClassName: 'text-yellow',
    //     secondaryNavItems: [
    //         {
    //             languageKey: 'secondaryNavMenu-generalSettings',
    //             path: '/webshop/general-settings'
    //         },
    //         {
    //             languageKey: 'secondaryNavMenu-customers',
    //             path: '/webshop/customers'
    //         },
    //         {
    //             languageKey: 'secondaryNavMenu-discounts',
    //             path: '/webshop/discounts'
    //         },
    //         {
    //             languageKey: 'secondaryNavMenu-articles',
    //             path: '/webshop/articles'
    //         },
    //         {
    //             languageKey: 'secondaryNavMenu-orders',
    //             path: '/webshop/orders'
    //         },
    //         {
    //             languageKey: 'secondaryNavMenu-payment',
    //             path: '/webshop/payment'
    //         },
    //         {
    //             languageKey: 'secondaryNavMenu-shipment',
    //             path: '/webshop/shipment'
    //         }
    //     ]
    // },


    // NOT VISIBLE FOR 1.0 - UNCOMMENT WHEN SECTION IS READY
    // {
    //     languageKey: 'primaryNavMenu-planner',
    //     path: '/planner',
    //     iconName: 'SearchOutlined',
    //     iconClassName: 'text-green',
    //     secondaryNavItems: [
    //         {
    //             languageKey: 'secondaryNavMenu-plannerSettings',
    //             path: '/planner/planner-settings'
    //         },
    //         {
    //             languageKey: 'secondaryNavMenu-viewAttendingData',
    //             path: '/planner/view-attending-data'
    //         },
    //         {
    //             languageKey: 'secondaryNavMenu-viewAdditionalAttendingData',
    //             path: '/planner/view-additional-attending-data'
    //         },
    //         {
    //             languageKey: 'secondaryNavMenu-viewAttendingDataCollections',
    //             path: '/planner/view-attending-data-collections'
    //         },
    //         {
    //             languageKey: 'secondaryNavMenu-manageEventData',
    //             path: '/planner/manage-event-data'
    //         },
    //         {
    //             languageKey: 'secondaryNavMenu-manageEventInviteData',
    //             path: '/planner/manage-event-invite-data'
    //         },
    //         {
    //             languageKey: 'secondaryNavMenu-manageLinkedProductData',
    //             path: '/planner/manage-linked-product-data'
    //         },
    //         {
    //             languageKey: 'secondaryNavMenu-manageLocationData',
    //             path: '/planner/manage-location-data'
    //         },
    //         {
    //             languageKey: 'secondaryNavMenu-managePlannerData',
    //             path: '/planner/manage-planner-data'
    //         }
    //     ]
    // },

    {
        languageKey: 'primaryNavMenu-externalCommunication',
        path: '/external-communication/form-data',
        iconName: 'CommentOutlined',
        iconClassName: 'text-red',
        secondaryNavItems: [
            {
                languageKey: 'secondaryNavMenu-formData',
                path: '/external-communication/form-data'
            },
            {
                languageKey: 'secondaryNavMenu-emailAndSms',
                path: '/external-communication/email-and-sms/email-templates'
            }
        ]
    }

]