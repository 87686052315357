import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

import DataTableHelper from "../../components/DataTable/DataTable.helper";
import { IFileData } from "../../types/FileData.types";
import { DataTable } from "../../components/DataTable/DataTable";
import { FileDataToolBox } from "../../components/Resources/FileData/FileDataToolBox";
import { FileDataUpdateModal } from "../../components/Resources/FileData/FileDataUpdateModal";
import { IDataTableColumn, IDataTableSettings, IShowHideColumnPubSubKey } from "../../types/DataTable.types";

import { TRANSLATION_KEY } from "../../constants/commonConstants";
import { DATA_TABLE_SHOW_HIDE_COLUMN } from "../../constants/pubSubKeys";
import { FILE_DATA_DATATABLE_COLUMNS, FILE_DATA_DATATABLE_SETTINGS, SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_FILE_DATA_KEY } from "../../constants/fileDataConstants";



export const FileData: React.FC = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [dataTableSettings] = useState<IDataTableSettings>(FILE_DATA_DATATABLE_SETTINGS);
    const [dataTableColumns, setDataTableColumns] = useState<IDataTableColumn[]>(FILE_DATA_DATATABLE_COLUMNS);


    useEffect(() => {
        const init = async () => {
            let visibleColumns = await getVisibleColumnsFromSettings();
            resetColumnVisibilityWithSavedSettings(visibleColumns);
        }
        init();

        let showHideColumnsPubSub = PubSub.subscribe(
            DATA_TABLE_SHOW_HIDE_COLUMN,
            (msg: string, model: IShowHideColumnPubSubKey) => {
                let _dataTableColumns = DataTableHelper.resetSingleColumnVisibility(
                    [...dataTableColumns],
                    model.columnKey,
                    model.isChecked
                );
                setDataTableColumns(_dataTableColumns);
            });
        return () => {
            PubSub.unsubscribe(showHideColumnsPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetColumnVisibilityWithSavedSettings = (visibleColumnsFromSettngs: string[]) => {
        let _dataTableColumns = DataTableHelper.resetColumnVisibility(dataTableColumns, visibleColumnsFromSettngs);
        if (_dataTableColumns) {
            setDataTableColumns(_dataTableColumns);
        }
    }

    const getVisibleColumnsFromSettings = async () => {
        return await DataTableHelper.getVisibleColumnsFromSettings(SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_FILE_DATA_KEY);
    }


    return (
        <>
            <Row gutter={16}>
                <Col span={5} className="gutter-row">
                    <FileDataToolBox dataTableColumns={dataTableColumns}></FileDataToolBox>
                </Col>
                <Col span={19} className="gutter-row">
                    {
                        <DataTable<IFileData>
                            dataTableSettings={dataTableSettings}
                            dataTableColumns={dataTableColumns}
                            lang={t}></DataTable>
                    }
                </Col>
            </Row>

            <FileDataUpdateModal></FileDataUpdateModal>

        </>
    )
}