import React, { useEffect, useState } from "react";
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'antd';

import { TRANSLATION_KEY } from "../../../constants/commonConstants";
import { DATA_TABLE_ROW_SELECTION_CHANGED } from "../../../constants/pubSubKeys";
import { SETTINGS_CHANGE_LOG_DATATABLE_SETTINGS, SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_SETTINGS_CHANGE_LOG_KEY } from "../../../constants/settingsChangeLogConstants";

import { IDataTableColumn } from "../../../types/DataTable.types";
import { ToolBoxSearch } from "../../Common/DataTableToolBox/ToolBoxSearch";
import { DeleteRowTool } from "../../Common/DataTableToolBox/DeleteRowTool";
import { ToolBoxCommonActions } from "../../Common/DataTableToolBox/ToolBoxCommonActions";
import { ToolBoxColumnSelector } from "../../Common/DataTableToolBox/ToolBoxColumnSelector";


interface ISettingsChangeLogToolBoxProps {
    dataTableColumns: IDataTableColumn[];
}

export const SettingsChangeLogToolBox: React.FC<ISettingsChangeLogToolBoxProps> = ({ dataTableColumns }) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);


    useEffect(() => {
        let rowSelectionPubSub = PubSub.subscribe(DATA_TABLE_ROW_SELECTION_CHANGED, (msg: string, selectedRowKeys: number[]) => {
            setSelectedRowKeys(selectedRowKeys);
        });
        return () => {
            PubSub.unsubscribe(rowSelectionPubSub);
        }
    }, []);


    return (
        <Card title={t('settingsChangeLog')}>
            <p>{t('settingsChangeLog-description')}</p>

            {
                selectedRowKeys.length > 0 &&
                <p>{t('dataTable-selected', 'Selected')} <strong>{selectedRowKeys.length}</strong> {t('dataTable-items', 'items')}</p>
            }

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <ToolBoxSearch dataTableColumns={dataTableColumns} />
                </Col>
                <Col span={24}>
                    <ToolBoxCommonActions isShowCopy={false} isShowCreateNew={false} isShowImport={false} />

                    <DeleteRowTool className="mt-13-px" selectedRowKeys={selectedRowKeys} dataEndpoint={SETTINGS_CHANGE_LOG_DATATABLE_SETTINGS.dataEndpoint} />
                </Col>
            </Row>

            <ToolBoxColumnSelector dataTableColumns={dataTableColumns} settingsKey={SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_SETTINGS_CHANGE_LOG_KEY} />
        </Card>
    );

}
