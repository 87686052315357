import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Space } from "antd";
import { SyncOutlined, TableOutlined, PlusOutlined, ExportOutlined, ImportOutlined, CopyOutlined } from '@ant-design/icons';

import { TRANSLATION_KEY } from "../../../constants/commonConstants";
import {
    DATA_TABLE_COPY_ITEM, DATA_TABLE_EXPORT, DATA_TABLE_RESET_AND_RELOAD,
    DATA_TABLE_SHOW_HIDE_COLUMN_SELECTOR, DATA_TABLE_SHOW_HIDE_IMPORT,
    DATA_TABLE_SHOW_UPDATE_MODAL
} from "../../../constants/pubSubKeys";



export interface IToolBoxCommonActionsProps {
    isShowColumnSelector?: boolean;
    isShowReload?: boolean;
    isShowCreateNew?: boolean;
    isShowExport?: boolean;
    isShowImport?: boolean;
    isShowCopy?: boolean;
}

export const ToolBoxCommonActions: React.FC<IToolBoxCommonActionsProps> = ({
    isShowColumnSelector = true,
    isShowReload = true,
    isShowCreateNew = true,
    isShowExport = true,
    isShowImport = true,
    isShowCopy = true,
}) => {
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [isColumnSelectorActive, setIsColumnSelectorActive] = useState<boolean>(false);


    const showHideColumnSelectors = () => {
        let isActive = !isColumnSelectorActive;
        setIsColumnSelectorActive(isActive);
        PubSub.publish(DATA_TABLE_SHOW_HIDE_COLUMN_SELECTOR, isActive);
    }

    const resetAndReloadBtnClicked = () => {
        PubSub.publish(DATA_TABLE_RESET_AND_RELOAD);
    }

    const addNewBtnClicked = () => {
        PubSub.publish(DATA_TABLE_SHOW_UPDATE_MODAL, null)
    }

    const exportBtnClicked = () => {
        PubSub.publish(DATA_TABLE_EXPORT);
    }

    const importBtnClicked = () => {
        PubSub.publish(DATA_TABLE_SHOW_HIDE_IMPORT, true);
    }

    const copyBtnClicked = () => {
        PubSub.publish(DATA_TABLE_COPY_ITEM);
    }

    return (
        <Space size={[9, 13]} wrap>
            {
                isShowColumnSelector &&
                <Button onClick={showHideColumnSelectors} type={isColumnSelectorActive === true ? 'primary' : 'default'}>
                    <TableOutlined className="text-blue" /> {t('dataTable-selectColumns', 'Select columns')}
                </Button>
            }
            {
                isShowReload &&
                <Button onClick={resetAndReloadBtnClicked} >
                    <SyncOutlined className="text-blue" /> {t('dataTable-reload', 'Reload')}
                </Button>
            }
            {
                isShowCreateNew &&
                <Button onClick={addNewBtnClicked} >
                    <PlusOutlined className="text-blue" /> {t('dataTable-createNew', 'Create new')}
                </Button>
            }
            {
                isShowExport &&
                <Button block onClick={exportBtnClicked} >
                    <ExportOutlined className="text-blue" /> {t('dataTable-export', 'Export')}
                </Button>
            }
            {
                isShowImport &&
                <Button block onClick={importBtnClicked}>
                    <ImportOutlined className="text-blue" /> {t('dataTable-import', 'Import')}
                </Button>
            }
            {
                isShowCopy &&
                <Button block onClick={copyBtnClicked}>
                    <CopyOutlined className="text-blue" /> {t('dataTable-copy', 'Copy')}
                </Button>
            }
        </Space>
    );

}