import React from "react";
import { Switch,  useRouteMatch } from "react-router-dom";

import { DirectoryData } from "../pages/Resources/DirectoryData";
import { FileData } from "../pages/Resources/FileData";
import { UserRole } from "../types/enum/UserRole.enum";
import { PrivateRoute } from "./private-route";


export const Resources: React.FC = () => {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <PrivateRoute path={`${path}/directory-data`} component={DirectoryData}  userRoles={[UserRole.Admin, UserRole.Editor]} />
            <PrivateRoute path={`${path}/file-data`} component={FileData}  userRoles={[UserRole.Admin, UserRole.Editor]} />
        </Switch>
    );
}