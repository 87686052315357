import React, { useEffect, useState } from "react";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";
import { EditOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Form, Input, InputNumber, Modal, Row, Select, Space, Switch, Tabs } from "antd";

import Notify from "../../../../utils/notification-handler";
import CommonMethods from "../../../../utils/commonMethods";
import CommonService from "../../../../services/CommonService";
import DefaultDataHandler from "../../../../utils/default-data-handler";
import { IRole } from "../../../../types/Role.types";
import { IPage, IPageUpdateFormModel } from "../../../../types/Page.types";
import { WebPageDirectorySelect } from "./WebPageDirectorySelect";

import { TRANSLATION_KEY } from "../../../../constants/commonConstants";
import { DATA_TABLE_HIDE_UPDATE_MODAL, DATA_TABLE_RELOAD, DATA_TABLE_SHOW_UPDATE_MODAL } from "../../../../constants/pubSubKeys";


const { TabPane } = Tabs;
const { Option } = Select;


export interface IWebPageUpdateModalProps {
    rolesLookups: IRole[];
}

export const WebPageUpdateModal: React.FC<IWebPageUpdateModalProps> = ({
    rolesLookups
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [form] = Form.useForm();
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [updatingFormModel, setUpdatingFormModel] = useState<IPageUpdateFormModel | null>(null);

    useEffect(() => {
        let showModalPubSub = PubSub.subscribe(DATA_TABLE_SHOW_UPDATE_MODAL, async (msg: string, updatingItem: IPage | null) => {
            let model: IPageUpdateFormModel = DefaultDataHandler.newPageUpdateFormModel();

            if (updatingItem !== null) {
                model = getModelFromEntity(updatingItem);
            }

            form.setFieldsValue(model)
            setUpdatingFormModel(model);
            setIsShowModal(true);

        });

        let hideModalPubSub = PubSub.subscribe(DATA_TABLE_HIDE_UPDATE_MODAL, () => {
            closeUpdateModal();
        })

        return () => {
            PubSub.unsubscribe(showModalPubSub);
            PubSub.unsubscribe(hideModalPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeUpdateModal = () => {
        form.resetFields();
        setUpdatingFormModel(null);
        setIsShowModal(false);
    }


    const getModelFromEntity = (entity: IPage) => {
        let model: IPageUpdateFormModel = {
            ...entity,
            publishDate: entity.publishDate ? moment(entity.publishDate) : null,
            lastPublish: entity.lastPublish ? moment(entity.lastPublish) : null,
            unPublishDate: entity.unPublishDate ? moment(entity.unPublishDate) : null,
            lastEdited: entity.lastEdited ? moment(entity.lastEdited) : null,
            created: entity.created ? moment(entity.created) : null
        };

        return model;
    }

    const getEntityFromModel = (updatingItem: IPageUpdateFormModel) => {
        let entity: IPage = {
            ...updatingItem,
        };
        return entity;
    }

    const parentIdChanged = (selectedId: number) => {
        form.setFieldsValue({ parentId: selectedId });
    }

    const onFinish = (updatingItem: IPageUpdateFormModel) => {
        let updatedPage = getEntityFromModel(updatingItem);

        // even though SEO score should be a number, in database seaoscore is stores as a string
        updatedPage.seoscore = updatedPage.seoscore  ? (updatedPage.seoscore.toString()) : "";

        if (updatedPage.id !== null && updatedPage.id !== 0) {
            edit(updatedPage);
            return;
        }
        add(updatedPage);
    };


    const add = async (updatingItem: IPage) => {
        setIsLoading(true);

        const odataResponse = await CommonService.post('data/PageData', updatingItem);
        if (odataResponse) {
            Notify.success(t('common-itemAddedSuccessfully'));
        } else {
            Notify.error(t('common-itemAddingFailed'));
        }

        PubSub.publish(DATA_TABLE_RELOAD);
        closeUpdateModal();
        setIsLoading(false);
    }


    const edit = async (updatingItem: IPage) => {
        setIsLoading(true);
        let url = `data/PageData/${updatingItem.id}`;
        const odataResponse = await CommonService.patch(url, updatingItem);

        if (odataResponse === 0 || odataResponse === 1) {
            Notify.success(t('common-itemUpdatedSuccessfully'));
        } else {
            Notify.error(t('common-itemUpdatingFailed'));
        }

        PubSub.publish(DATA_TABLE_RELOAD);
        closeUpdateModal();
        setIsLoading(false);
    }

    return (
        <>
            {
                updatingFormModel &&
                <Modal title={(updatingFormModel.id !== 0) ? t('websiteContent-pages-webPages-webPagesEditModalTitle') : t('websiteContent-pages-webPages-webPagesAddModalTitle')}
                    className="data-table-item-update-modal"
                    visible={isShowModal && updatingFormModel !== null} width={1000}
                    onCancel={closeUpdateModal} footer={null}>

                    <Form
                        form={form}
                        name="data-table-item-update-form"
                        layout="vertical"
                        onFinish={onFinish}>

                        <Tabs defaultActiveKey="1">
                            <TabPane tab={<span><EditOutlined />{t('common-editable', 'Editable')}</span>} key="1" forceRender={true}>

                                <Row gutter={24}>

                                    <Col span={12}>
                                        <Form.Item
                                            name="name"
                                            label={t('page-name')}
                                            required={true}
                                            rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item label={t('page-parentId')} required={true}>
                                            <Form.Item hidden={true}
                                                noStyle
                                                name="parentId"
                                                required={true}
                                                rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                                <InputNumber hidden={true} value={updatingFormModel.parentId} className="w-100" />
                                            </Form.Item>
                                            <WebPageDirectorySelect selectedDirectoryId={updatingFormModel?.parentId} onDirectoryChange={parentIdChanged} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="metadata"
                                            label={t('page-metadata')}
                                            required={true}
                                            rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                            <TextArea rows={3} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="requiredRole"
                                            label={t('page-requiredRole')}>
                                            <Select className="w-100">
                                                {
                                                    rolesLookups.map(rolesLookup => {
                                                        return <Option key={rolesLookup.name} value={rolesLookup.name}>{rolesLookup.name}</Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item valuePropName="checked"
                                            name="hidden"
                                            label={t('page-hideInMenus')} >
                                            <Switch />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item valuePropName="checked"
                                            name="excludeFromIndex"
                                            label={t('page-excludeFromSearchIndex')} >
                                            <Switch />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="seoword"
                                            label={t('page-seoWords')}>
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="seoscore"
                                            label={t('page-seoScore')}>
                                            <InputNumber className="w-100" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            name="description"
                                            label={t('page-description')}>
                                            <TextArea rows={3} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="virtualUrl"
                                            label={t('page-friendlyUrl')}>
                                            <Input />
                                        </Form.Item>
                                    </Col>


                                    <Col span={12}>
                                        <Form.Item
                                            name="title"
                                            label={t('page-title')}>
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </TabPane>
                            <TabPane tab={<span><LockOutlined />{t('common-readonly')}</span>} key="2" forceRender={true}>
                                <Row gutter={24}>

                                    <Col span={12}>
                                        <Form.Item
                                            name="id"
                                            label={t('page-id')}>
                                            <InputNumber className="w-100" disabled={true} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="pageStatus"
                                            label={t('page-status')}>
                                                <p>{CommonMethods.getWebPageStatusTag(updatingFormModel)}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item valuePropName="checked"
                                            name="published"
                                            label={t('page-published')} >
                                            <Switch disabled={true} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="publishDate"
                                            label={t('page-publishedDate')} >
                                            <DatePicker disabled={true} className="w-100" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="lastPublish"
                                            label={t('page-lastPublishedDate')} >
                                            <DatePicker disabled={true} className="w-100" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="unPublishDate"
                                            label={t('page-unpublishedDate')} >
                                            <DatePicker disabled={true} className="w-100" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="edited" valuePropName="checked"
                                            label={t('page-edited')} >
                                            <Switch disabled={true} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="lastEdited"
                                            label={t('page-lastEdited')} >
                                            <DatePicker disabled={true} className="w-100" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item valuePropName="checked"
                                            name="readyForPublish"
                                            label={t('page-readyForPublish')} >
                                            <Switch disabled={true} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="createdBy"
                                            label={t('page-createdBy')} >
                                            <Input disabled={true} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="created"
                                            label={t('page-dateCreated')} >
                                            <DatePicker disabled={true} className="w-100" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="editedBy"
                                            label={t('page-editedBy')} >
                                            <Input disabled={true} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="editRole"
                                            label={t('page-editRole')} >
                                            <Select className="w-100" disabled={true}>
                                                {
                                                    rolesLookups.map(rolesLookup => {
                                                        return <Option key={rolesLookup.name} value={rolesLookup.name}>{rolesLookup.name}</Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="publishRole"
                                            label={t('page-publishedRole')} >
                                            <Select className="w-100" disabled={true}>
                                                {
                                                    rolesLookups.map(rolesLookup => {
                                                        return <Option key={rolesLookup.name} value={rolesLookup.name}>{rolesLookup.name}</Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="page"
                                            label={t('page-template')} >
                                            <Input disabled={true} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item valuePropName="checked"
                                            name="inTrash"
                                            label={t('page-inTrash')} >
                                            <Switch disabled={true} />
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </TabPane>
                        </Tabs>

                        <Divider />
                        <Row justify="end">
                            <Col span={24}>
                                <Space className="data-table-item-update-modal-footer">
                                    <Button onClick={closeUpdateModal}>
                                        {t('common-cancel')}
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={isLoading}>
                                        {t('common-save')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>

                </Modal>
            }
        </>
    )
}