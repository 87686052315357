import React from "react";
import { useTranslation } from "react-i18next";
import { GoogleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Col, Row } from "antd";

import { UniqueVisitors } from "../GoogleAnalyticsCharts/UniqueVisitors";
import { TopPages } from "../GoogleAnalyticsCharts/TopPages";
import { GOOGLE_ANALYTICS_TYPE_UA } from "../../constants/googleAnalytics";
import { TRANSLATION_KEY } from "../../constants/commonConstants";


export const GoogleAnalyticsWidget: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);

    const navigateToAnalytics = () => {
        history.push('/system/google-analytics');
    }

    return (

        <div className="widget-container">
            <div className="mb-1">
                <h2><GoogleOutlined className="text-primary-contrast mr-2" />{t('googleAnalytics')}</h2>
                <span className="widget-flag" onClick={navigateToAnalytics}>{t('dashboard-viewAllData')}</span>
            </div>
            <div>
                <Row>
                    <Col span={12}>
                        <UniqueVisitors fromDate="7daysAgo" toDate="today" analyticsType={GOOGLE_ANALYTICS_TYPE_UA} />
                    </Col>
                    <Col span={12}>
                        <TopPages fromDate="7daysAgo" toDate="today" analyticsType={GOOGLE_ANALYTICS_TYPE_UA} />
                    </Col>
                </Row>
            </div>
        </div>
    )
}