import React, { useEffect, useState } from "react";
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'antd';

import { UsersRowTools } from "./UsersRowTools";
import { IDataTableColumn } from "../../../../types/DataTable.types";
import { ToolBoxSearch } from "../../../Common/DataTableToolBox/ToolBoxSearch";
import { ToolBoxCommonActions } from "../../../Common/DataTableToolBox/ToolBoxCommonActions";
import { ToolBoxColumnSelector } from "../../../Common/DataTableToolBox/ToolBoxColumnSelector";
import { ToolBoxStaticFilters } from "../../../Common/DataTableToolBox/ToolBoxStaticFilters";
import { ViewSelector } from "../../../Common/ViewSelector";

import { TRANSLATION_KEY } from '../../../../constants/commonConstants';
import { DATA_TABLE_ROW_SELECTION_CHANGED } from "../../../../constants/pubSubKeys";
import { WEBSITE_CONTENT_USERS_VIEWS } from "../../../../constants/innerViews";
import { SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_USERS_KEY, USERS_DATATABLE_SETTINGS } from "../../../../constants/usersConstants";


interface IUsersToolBoxProps {
    dataTableColumns: IDataTableColumn[];
}


export const UsersToolBox: React.FC<IUsersToolBoxProps> = ({
    dataTableColumns
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);


    useEffect(() => {
        let rowSelectionPubSub = PubSub.subscribe(DATA_TABLE_ROW_SELECTION_CHANGED, (msg: string, selectedRowKeys: number[]) => {
            setSelectedRowKeys(selectedRowKeys);
        });
        return () => {
            PubSub.unsubscribe(rowSelectionPubSub);
        }
    }, []);


    return (
        <Card title={t('webSiteContent-users-users-tableName')}>
            <p>{t('webSiteContent-users-users-tableDescription')}</p>

            {
                selectedRowKeys.length > 0 &&
                <p>{t('dataTable-selected')} <strong>{selectedRowKeys.length}</strong> {t('dataTable-items')}</p>
            }

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <ToolBoxSearch dataTableColumns={dataTableColumns} />
                </Col>
                <Col span={24}>
                    <ToolBoxCommonActions isShowCopy={false} />
                </Col>

                <Col span={24}>
                    <ViewSelector views={WEBSITE_CONTENT_USERS_VIEWS} />
                </Col>

            </Row>

            <ToolBoxColumnSelector dataTableColumns={dataTableColumns} settingsKey={SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_USERS_KEY} />
            <ToolBoxStaticFilters tableDisplayName={t('webSiteContent-users-users-tableName')} dataTableSettings={USERS_DATATABLE_SETTINGS} />
            <UsersRowTools selectedRowKeys={selectedRowKeys} />
        </Card>
    );

}

