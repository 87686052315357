import { SHOW_HIDE_LOADER } from "../constants/pubSubKeys";

export default class Loader {

    public static show = () => {
        PubSub.publish(SHOW_HIDE_LOADER, true);
    };

    public static hide = () => {
        PubSub.publish(SHOW_HIDE_LOADER, false);
    };

}