import React from "react";
import { Switch,  useRouteMatch } from "react-router-dom";

import { PropertyChangeLog } from "../pages/WebsiteContent/PropertyChangeLog";
import { PageNotFoundErrors } from "../pages/WebsiteContent/Pages/PageNotFoundErrors";
import { Redirects } from "../pages/WebsiteContent/Pages/Redirects";
import { WebPages } from "../pages/WebsiteContent/Pages/WebPages";
import { Users } from "../pages/WebsiteContent/Users/Users";
import { Roles } from "../pages/WebsiteContent/Users/Roles";
import { UserKeys } from "../pages/WebsiteContent/Users/UserKeys";
import { RedirectSettings } from "../pages/WebsiteContent/Pages/RedirectSettings";
import { PrivateRoute } from "./private-route";
import { UserRole } from "../types/enum/UserRole.enum";

export const WebsiteContent: React.FC = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute
        path={`${path}/pages/page-not-found-errors`}
        component={PageNotFoundErrors}
        userRoles={[UserRole.Admin, UserRole.Editor]}
      />
      <PrivateRoute
        path={`${path}/pages/redirects`}
        component={Redirects}
        userRoles={[UserRole.Admin, UserRole.Editor]}
      />
      <PrivateRoute
        path={`${path}/pages/redirect-settings`}
        component={RedirectSettings}
        userRoles={[UserRole.Admin]}
      />
      <PrivateRoute
        path={`${path}/pages/web-pages`}
        component={WebPages}
        userRoles={[UserRole.Admin, UserRole.Editor]}
      />

      <PrivateRoute
        path={`${path}/property-change-log`}
        component={PropertyChangeLog}
        userRoles={[UserRole.Admin, UserRole.Editor]}
      />

      <PrivateRoute
        path={`${path}/users/roles`}
        component={Roles}
        userRoles={[UserRole.Admin]}
      />
      <PrivateRoute
        path={`${path}/users/users`}
        component={Users}
        userRoles={[UserRole.Admin]}
      />
      <PrivateRoute
        path={`${path}/users/user-creation-keys`}
        component={UserKeys}
        userRoles={[UserRole.Admin]}
      />
    </Switch>
  );
};
