import { IDataTableColumn, IDataTableSettings } from "../types/DataTable.types";
import {  INT, STRING } from "./commonConstants";


export const SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_REDIRECTS_KEY: string = 'SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_REDIRECTS';


export const REDIRECTS_DATATABLE_SETTINGS: IDataTableSettings = {
    defaultSortColumn: 'id',
    dataEndpoint: 'RedirectData',
    staticFilters: []
}


export const REDIRECTS_DATATABLE_COLUMNS: IDataTableColumn[] = [
    {
        key: 'id',
        dataIndex: 'id',
        languageKey: 'redirects-id',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        isMandatory:true,
        dataType: INT
    },
    {
        key: 'pageId',
        dataIndex: 'pageId',
        languageKey: 'redirects-targetPageId',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        isImportable:true,
        dataType: INT
    },
    {
        key: 'customUrl',
        dataIndex: 'customUrl',
        languageKey: 'redirects-targetUrl',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        isImportable:true,
        dataType: STRING
    },
    {
        key: 'fromUrl',
        dataIndex: 'fromUrl',
        languageKey: 'redirects-url',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        isImportable:true,
        dataType: STRING
    },
   
];
