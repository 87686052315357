import React from "react";
import { Switch } from "react-router-dom";
// import { Dashboard } from "../pages/EmporiAdvanced/Dashboard";




export const EmporiAdvanced: React.FC = () => {
    // let { path } = useRouteMatch();

    return (
        <Switch>
            {/* <Route path={`${path}/dashboard`} component={Dashboard}/> */}
        </Switch>
    );
}