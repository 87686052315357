import React, { useEffect, useState } from "react";
import { TreeNode } from "antd/lib/tree-select";
import { TreeSelect } from "antd";

import CommonService from "../../../../services/CommonService";
import { IPageDirectoryItem } from "../../../../types/Page.types";


export interface IWebPageDirectorySelectProps {
  selectedDirectoryId?: number;
  onDirectoryChange?: (id: number) => void;
}

export const WebPageDirectorySelect: React.FC<IWebPageDirectorySelectProps> = ({
  selectedDirectoryId,
  onDirectoryChange
}) => {

  const [pageDirectoryItems, setPageDirectoryItems] = useState<IPageDirectoryItem[]>([]);
  const [selectedPageDirectoryItemId, setSelectedPageDirectoryItemId] = useState<number | undefined>(selectedDirectoryId);


  useEffect(() => {
    getPageDirectoryItems();
  }, [])


  const onChange = (id: number) => {
    setSelectedPageDirectoryItemId(id);
    if (onDirectoryChange) {
      onDirectoryChange(id);
    }
  };

  const getPageDirectoryItems = async () => {
    let url = `data/PageData?$select=id,parentId,name,inTrash`;
    const items = await CommonService.get(url);
    setPageDirectoryItems(items);
  }

  const renderChildrenTreeNodes = (parentId: number) => {
    let treeNodes: any[] = [];

    pageDirectoryItems.filter(p => p.parentId === parentId).forEach((pageDirectoryItem: IPageDirectoryItem) => {
      let treeNode = (
        <TreeNode value={pageDirectoryItem.id} title={`${pageDirectoryItem.id} - ${pageDirectoryItem.name}`}
          key={pageDirectoryItem.id} disabled={pageDirectoryItem.inTrash === true}>
          {renderChildrenTreeNodes(pageDirectoryItem.id)}
        </TreeNode>
      );
      treeNodes.push(treeNode);
    })

    return treeNodes;
  };


  return (
    <>
      <TreeSelect
        showSearch
        allowClear
        treeDefaultExpandAll
        className="w-100"
        value={selectedPageDirectoryItemId}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        onChange={(e) => onChange(e)}>

        {
          pageDirectoryItems.filter(p => p.parentId === -1 || p.parentId === 0 || p.parentId === null).map((pageDirectoryItem: IPageDirectoryItem) => {
            return (
              <TreeNode
                value={pageDirectoryItem.id} title={`${pageDirectoryItem.id} - ${pageDirectoryItem.name}`}
                key={pageDirectoryItem.id} disabled={pageDirectoryItem.inTrash === true}>
                {renderChildrenTreeNodes(pageDirectoryItem.id)}
              </TreeNode>
            )

          })
        }

      </TreeSelect>
    </>
  )
}