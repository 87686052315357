import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Modal, Row, Tag } from "antd";

import DataTableHelper from "../../../components/DataTable/DataTable.helper";
import { IRole } from "../../../types/Role.types";
import { DataTable } from "../../../components/DataTable/DataTable";
import { IUserDataTableDisplayModel, IUserRole } from "../../../types/User.types";
import { UsersToolBox } from "../../../components/WebsiteContent/Users/Users/UsersToolBox";
import { DataTableImportExcel } from "../../../components/DataTable/DataTableImportExcel";
import { UserUpdateModal } from "../../../components/WebsiteContent/Users/Users/UserUpdateModal"
import { IDataTableColumn, IDataTableSettings, IOverrideImportExcelResult, IShowHideColumnPubSubKey } from "../../../types/DataTable.types";

import { TRANSLATION_KEY } from "../../../constants/commonConstants";
import { DATA_TABLE_SHOW_HIDE_COLUMN, DATA_TABLE_SHOW_HIDE_IMPORT } from "../../../constants/pubSubKeys";
import { SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_USERS_KEY, USERS_DATATABLE_COLUMNS, USERS_DATATABLE_SETTINGS } from "../../../constants/usersConstants";
import CommonService from "../../../services/CommonService";
import Loader from "../../../utils/loader-handler";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import Notify from "../../../utils/notification-handler";


const { confirm } = Modal;

export const Users: React.FC = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [isShowExcelImport, setIsShowExcelImport] = useState<boolean>(false);
    const [rolesLookups, setRolesLookups] = useState<IRole[]>([]);
    const [dataTableSettings] = useState<IDataTableSettings>(USERS_DATATABLE_SETTINGS);
    const [dataTableColumns, setDataTableColumns] = useState<IDataTableColumn[]>(USERS_DATATABLE_COLUMNS);


    useEffect(() => {
        const init = async () => {
            let visibleColumns = await getVisibleColumnsFromSettings();
            resetColumnVisibilityWithSavedSettings(visibleColumns);
            await getUserRolesLookups();
        }
        init();

        let importPubSub = PubSub.subscribe(DATA_TABLE_SHOW_HIDE_IMPORT, (msg: string, isShow: boolean) => {
            setIsShowExcelImport(isShow);
        });
        let showHideColumnsPubSub = PubSub.subscribe(
            DATA_TABLE_SHOW_HIDE_COLUMN,
            (msg: string, model: IShowHideColumnPubSubKey) => {
                let _dataTableColumns = DataTableHelper.resetSingleColumnVisibility(
                    [...dataTableColumns],
                    model.columnKey,
                    model.isChecked
                );
                setDataTableColumns(_dataTableColumns);
            });
        return () => {
            PubSub.unsubscribe(importPubSub);
            PubSub.unsubscribe(showHideColumnsPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const resetColumnVisibilityWithSavedSettings = (visibleColumnsFromSettngs: string[]) => {
        let _dataTableColumns = DataTableHelper.resetColumnVisibility(dataTableColumns, visibleColumnsFromSettngs);
        if (_dataTableColumns) {
            setDataTableColumns(_dataTableColumns);
        }
    }

    const showHideExcelImport = (isShow: boolean) => {
        setIsShowExcelImport(isShow);
    }



    const modifyData = (data: any[]) => {
        data = data as IUserDataTableDisplayModel[];

        data.forEach((dataItem: IUserDataTableDisplayModel) => {
            let roleIds: number[] = [];
            dataItem.userRoles.forEach((userRole: IUserRole) => {
                roleIds.push(userRole.roleId);
            })
            dataItem.roleIds = roleIds;
        });

        return data;
    }

    const getRoleNameById = (roleId: number) => {
        let matchingRole = rolesLookups.find(r => r.id === roleId);
        return matchingRole ? matchingRole.name : '';
    }

    const getRoleIdByName = (roleName: string) => {
        let matchingRole = rolesLookups.find(r => r.name.toLowerCase() === roleName.toLowerCase());
        return matchingRole ? matchingRole.id : undefined;
    }

    const getVisibleColumnsFromSettings = async () => {
        return await DataTableHelper.getVisibleColumnsFromSettings(SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_USERS_KEY);
    }

    const getUserRolesLookups = async () => {
        let roles = await DataTableHelper.getUserRolesLookups();
        setRolesLookups(roles);
    }

    const generateRolesCustomDisplay = (value: any) => {
        let display: JSX.Element[] = [];
        let roleIds = value as number[];

        roleIds.forEach((roleId: number, index:number) => {
            let roleName = getRoleNameById(roleId);
            display.push(<Tag key={index} color="#87d068">{roleName}</Tag>);
        });

        return display;
    }

    const generateRolesCustomDisplayForExcel = (value: any) => {
        let roleIds = value as number[];
        let roleNames: string[] = [];

        roleIds.forEach((roleId: number) => {
            roleNames.push(getRoleNameById(roleId));
        });

        return roleNames.join(', ');
    }

    const generateCustomColumnDisplays = (key: string, value: any) => {
        switch (key) {
            case 'roleIds':
                return generateRolesCustomDisplay(value);
        }
        return <></>
    }

    const generateCustomColumnDisplaysForExcel = (key: string, value: any) => {
        switch (key) {
            case 'roleIds':
                return generateRolesCustomDisplayForExcel(value);

            default:
                return value;
        }
    }

    const overrideImport = (tableData: any[]): Promise<IOverrideImportExcelResult> => {
        return new Promise<IOverrideImportExcelResult>((resolve, reject) => {

            let error = modifyImportingTableData(tableData);
            if (error) {
                Notify.error(t('dataTable-excelImportFailed'));
                let result = {
                    isSuccess: false,
                    errorText: error
                };
                reject(result);
                return
            }

            confirm({
                title: t('user-importConfirm'),
                icon: <LockOutlined />,
                content: `${t('user-import-passwordEmailNotification')}`,
                okText: t('common-confirm'),
                okType: 'primary',
                cancelText: t('common-cancel'),
                onOk: async () => {

                    Loader.show();

                    let url = `data/${dataTableSettings.dataEndpoint}/Bulk/Insert`;
                    const response = await CommonService.post(url, tableData, true, true);

                    if (response && response.data && response.data.value >= 1) {
                        Notify.success(t('dataTable-dataImportedSuccesfully'));
                        Loader.hide();

                        resolve({ isSuccess: true });
                        return;
                    }

                    let result = {
                        isSuccess: false,
                        errorText: getImportError(response)
                    };
                    Notify.error(t('dataTable-excelImportFailed'));
                    Loader.hide();
                    reject(result);
                }
            });
        });
    }


    const modifyImportingTableData = (tableData: any[]) => {
        let invalidRoleNames: string[] = [];

        tableData.forEach((element: any) => {
            let userRoles: IUserRole[] = [];

            if (element.roleIds) {
                element.roleIds.split(',').forEach((roleName: string) => {
                    let roleId = getRoleIdByName(roleName);
                    if (roleId) {
                        userRoles.push({ roleId, userId: 0 });
                    } else {
                        if (!invalidRoleNames.find(r => r === roleName)) {
                            invalidRoleNames.push(roleName);
                        }

                    }
                });
            }
            element.userRoles = userRoles;
        });

        if (invalidRoleNames.length > 0) {
            let rolesString = invalidRoleNames.join(', ');
            return `${rolesString} - ${t('user-error-invalidRole')}`;
        }
    }


    const getImportError = (response: any) => {
        if (response && response.data && response.data.isError) {
            if (response.data.responseException && response.data.responseException.exceptionMessage) {
                if (response.data.responseException.exceptionMessage === 'Username already exists.') {
                    return t('user-error-usernameAlreadyExist');
                }
                return response.data.responseException.exceptionMessage;
            }
        }
    }


    return (
        <>
            {
                isShowExcelImport === false &&
                <Row gutter={16}>
                    <Col span={5} className="gutter-row">
                        <UsersToolBox dataTableColumns={dataTableColumns}></UsersToolBox>
                    </Col>
                    <Col span={19} className="gutter-row">
                        {
                            <DataTable<IUserDataTableDisplayModel>
                                dataTableSettings={dataTableSettings}
                                dataTableColumns={dataTableColumns}
                                modifyData={modifyData}
                                cutomDisplays={generateCustomColumnDisplays}
                                cutomDisplaysExcel={generateCustomColumnDisplaysForExcel}
                                lang={t}></DataTable>
                        }
                    </Col>
                </Row>
            }
            {
                isShowExcelImport === true &&
                <DataTableImportExcel
                    dataTableSettings={dataTableSettings}
                    dataTableColumns={dataTableColumns}
                    showHideExcelImport={showHideExcelImport}
                    overrideImport={overrideImport}
                    lang={t} />
            }

            <UserUpdateModal rolesLookups={rolesLookups}></UserUpdateModal>

        </>
    )
}

