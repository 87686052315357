import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';

import Notify from '../../../utils/notification-handler';
import DynamicIconHandler from '../../../utils/dynamic-icon-handler';
import CommonService from '../../../services/CommonService';
import { IBulkAction } from '../../../types/DataTable.types';
import { IBulkUpdateRequest } from '../../../types/Common.types';

import { TRANSLATION_KEY } from '../../../constants/commonConstants';
import { DATA_TABLE_RELOAD } from '../../../constants/pubSubKeys';


export interface ICommonRowToolItemProps {
    dataEndpoint: string;
    bulkAction: IBulkAction;
    selectedRowKeys: number[];
}

export const CommonRowToolItem: React.FC<ICommonRowToolItemProps> = ({
    dataEndpoint,
    bulkAction,
    selectedRowKeys
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [isShowActionModal, setIsShowActionModal] = useState<boolean>(false);


    const bulkActionClicked = () => {
        if (selectedRowKeys.length <= 0) {
            Notify.info(
                t('dataTable-notification-noItemsSelectedToProceed-content'),
                t('dataTable-notification-noItemsSelectedToProceed-title')
            );
            return;
        }
        setIsShowActionModal(true);
    }

    const closeActionModal = () => {
        setIsShowActionModal(false);
    }

    const bulkActionConfirmed = () => {
        let model: IBulkUpdateRequest = {
            idList: selectedRowKeys,
            data: []
        }
        bulkAction.properties.forEach((item) => {
            model.data.push({ propertyName: item.propertyDataIndex, value: item.propertyValue });
        });
        bulkUpdate(model);
        closeActionModal();
        PubSub.publish(DATA_TABLE_RELOAD);
    }

    const bulkUpdate = async (model: IBulkUpdateRequest) => {
        const odataResponse = await CommonService.post(`data/${dataEndpoint}/Bulk/Update`, model);

        if (odataResponse >= 0) {
        Notify.success(t('dataTable-notification-bulkAction-success'));
        } else {
          Notify.error(t('dataTable-notification-bulkAction-error'));
        }
    }

    return (
        <>
            <Button type="default"
                onClick={() => bulkActionClicked()}>
                {DynamicIconHandler.AntIcon({ type: bulkAction.iconName, className: 'text-blue' })}
                {t(bulkAction.languageKey)}
            </Button>
            {
                <Modal title={t(bulkAction.languageKey)} visible={isShowActionModal} onOk={bulkActionConfirmed} onCancel={closeActionModal}
                    okText={t('common-yes')} cancelText={t('common-no')}>
                    <p>
                        {
                            `${t('dataTable-areYouSureYouWantToPerformAction')} "${t(bulkAction.languageKey)}" ${t('dataTable-toSelected')} ${selectedRowKeys.length} ${t('dataTable-items')}?`
                        }
                    </p>
                    {
                        bulkAction.descriptionLanguageKey &&
                        <p className="mt-3"><b>{t('common-note')}: </b>
                            {t(bulkAction.descriptionLanguageKey)}</p>
                    }
                </Modal>
            }
        </>
    )
}