import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

import CommonMethods from "../../../utils/commonMethods";
import DataTableHelper from "../../../components/DataTable/DataTable.helper";

import { IRole } from "../../../types/Role.types";
import { IPage } from "../../../types/Page.types";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { DataTable } from "../../../components/DataTable/DataTable";
import { WebPageUpdateModal } from "../../../components/WebsiteContent/Pages/WebPages/WebPageUpdateModal";
import { WebPagesToolBox } from "../../../components/WebsiteContent/Pages/WebPages/WebPagesToolBox";
import { DataTableImportExcel } from "../../../components/DataTable/DataTableImportExcel";
import { WebPagesSourceColumnFilter } from "../../../components/WebsiteContent/Pages/WebPages/WebPagesSourceColumnFilter";
import { IDataTableColumn, IDataTableSettings, IFilter, IShowHideColumnPubSubKey } from "../../../types/DataTable.types";

import { DATA_TABLE_SHOW_HIDE_COLUMN, DATA_TABLE_SHOW_HIDE_IMPORT } from "../../../constants/pubSubKeys";
import { PAGE_STATUSES_EDITED, PAGE_STATUSES_PUBLISHED, PAGE_STATUSES_READYFORPUBLISH, PAGE_STATUSES_UNPUBLISHED, TRANSLATION_KEY } from "../../../constants/commonConstants";
import { WEB_PAGES_DATATABLE_COLUMNS, WEB_PAGES_DATATABLE_SETTINGS, SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_WEB_PAGES_KEY, } from "../../../constants/webPagesConstants";


export const WebPages: React.FC = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [isShowExcelImport, setIsShowExcelImport] = useState<boolean>(false);
    const [rolesLookups, setRolesLookups] = useState<IRole[]>([]);
    const [dataTableSettings] = useState<IDataTableSettings>(WEB_PAGES_DATATABLE_SETTINGS);
    const [dataTableColumns, setDataTableColumns] = useState<IDataTableColumn[]>(WEB_PAGES_DATATABLE_COLUMNS);


    useEffect(() => {
        const init = async () => {
            let visibleColumns = await getVisibleColumnsFromSettings();
            resetColumnVisibilityWithSavedSettings(visibleColumns);
            await getUserRolesLookups();
        }
        init();

        let importPubSub = PubSub.subscribe(DATA_TABLE_SHOW_HIDE_IMPORT, (msg: string, isShow: boolean) => {
            setIsShowExcelImport(isShow);
        });
        let showHideColumnsPubSub = PubSub.subscribe(
            DATA_TABLE_SHOW_HIDE_COLUMN,
            (msg: string, model: IShowHideColumnPubSubKey) => {
                let _dataTableColumns = DataTableHelper.resetSingleColumnVisibility(
                    [...dataTableColumns],
                    model.columnKey,
                    model.isChecked
                );
                setDataTableColumns(_dataTableColumns);
            });
        return () => {
            PubSub.unsubscribe(importPubSub);
            PubSub.unsubscribe(showHideColumnsPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetColumnVisibilityWithSavedSettings = (visibleColumnsFromSettngs: string[]) => {
        let _dataTableColumns = DataTableHelper.resetColumnVisibility(dataTableColumns, visibleColumnsFromSettngs);
        if (_dataTableColumns) {
            setDataTableColumns(_dataTableColumns);
        }
    }

    const showHideExcelImport = (isShow: boolean) => {
        setIsShowExcelImport(isShow);
    }

    const getVisibleColumnsFromSettings = async () => {
        return await DataTableHelper.getVisibleColumnsFromSettings(SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_WEB_PAGES_KEY);
    }

    const getUserRolesLookups = async () => {
        let roles = await DataTableHelper.getUserRolesLookups();
        setRolesLookups(roles);
    }

    const generateParentIdCustomDisplay = (value: any) => {
        if (value === -1 || value === 0) {
            return '';
        }
        else {
            return value;
        }
    }

    const generateCustomColumnDisplays = (dataIndex: string, value: any, fullRecord: IPage) => {
        switch (dataIndex) {
            case 'parentId':
                return generateParentIdCustomDisplay(value);
            case 'pageStatus':
                return CommonMethods.getWebPageStatusTag(fullRecord);
        }
        return <></>
    }

    const generateCustomColumnDisplaysForExcel = (dataIndex: string, value: any, fullRecord: IPage) => {
        switch (dataIndex) {
            case 'parentId':
                return generateParentIdCustomDisplay(value);
            case 'pageStatus':
                return CommonMethods.getWebPageStatusText(fullRecord);
        }
        return <></>
    }

    const generateCustomFilters = (dataIndex: string, column: IDataTableColumn, filterDropdownProps: FilterDropdownProps) => {
        switch (dataIndex) {
            case 'pageStatus':
                return (<WebPagesSourceColumnFilter dataIndex={dataIndex} filterDropdownProps={filterDropdownProps} />);
            default:
                return <></>;
        }
    }

    const overrideFilterQuery = (columnFilter: IFilter) => {
        if (columnFilter.columnName === 'pageStatus') {
            switch (columnFilter.value) {
                case PAGE_STATUSES_UNPUBLISHED:
                    return "$filter=(published eq false or published eq null) and (readyForPublish eq false or readyForPublish eq null)";
                case PAGE_STATUSES_READYFORPUBLISH:
                    return "$filter=published eq false and readyForPublish eq true";
                case PAGE_STATUSES_PUBLISHED:
                    return "$filter=published eq true and edited eq false";
                case PAGE_STATUSES_EDITED:
                    return "$filter=published eq true and edited eq true";
                default:
                    return '';
            }
        }
        return '';
    }


    return (
        <>
            {
                isShowExcelImport === false &&
                <Row gutter={16}>
                    <Col span={5} className="gutter-row">
                        <WebPagesToolBox
                            dataTableColumns={dataTableColumns}
                        ></WebPagesToolBox>
                    </Col>
                    <Col span={19} className="gutter-row">
                        {
                            <DataTable<IPage>
                                dataTableSettings={dataTableSettings}
                                dataTableColumns={dataTableColumns}
                                cutomDisplays={generateCustomColumnDisplays}
                                cutomDisplaysExcel={generateCustomColumnDisplaysForExcel}
                                customFilters={generateCustomFilters}
                                overrideOdataQueries={{ overrideFilterQuery: overrideFilterQuery }}
                                lang={t}></DataTable>
                        }
                    </Col>
                </Row>
            }
            {
                isShowExcelImport === true &&
                <DataTableImportExcel
                    dataTableSettings={dataTableSettings}
                    dataTableColumns={dataTableColumns}
                    showHideExcelImport={showHideExcelImport}
                    lang={t} />
            }

            <WebPageUpdateModal rolesLookups={rolesLookups}></WebPageUpdateModal>

        </>
    )
}