import React from "react";
import { useHistory } from 'react-router-dom';
import { DASHBOARD_PATH } from "../../../constants/commonConstants";
import { PRIMARY_NAV_CLICKED } from "../../../constants/pubSubKeys";

export const HeaderLogo: React.FC = () => {
    const history = useHistory();

    const redirectToDashboard = ()=>{
        PubSub.publish(PRIMARY_NAV_CLICKED, {  languageKey: 'dashboard', path: `/${DASHBOARD_PATH}`, secondaryNavItems:[]});
        history.push(`/${DASHBOARD_PATH}`);
    }

    return (
        <img className="logo-image" alt="Control Panel" onClick={redirectToDashboard} src="https://cdn.empori.se/customer_logotypes/svgs/empori-green-icon.svg" />
    );
}