import { IDataTableColumn, IDataTableSettings } from "../types/DataTable.types";
import { DATE, INT, STRING } from "./commonConstants";


export const SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_PROPERTY_CHANGE_LOG_KEY: string = 'SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_PROPERTY_CHANGE_LOG';


export const PROPERTY_CHANGE_LOG_DATATABLE_SETTINGS: IDataTableSettings = {
    defaultSortColumn: 'id',
    dataEndpoint: 'HistoryPropertyData',
    staticFilters: []
}


export const PROPERTY_CHANGE_LOG_DATATABLE_COLUMNS: IDataTableColumn[] = [
    {
        key: 'id',
        dataIndex: 'id',
        languageKey: '',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        isHiddenFromTableDisplay: true,
        isMandatory: true,
        dataType: INT
    },
    {
        key: 'lang',
        dataIndex: 'lang',
        languageKey: 'propertyChangeLog-language',
        isVisible: false,
        isSortable: true,
        isSearchable: false,
        dataType: INT,
        isCustomDisplay: true,
        isCustomFilter: true
    },
    {
        key: 'date',
        dataIndex: 'published',
        languageKey: 'propertyChangeLog-date',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: DATE
    },
    {
        key: 'user',
        dataIndex: 'publishedBy',
        languageKey: 'propertyChangeLog-user',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'pageId',
        dataIndex: 'pageId',
        languageKey: 'propertyChangeLog-pageId',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: INT
    },
    {
        key: 'control',
        dataIndex: 'control',
        languageKey: 'propertyChangeLog-control',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'name',
        dataIndex: 'name',
        languageKey: 'propertyChangeLog-name',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'value',
        dataIndex: 'value',
        languageKey: 'propertyChangeLog-value',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    }
];
