import React, { useEffect, useState } from "react";
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Col, Modal, Row } from 'antd';

import Notify from "../../../utils/notification-handler";
import OldApiService from "../../../services/OldApiService";
import { IDataTableColumn } from "../../../types/DataTable.types";
import { ToolBoxSearch } from "../../Common/DataTableToolBox/ToolBoxSearch";
import { ToolBoxCommonActions } from "../../Common/DataTableToolBox/ToolBoxCommonActions";
import { ToolBoxColumnSelector } from "../../Common/DataTableToolBox/ToolBoxColumnSelector";

import { ENDPOINTS } from "../../../services/endpoints";
import { TRANSLATION_KEY } from '../../../constants/commonConstants';
import { DATA_TABLE_ROW_SELECTION_CHANGED } from "../../../constants/pubSubKeys";
import { SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_FILE_DATA_KEY } from "../../../constants/fileDataConstants";



const { confirm } = Modal;


interface IFileDataToolBoxProps {
    dataTableColumns: IDataTableColumn[];
}

export const FileDataToolBox: React.FC<IFileDataToolBoxProps> = ({ dataTableColumns }) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);


    useEffect(() => {
        let rowSelectionPubSub = PubSub.subscribe(DATA_TABLE_ROW_SELECTION_CHANGED, (msg: string, selectedRowKeys: number[]) => {
            setSelectedRowKeys(selectedRowKeys);
        });
        return () => {
            PubSub.unsubscribe(rowSelectionPubSub);
        }
    }, []);


    const deleteTempFilesClicked = async () => {
        confirm({
            title: t('dataTable-deleteConfirm'),
            icon: <ExclamationCircleOutlined />,
            content: t('resources-fileData-deleteTempFilesConfirmMessage'),
            okText: t('common-yes'),
            okType: 'danger',
            cancelText: t('common-no'),
            onOk: deleteTempFilesConfirmed
        });
    }


    const deleteTempFilesConfirmed = async () => {
        //TODO test once backend is fixed
       await OldApiService.post(`${ENDPOINTS.DeleteTempfiles}`,
            {
                "sign": localStorage.getItem("sign")
            },true
        );
        Notify.success('Temp files deleted successfully')
        // if(response === true){
        //     Notify.success('Temp files deleted successfully')
        // }
        // console.log(response);
    }


    return (
        <Card title={t('resources-fileData-tableName')}>
            <p>{t('resources-fileData-tableDescription')}</p>

            {
                selectedRowKeys.length > 0 &&
                <p>{t('dataTable-selected')} <strong>{selectedRowKeys.length}</strong> {t('dataTable-items')}</p>
            }

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <ToolBoxSearch dataTableColumns={dataTableColumns} />
                </Col>
                <Col span={24}>
                    <ToolBoxCommonActions isShowCopy={false} isShowCreateNew={false} isShowImport={false} />
                    <Button onClick={deleteTempFilesClicked} className="mt-13-px">
                        <DeleteOutlined className="text-blue" /> {t('resources-fileData-deleteTempFiles')}
                    </Button>
                </Col>

            </Row>

            <ToolBoxColumnSelector dataTableColumns={dataTableColumns} settingsKey={SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_FILE_DATA_KEY} />

        </Card>
    );

}

