import Loader from "../../utils/loader-handler";
import Notify from "../../utils/notification-handler";
import CommonService from "../../services/CommonService";
import { IDataTableColumn } from "../../types/DataTable.types";
import { IRole } from "../../types/Role.types";
import { BOOLEAN, DATETIME, INT, STRING } from "../../constants/commonConstants";


// This class contain common methods that will be used in more than one data table related components
export default class DataTableHelper {

    static getNewTableItem = async (columns: IDataTableColumn[]) => {
        let newItem: any = {};
        columns.forEach((column: IDataTableColumn) => {

            if (typeof column.dataIndex === 'string' || column.dataIndex instanceof String) {
                newItem[column.dataIndex as string] = DataTableHelper.getDefaultValueForDataType(column.dataType);
            }
            else {
                let updatingObject = newItem;

                column.dataIndex.forEach((propertyName: string, index: number) => {
                    if (index === (column.dataIndex.length - 1)) {
                        updatingObject[propertyName] = DataTableHelper.getDefaultValueForDataType(column.dataType);
                    }
                    else {

                        if (updatingObject[propertyName] === undefined) {
                            updatingObject[propertyName] = {};
                        }

                        updatingObject = updatingObject[propertyName];
                    }
                });
            }

        });
        return newItem;
    }


    static getDefaultValueForDataType = (dataType: string) => {
        switch (dataType) {
            case STRING:
                return "";
            case INT:
                return 0;
            case BOOLEAN:
                return null;
            case DATETIME:
                return null;
        }
    }

    static resetColumnVisibility = (dataTableColumns: IDataTableColumn[], visibleColumnsFromSettngs?: string[]) => {
        if (!visibleColumnsFromSettngs || visibleColumnsFromSettngs.length <= 0) {
            return;
        }

        let _dataTableColumns = dataTableColumns;
        _dataTableColumns.forEach((column: IDataTableColumn) => {
            let matchingColumnFromApi = visibleColumnsFromSettngs.find(c => c === column.key);
            if (matchingColumnFromApi) {
                column.isVisible = true;
            } else {
                column.isVisible = false;
            }
        });
        return _dataTableColumns;
    }

    static resetSingleColumnVisibility = (dataTableColumns: IDataTableColumn[], columnKey: string, isChecked: boolean) => {
        let matchingColumnIndex = dataTableColumns.findIndex(c => c.key === columnKey);
        if (matchingColumnIndex !== -1) {
            dataTableColumns[matchingColumnIndex].isVisible = isChecked;
        }
        return dataTableColumns;
    }

    static saveColumnSettings = async (settingsKey: string, dataTableColumns: IDataTableColumn[], successMessage?: string) => {
        Loader.show();

        let visibleColumns: string[] = [];
        dataTableColumns.forEach((column: IDataTableColumn) => {
            if (column.isVisible === true) {
                visibleColumns.push(column.key);
            }
        });

        await CommonService.put('api/user/settings?key=' + settingsKey, visibleColumns);
        if (successMessage) {
            Notify.success(successMessage);
        }
        Loader.hide();
    }

    static getUserRolesLookups = async () => {
        let roles: IRole[] = []
        const odataResponse = await CommonService.get('data/RoleData?$select=id,name');
        if (odataResponse) {
            roles = odataResponse;
        }
        return roles;
    }

    static getVisibleColumnsFromSettings = async (settingsKey: string) => {

        //    NOTE : This functionality is temporarily removed, because backend is configured to 
        //                 save settiongs data in cache, we do not use cache anymore 
        return [];

        // let response = await CommonService.get(`api/user/settings?key=${settingsKey}`, false, true);

        // if (typeof response === 'string' || response instanceof String) {
        //     return JSON.parse(response as string);
        // }

        // if (response.status !== 404) {
        //     CommonService.handleErrors(response);
        // }

        // return [];
    }


}