import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Select,
  Card,
  Button,
  Space,
  Form,
  Input,
  Row,
  Col,
  Modal,
} from "antd";
import {
  PlusCircleOutlined,
  SaveOutlined,
  DeleteOutlined,
  RollbackOutlined,
  ExclamationCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons/lib/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Loader from "../../utils/loader-handler";
import Notify from "../../utils/notification-handler";
import CommonService from "../../services/CommonService";
import { IEmailTemplate } from "../../types/Email.types";
import { ViewSelector } from "../../components/Common/ViewSelector";

import { TRANSLATION_KEY } from "../../constants/commonConstants";
import { EXTERNAL_COMMUNICATION_EMAIL_AND_SMS_VIEWS } from "../../constants/innerViews";

const { confirm } = Modal;

export const EmailSettings: React.FC = () => {
  const [templateForm] = Form.useForm();
  const { t } = useTranslation([TRANSLATION_KEY.toString()]);
  const [templates, setTemplates] = useState<IEmailTemplate[]>([]);
  const [selectedValue, setSeletedValue] = useState<number>();
  const [isAddingNew, setIsAddingNew] = useState<boolean>(false);
  const [canDelete, setcanDelete] = useState<boolean>(false);

  const setTemplatesList = async () => {
    Loader.show();
    const response = await CommonService.get("api/email/templates");
    if (response) {
      var updatedItems = response.map((p: any) => {
        p.templateData = atob(p.templateData);
        return p;
      });
      setTemplates(updatedItems);
    }
    Loader.hide();
  };

  const isTemplateUse = async (value: number) => {
    const response = await CommonService.get(
      `api/system/emailTemplateIsUse/?templateId=${value}`,
      true
    );
    if (response) {
      setcanDelete(!response.data.value);
    }
  };

  const updateTemplate = async (template: IEmailTemplate) => {
    Loader.show();
    const response = await CommonService.patch("api/email/templates", template);
    if (response >= 0) {
      Notify.success(t("common-itemUpdatedSuccessfully"));
    } else {
      Notify.error(t("common-itemUpdatingFailed"));
    }
    Loader.hide();
  };

  const addTemplate = async (template: IEmailTemplate) => {
    Loader.show();
    const response = await CommonService.post("api/email/templates", template);
    if (response >= 1) {
      Notify.success(t("common-itemAddedSuccessfully"));
    } else {
      Notify.error(t("common-itemAddingFailed"));
    }
    Loader.hide();
  };

  const remove = async (id: number) => {
    Loader.show();
    const response = await CommonService.delete(`api/email/templates/${id}`);
    if (response >= 1) {
      Notify.success(t("common-itemDeletedSuccessfully"));
    } else {
      Notify.error(t("common-itemDeletingFailed"));
    }
  }
 

  const handleChange = async (value: number) => {
    setSeletedValue(value);
    var selectedTemplate = templates.filter((item) => item.id === value)[0];
    templateForm.setFieldsValue(selectedTemplate);
    await isTemplateUse(value);
  };

  const saveTemplate = async () => {
    var currentTemplate: IEmailTemplate = templateForm.getFieldsValue();
    currentTemplate.templateData = btoa(currentTemplate.templateData);

    if (currentTemplate.id === 0) {
      await addTemplate(currentTemplate);
    } else {
      await updateTemplate(currentTemplate);
    }

    await setTemplatesList();
    setIsAddingNew(false);
    setSeletedValue(undefined);
    templateForm.resetFields();
  };

  const addNewTemplate = async (emailTemplate?: IEmailTemplate) => {
    setIsAddingNew(true);
    var newTemplate: IEmailTemplate = emailTemplate
      ? emailTemplate
      : {
          id: 0,
          templateId: Date.now().toString(),
          templateName: "",
          regDate: new Date(),
          subject: "",
          fromAddress: "",
          templateData: "",
        };
    templateForm.setFieldsValue(newTemplate);
  };

  const cancelUpdateTemplate = async () => {
    setIsAddingNew(false);
    setSeletedValue(undefined);
    templateForm.resetFields();
  };

  const deleteTemplate = async () => {
    if (selectedValue) {
      await remove(selectedValue);
      setTemplatesList();
      setSeletedValue(undefined);
      templateForm.resetFields();
    }
  };

  const deleteBtnClicked = async () => {
    if (canDelete) {
      confirm({
        title: t("delete-confirm"),
        icon: <ExclamationCircleOutlined />,
        content: `${t("are-you-sure-you-want-to-delete")}}?`,
        okText: t("common-yes"),
        okType: "danger",
        cancelText: t("common-no"),
        onOk: deleteTemplate,
      });
    }
  };

  const copyBtnClicked = () => {
    var currentTemplate: IEmailTemplate = templateForm.getFieldsValue();
    currentTemplate.id = 0;
    currentTemplate.templateName = `${currentTemplate.templateName}_${t(
      "copy"
    )}`;
    currentTemplate.templateId = Date.now().toString();
    currentTemplate.regDate = new Date();

    addNewTemplate(currentTemplate);
  };

  useEffect(() => {
    setTemplatesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "image",
    "video",
  ];

  const listItems = (
    <div>
      <p>{t("email-templates")}</p>
      <Space>
        {isAddingNew === false && (
          <Select
            placeholder={t("select-template-label", "Select email template")}
            value={selectedValue}
            style={{ width: 320 }}
            onChange={handleChange}
          >
            {templates?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.templateName}
              </option>
            ))}
          </Select>
        )}
        {isAddingNew === false && selectedValue && (
          <Button onClick={copyBtnClicked} icon={<CopyOutlined />}>
            {t("copy")}
          </Button>
        )}
        {isAddingNew === false && selectedValue && (
          <Button
            onClick={deleteBtnClicked}
            icon={<DeleteOutlined />}
            disabled={!canDelete}
          >
            {t("delete")}
          </Button>
        )}
        {(selectedValue || (!selectedValue && isAddingNew)) && (
          <Button onClick={cancelUpdateTemplate} icon={<RollbackOutlined />}>
            {t("cancel")}
          </Button>
        )}
        {!selectedValue && !isAddingNew && (
          <Button
            type="primary"
            onClick={() => addNewTemplate()}
            icon={<PlusCircleOutlined />}
          >
            {t("add-new-template")}
          </Button>
        )}
        {(selectedValue || (!selectedValue && isAddingNew)) && (
          <Button
            type="primary"
            onClick={templateForm.submit}
            icon={<SaveOutlined />}
          >
            {t("save")}
          </Button>
        )}
      </Space>
    </div>
  );

  return (
    <>
      <Row gutter={16}>
        <Col span={5} className="gutter-row">
          <Card title={t("email-templates")}>
            <p>{t("email-templates-description")}</p>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <ViewSelector
                  views={EXTERNAL_COMMUNICATION_EMAIL_AND_SMS_VIEWS}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={19} className="gutter-row">
          <Card title={listItems}>
            {(selectedValue || (!selectedValue && isAddingNew)) && (
              <Form
                onFinish={saveTemplate}
                layout="vertical"
                form={templateForm}
                name="add_template_form"
                autoComplete="off"
              >
                <Form.Item hidden={true} name="id">
                  {" "}
                  <Input />{" "}
                </Form.Item>

                <Row gutter={[16, 16]}>
                  <Col xs={24} xl={10}>
                    <Form.Item
                      label={t("template-name", "Template Name")}
                      name="templateName"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "template-name-required",
                            "Template name is required"
                          ),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={10}>
                    <Form.Item
                      label={t("template-id", "Template Id")}
                      name="templateId"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "template-id-required",
                            "Template Id is required"
                          ),
                        },
                      ]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={10}>
                    <Form.Item
                      label={t("mail-subject", "Email Subject")}
                      name="subject"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "mail-subject-required",
                            "Email subject is required"
                          ),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={10}>
                    <Form.Item
                      label={t("from-address", "From Address")}
                      name="fromAddress"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "mail-from-address-required",
                            "Mail from address is required"
                          ),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={20}>
                    <Form.Item
                      label={t("mail-body", "Email Content")}
                      name="templateData"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "mail-content-required",
                            "Email content is required"
                          ),
                        },
                      ]}
                    >
                      <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
            {!selectedValue && !isAddingNew && (
              <p className="text-center mt-4 mb-4">
                {t("email-template-initial-message")}
              </p>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};
