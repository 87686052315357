import React from "react";
import { Layout } from "antd";
import { Content, Header } from "antd/lib/layout/layout";

import { Header as AppHeader } from '../Header/Header';
import { MainRoutes } from "../../../routes/main.routes";
import BasicFooter from "../Footer/BasicFooter";
import { Loader } from "../../Loader/Loader";


export const BasicLayout: React.FC = () => {

    return (
        <Layout className="basic-layout">
            <Header className="main-header">
                <AppHeader></AppHeader>
            </Header>
            <Content style={{ minHeight: "calc(100vh - 250px)" }}>
                <MainRoutes />
                <Loader />
            </Content>
            <BasicFooter footerTitle="Copyright 2021 Empori"></BasicFooter>
        </Layout>
    );

}