import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import JobScheduler from "../pages/Jobs/JobScheduler";
import { GoogleAnalytics } from "../pages/System/GoogleAnalytics";
import { SearchLog } from "../pages/System/SearchLog";
import { SearchSettings } from "../pages/System/SearchSettings";
import { SettingsChangeLog } from "../pages/System/SettingsChageLog";
import { SiteSettings } from "../pages/System/SiteSettings";
import { UserRole } from "../types/enum/UserRole.enum";
import { PrivateRoute } from "./private-route";

export const System: React.FC = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute
        path={`${path}/process-and-tasks`}
        component={JobScheduler}
        userRoles={[UserRole.Admin]}
      />
      <PrivateRoute
        path={`${path}/global-app-settings/site-settings`}
        component={SiteSettings}
        userRoles={[UserRole.Admin, UserRole.Editor]}
      />
      <PrivateRoute
        path={`${path}/search/search-log`}
        component={SearchLog}
        userRoles={[UserRole.Admin, UserRole.Editor]}
      />
      <PrivateRoute
        path={`${path}/search/search-settings`}
        component={SearchSettings}
        userRoles={[UserRole.Admin, UserRole.Editor]}
      />
      <PrivateRoute
        path={`${path}/google-analytics`}
        component={GoogleAnalytics}
        userRoles={[UserRole.Admin, UserRole.Editor]}
      />
      <PrivateRoute
        path={`${path}/settings-change-log`}
        component={SettingsChangeLog}
        userRoles={[UserRole.Admin, UserRole.Editor]}
      />
    </Switch>
  );
};
