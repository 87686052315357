import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

import DataTableHelper from "../../../components/DataTable/DataTable.helper";
import { IPageNotFoundError } from "../../../types/PageNotFoundErrors.types";
import { DataTable } from "../../../components/DataTable/DataTable";
import { PageNotFoundErrorsToolBox } from "../../../components/WebsiteContent/Pages/PageNotFoundErrors/PageNotFoundErrorsToolBox";
import { PageNotFoundErrorsUpdateModal } from "../../../components/WebsiteContent/Pages/PageNotFoundErrors/PageNotFoundErrorsUpdateModal";
import { IDataTableColumn, IDataTableSettings, IShowHideColumnPubSubKey } from "../../../types/DataTable.types";

import { TRANSLATION_KEY } from "../../../constants/commonConstants";
import { DATA_TABLE_SHOW_HIDE_COLUMN } from "../../../constants/pubSubKeys";
import { PAGE_NOT_FOUND_ERRORS_DATATABLE_COLUMNS, PAGE_NOT_FOUND_ERRORS_DATATABLE_SETTINGS, SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_PAGE_NOT_FOUND_ERRORS_KEY } from "../../../constants/pageNotFoundErrorsConstants";



export const PageNotFoundErrors: React.FC = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [dataTableSettings] = useState<IDataTableSettings>(PAGE_NOT_FOUND_ERRORS_DATATABLE_SETTINGS);
    const [dataTableColumns, setDataTableColumns] = useState<IDataTableColumn[]>(PAGE_NOT_FOUND_ERRORS_DATATABLE_COLUMNS);


    useEffect(() => {
        const init = async () => {
            let visibleColumns = await getVisibleColumnsFromSettings();
            resetColumnVisibilityWithSavedSettings(visibleColumns);
        }
        init();

        let showHideColumnsPubSub = PubSub.subscribe(
            DATA_TABLE_SHOW_HIDE_COLUMN,
            (msg: string, model: IShowHideColumnPubSubKey) => {
                let _dataTableColumns = DataTableHelper.resetSingleColumnVisibility(
                    [...dataTableColumns],
                    model.columnKey,
                    model.isChecked
                );
                setDataTableColumns(_dataTableColumns);
            });
        return () => {
            PubSub.unsubscribe(showHideColumnsPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetColumnVisibilityWithSavedSettings = (visibleColumnsFromSettngs: string[]) => {
        let _dataTableColumns = DataTableHelper.resetColumnVisibility(dataTableColumns, visibleColumnsFromSettngs);
        if (_dataTableColumns) {
            setDataTableColumns(_dataTableColumns);
        }
    }

    const getVisibleColumnsFromSettings = async () => {
        return await DataTableHelper.getVisibleColumnsFromSettings(SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_PAGE_NOT_FOUND_ERRORS_KEY);
    }


    return (
        <>
            <Row gutter={16}>
                <Col span={5} className="gutter-row">
                    <PageNotFoundErrorsToolBox dataTableColumns={dataTableColumns}></PageNotFoundErrorsToolBox>
                </Col>
                <Col span={19} className="gutter-row">
                    {
                        <DataTable<IPageNotFoundError>
                            dataTableSettings={dataTableSettings}
                            dataTableColumns={dataTableColumns}
                            lang={t}></DataTable>
                    }
                </Col>
            </Row>

            <PageNotFoundErrorsUpdateModal></PageNotFoundErrorsUpdateModal>

        </>
    )
}