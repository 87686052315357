import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { SwapOutlined } from '@ant-design/icons';

import Notify from '../../../../utils/notification-handler';
import CommonService from '../../../../services/CommonService';
import { WebPageDirectorySelect } from './WebPageDirectorySelect';
import { IBulkUpdateRequest } from '../../../../types/Common.types';

import { TRANSLATION_KEY } from '../../../../constants/commonConstants';
import { DATA_TABLE_RELOAD } from '../../../../constants/pubSubKeys';
import { WEB_PAGES_DATATABLE_SETTINGS } from '../../../../constants/webPagesConstants';


export interface IWebPageMoveRowToolProps {
    selectedRowKeys: number[];
}

export const WebPageMoveRowTool: React.FC<IWebPageMoveRowToolProps> = ({
    selectedRowKeys
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [isShowActionModal, setIsShowActionModal] = useState<boolean>(false);
    const [selectedDirectoryId, setSelectedDirectoryId] = useState<number | null>();


    const moveConfiremd =() =>{
        let model: IBulkUpdateRequest = {
            idList: selectedRowKeys,
            data: [{ propertyName: "parentId", value: selectedDirectoryId }]
        }
        bulkUpdate(model);
        closeModal();
        PubSub.publish(DATA_TABLE_RELOAD);
    }

    const directoryChanged = (id: number) => {
        setSelectedDirectoryId(id);
    }

    const moveBtnClicked = async () => {
        if (selectedRowKeys.length <= 0) {
            Notify.info(
                t('dataTable-notification-noItemsSelectedToDelete-content'),
                t('dataTable-notification-noItemsSelectedToDelete-title')
            );
            return;
        }
        setIsShowActionModal(true);
    }

    const closeModal = () => {
        setIsShowActionModal(false);
        setSelectedDirectoryId(null);
    }

    const bulkUpdate = async (model: IBulkUpdateRequest) => {
        const odataResponse = await CommonService.post(`data/${WEB_PAGES_DATATABLE_SETTINGS.dataEndpoint}/Bulk/Update`, model);

        if (odataResponse >= 0) {
        Notify.success(t('dataTable-notification-bulkAction-success'));
        } else {
          Notify.error(t('dataTable-notification-bulkAction-error'));
        }
    }


    return (
        <>
            <Button onClick={moveBtnClicked}>
                <SwapOutlined className="text-blue" /> {t('pages-rowtool-moveWebPages')}
            </Button>
            {
                <Modal title={t('pages-rowtool-moveWebPages')} visible={isShowActionModal} onOk={moveConfiremd} onCancel={closeModal}
                    okText={t('common-yes')} cancelText={t('common-no')}>
                    <p>{`${t('dataTable-selectDirectoryToMove')} ${selectedRowKeys.length} ${t('dataTable-items')}`}</p>
                    <WebPageDirectorySelect onDirectoryChange={directoryChanged} />
                    {
                        <p className="mt-3"><b>{t('common-note')}: </b> {t('pages-rowtool-moveWebPages-description')}</p>
                    }
                </Modal>
            }
        </>
    )
}