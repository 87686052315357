import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Input, Button } from 'antd';

import SettingService from '../../services/SettingsService';
import { TRANSLATION_KEY } from '../../constants/commonConstants';


const SystemSettings: React.FC = () => {
    const [setting, setSetting] = useState("");
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);


    const fetch = async () => {
        try {
            var response = await SettingService.getSystemSetting("BaseUrl");
            setSetting(response.data.d);

        } catch (error) {
            console.log(error);
        }
    };

    const save = async () => {
        try {
           await SettingService.setSystemSetting("BaseUrl", setting);

        } catch (error) {
            console.log(error);
        }
        setTimeout(() => {
            setLoading(false);
        }, 1000);

    };

    useEffect(() => {
        fetch();
    }, [ t]);

    const onchange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSetting(e.target.value);
    };

    const saveClick = () => {
        setLoading(true);
        save();
    };

    return (
        <div>
            <p>{t('systemSettings-hostAddress', 'Host address')}</p>
            <Input onChange={onchange} placeholder={t("systemSettings-pleaseInput", "Please input")} value={setting} />
            <Button onClick={saveClick} type="primary" htmlType="submit" loading={loading}>{t("common-save", "Save")}</Button>
        </div>
    );
};

export default SystemSettings;
