import React, { useState } from "react";
import PubSub from 'pubsub-js';
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import CommonMethods from "../../../utils/commonMethods";
import { ISecondaryNavItem, IPrimaryNavItem } from "../../../types/Layout.types";
import { TRANSLATION_KEY } from "../../../constants/commonConstants";
import { NAVIGATION_ITEMS } from "../../../constants/navigations";
import { PRIMARY_NAV_CLICKED, SECONDARY_NAV_CLICKED } from "../../../constants/pubSubKeys";



export const SecondaryNav: React.FC = () => {
  const { t } = useTranslation([TRANSLATION_KEY.toString()]);
  const [selectedPrimaryNavItem, setSelectedPrimaryNavItem] = useState<IPrimaryNavItem>();
  const location = useLocation();


  PubSub.subscribe(PRIMARY_NAV_CLICKED, (msg: string, primaryNavItem: IPrimaryNavItem) => {
    setSelectedPrimaryNavItem(primaryNavItem);
  });


  const navItemClicked = (secondaryNavItem: ISecondaryNavItem) => {
    PubSub.publish(SECONDARY_NAV_CLICKED, secondaryNavItem);
  }

  const isActiveSecondaryNav = (path: string) => {
    if (location.pathname === '/' && path === NAVIGATION_ITEMS[0].secondaryNavItems[0].path) {
      return true;
    }

    let currentPath = CommonMethods.removeFirstAndLastSlash(location.pathname);
    let navItemPath = CommonMethods.removeFirstAndLastSlash(path);

    if (currentPath.split('/')[1] === navItemPath.split('/')[1]) {
      return true;
    }

    return false;
  }

  return (
    <>

      <Menu mode="horizontal">
        {
          selectedPrimaryNavItem &&
          <Menu.Item >
          <span className="nav-name-display-container">
            {t(selectedPrimaryNavItem.languageKey)}
          </span>
          </Menu.Item>
        }
        {
          selectedPrimaryNavItem && selectedPrimaryNavItem.secondaryNavItems.map((secondaryNavItem: ISecondaryNavItem, index: number) => {
            return (
              <Menu.Item key={index} 
              //style={{opacity:1, position:'initial', height:'100%', overflowY:'auto'}}
                className={`secondary-nav-item ${(isActiveSecondaryNav(secondaryNavItem.path)) ? ' active ' : ''}${(index === 0) ? ' first ' : ''}`} >
                <Link to={secondaryNavItem.path} onClick={() => navItemClicked(secondaryNavItem)}>
                  {t(secondaryNavItem.languageKey)}
                </Link>
              </Menu.Item>
            )
          })
        }

      </Menu>

    </>
  );
}