import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Col, Divider, Row } from "antd";

import { IDataTableColumn, IShowHideColumnPubSubKey } from "../../../types/DataTable.types";
import { TRANSLATION_KEY } from "../../../constants/commonConstants";
import { DATA_TABLE_SHOW_HIDE_COLUMN, DATA_TABLE_SHOW_HIDE_COLUMN_SELECTOR } from "../../../constants/pubSubKeys";



export interface IToolBoxColumnSelectorProps {
    settingsKey: string;
    dataTableColumns: IDataTableColumn[];
}

export const ToolBoxColumnSelector: React.FC<IToolBoxColumnSelectorProps> = ({
    settingsKey,
    dataTableColumns
}) => {
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [isShowColumnSelector, setIsShowColumnSelector] = useState<boolean>(false);


    useEffect(() => {
        let columnSelectorPubSub = PubSub.subscribe(DATA_TABLE_SHOW_HIDE_COLUMN_SELECTOR, (msg: string, isShow: boolean) => {
            setIsShowColumnSelector(isShow);
        });
        return () => {
            PubSub.unsubscribe(columnSelectorPubSub);
        }
    }, []);


    const columnSelectChanged = (columnKey: string, isChecked: boolean) => {
        let model: IShowHideColumnPubSubKey = { columnKey, isChecked };
        PubSub.publish(DATA_TABLE_SHOW_HIDE_COLUMN, model);
    }

    //    NOTE : This functionality is temporarily removed, because backend is configured to 
    //                 save settiongs data in cache, we do not use cache anymore 

    // const saveColumnSettings = async () => {
    //     Loader.show();

    //     let visibleColumns: string[] = [];
    //     dataTableColumns.forEach((column: IDataTableColumn) => {
    //         if (column.isVisible === true) {
    //             visibleColumns.push(column.key);
    //         }
    //     });

    //     await CommonService.put(`api/user/settings?key=${settingsKey}`, visibleColumns);
    //     Notify.success(t('dataTable-columnSettingsSavedSuccessfully', 'Column settings saved successfully'));
    //     Loader.hide();
    // }

    return (
        <>

            {
                isShowColumnSelector &&
                <>
                    <Divider />
                    <h4>{t('dataTable-selectColumns', 'Select columns')}</h4>

                    <Row>
                        {
                            dataTableColumns.filter(c => c.isHiddenFromTableDisplay !== true).map((column: IDataTableColumn, index: number) => {
                                return <Col span={12} key={index}>
                                    <Checkbox checked={column.isVisible === true} onChange={(e) => columnSelectChanged(column.key, e.target.checked)}> {t(column.languageKey)}</Checkbox>
                                </Col>
                            })
                        }
                    </Row>

                    {/* NOTE : This functionality is temporarily removed, because backend is configured to 
                    save settiongs data in cache, we do not use cache anymore */}
                    {/* <Button block type="primary" className="mt-4" onClick={saveColumnSettings}>
                        {t('dataTable-saveColumnSettings')}
                    </Button> */}
                </>
            }
        </>
    );

}
