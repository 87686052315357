import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { MailOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { TRANSLATION_KEY } from '../../../../constants/commonConstants';
import CommonService from '../../../../services/CommonService';
import Notify from '../../../../utils/notification-handler';
import { DATA_TABLE_RELOAD } from '../../../../constants/pubSubKeys';


const { confirm } = Modal;


export interface ICommonRowToolItemProps {
    dataEndpoint: string;
    selectedRowKeys: number[];
    className?: string;
}

export const SendNewPasswordsRowTool: React.FC<ICommonRowToolItemProps> = ({
    dataEndpoint,
    selectedRowKeys,
    className
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);

    const sendConfirmed = async () => {
        let url = `data/${dataEndpoint}/password`;
        const odataResponse = await CommonService.post(url, selectedRowKeys);
        if (odataResponse === true) {
            PubSub.publish(DATA_TABLE_RELOAD);
            Notify.success(t('dataTable-notification-bulkAction-success'));
        } else {
            Notify.error(t('dataTable-notification-bulkAction-error'));
        }
    }

    const sendBtnClicked = async () => {
        if (selectedRowKeys.length <= 0) {
            Notify.info(
                t('dataTable-notification-noItemsSelectedToDelete-content'),
                t('dataTable-notification-noItemsSelectedToDelete-title')
            );
            return;
        }

        confirm({
            title: t('websiteContent-users-users-rowtool-sendNewPasswords'),
            icon: <ExclamationCircleOutlined />,
            content: `${t('websiteContent-users-users-rowtool-sendNewPasswords-description')}  
            ${t('dataTable-areYouSureYouWantToPerformAction')} "${t('websiteContent-users-users-rowtool-sendNewPasswords')}" 
            ${t('dataTable-toSelected')} ${selectedRowKeys.length} ${t('dataTable-items')}?`,
            okText: t('common-yes'),
            okType: 'danger',
            cancelText: t('common-no'),
            onOk: sendConfirmed
        });
    }

    return (
        <div className={className}>
            <Button onClick={sendBtnClicked}>
                <MailOutlined className="text-blue" /> {t('websiteContent-users-users-rowtool-sendNewPasswords')}
            </Button>
        </div>
    )
}