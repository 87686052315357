import React, { useEffect, useState } from "react";
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'antd';

import { IDataTableColumn } from "../../../types/DataTable.types";
import { ToolBoxSearch } from "../../Common/DataTableToolBox/ToolBoxSearch";
import { ToolBoxCommonActions } from "../../Common/DataTableToolBox/ToolBoxCommonActions";
import { ToolBoxColumnSelector } from "../../Common/DataTableToolBox/ToolBoxColumnSelector";

import { TRANSLATION_KEY } from '../../../constants/commonConstants';
import { DATA_TABLE_ROW_SELECTION_CHANGED } from "../../../constants/pubSubKeys";
import { SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_PROPERTY_CHANGE_LOG_KEY } from "../../../constants/propertyChangeLogConstants";



interface IPropertyChangeLogToolBoxProps {
    dataTableColumns: IDataTableColumn[];
}

export const PropertyChangeLogToolBox: React.FC<IPropertyChangeLogToolBoxProps> = ({ dataTableColumns }) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);


    useEffect(() => {
        let rowSelectionPubSub = PubSub.subscribe(DATA_TABLE_ROW_SELECTION_CHANGED, (msg: string, selectedRowKeys: number[]) => {
            setSelectedRowKeys(selectedRowKeys);
        });
        return () => {
            PubSub.unsubscribe(rowSelectionPubSub);
        }
    }, []);


    return (
        <Card title={t('webSiteContent-propertyChangeLog-tableName')}>
            <p>{t('webSiteContent-propertyChangeLog-tableDescription')}</p>

            {
                selectedRowKeys.length > 0 &&
                <p>{t('dataTable-selected')} <strong>{selectedRowKeys.length}</strong> {t('dataTable-items')}</p>
            }

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <ToolBoxSearch dataTableColumns={dataTableColumns} />
                </Col>
                <Col span={24}>
                    <ToolBoxCommonActions isShowCopy={false} isShowCreateNew={false} isShowImport={false} />
                </Col>

            </Row>

            <ToolBoxColumnSelector dataTableColumns={dataTableColumns} settingsKey={SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_PROPERTY_CHANGE_LOG_KEY} />

        </Card>
    );

}

