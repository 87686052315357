import React, { useEffect, useState } from "react";
import moment from "moment";
import CopyOutlined from "@ant-design/icons/lib/icons/CopyOutlined";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import { Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Select, Space } from "antd";

import Notify from "../../../../utils/notification-handler";
import CommonService from "../../../../services/CommonService";
import DefaultDataHandler from "../../../../utils/default-data-handler";
import { IRole } from "../../../../types/Role.types";
import { IUserKey, IUserKeyUpdateFormModel, } from "../../../../types/UserKey.types";
import { TRANSLATION_KEY } from "../../../../constants/commonConstants";
import { DATA_TABLE_HIDE_UPDATE_MODAL, DATA_TABLE_RELOAD, DATA_TABLE_SHOW_UPDATE_MODAL } from "../../../../constants/pubSubKeys";


const { Option } = Select;
const DEFAULT_ROLE = 'Base';

export interface IUserKeyUpdateModalProps {
    rolesLookups: IRole[];
}

export const UserKeyUpdateModal: React.FC<IUserKeyUpdateModalProps> = ({
    rolesLookups,
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSendMail, setIsSendMail] = useState<boolean>(false);
    const [userKeyUpdateFormModel, setUserKeyUpdateFormModel] = useState<IUserKeyUpdateFormModel | null>(null);



    useEffect(() => {
        let showModalPubSub = PubSub.subscribe(DATA_TABLE_SHOW_UPDATE_MODAL, async (msg: string, updatingItem: IUserKey | null) => {
            let model: IUserKeyUpdateFormModel = getUserKeyUpdatdFormModel(updatingItem);
            setUserKeyUpdateFormModel(model);
            setIsShowModal(true);
        });

        let hideModalPubSub = PubSub.subscribe(DATA_TABLE_HIDE_UPDATE_MODAL, () => {
            closeUpdateModal();
        })

        return () => {
            PubSub.unsubscribe(showModalPubSub);
            PubSub.unsubscribe(hideModalPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getUserKeyUpdatdFormModel = (userKey: IUserKey | null) => {
        let userKeyUpdateFormModel: IUserKeyUpdateFormModel = DefaultDataHandler.newUserKeyUpdateModel();


        if (userKey) {
            userKeyUpdateFormModel.id = userKey.id;
            userKeyUpdateFormModel.email = userKey.email;
            userKeyUpdateFormModel.status = userKey.status;
            userKeyUpdateFormModel.signKey = userKey.signKey;
            userKeyUpdateFormModel.roles = (userKey.roles !== null && userKey.roles !== '') ? userKey.roles.split(',') : [];
            userKeyUpdateFormModel.url = userKey.url;
            userKeyUpdateFormModel.expiryDate = userKey.expiryDate ? moment(userKey.expiryDate) : null;
            userKeyUpdateFormModel.regDate = userKey.regDate ? moment(userKey.regDate) : null;
        }
        else {
            userKeyUpdateFormModel.roles = [DEFAULT_ROLE];
            userKeyUpdateFormModel.signKey = uuidv4();
            userKeyUpdateFormModel.url = `${process.env.REACT_APP_BASE_URL}/${userKeyUpdateFormModel.signKey}`;

            let expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + 15);
            userKeyUpdateFormModel.expiryDate = moment(expiryDate);
        }
        return userKeyUpdateFormModel;
    }


    const getUserKeyFromFormModel = (userKeyUpdateFormModel: IUserKeyUpdateFormModel) => {
        let copyOfFormModel: any = { ...userKeyUpdateFormModel };
        copyOfFormModel.roles = (Array.isArray(copyOfFormModel.roles)) ? copyOfFormModel.roles.toString() : '';
        return copyOfFormModel as IUserKey;
    }

    const closeUpdateModal = () => {
        setIsSendMail(false);
        setIsShowModal(false);
        setUserKeyUpdateFormModel(null);
    }

    const copyToClipBoard = () => {
        if (userKeyUpdateFormModel && userKeyUpdateFormModel.url) {
            Notify.info(t('userKeys-userKeyUpdateModal-linkCopiedToClipboard'));
            navigator.clipboard.writeText(userKeyUpdateFormModel.url);
        }
    }

    const onFinish = (updatingItem: IUserKeyUpdateFormModel) => {
        let updatedUserKey = getUserKeyFromFormModel(updatingItem);

        if (updatedUserKey.id !== null && updatedUserKey.id !== 0) {
            edit(updatedUserKey);
            return;
        }

        add(updatedUserKey);
    };


    const add = async (updatingItem: IUserKey) => {
        setIsLoading(true);
        const odataResponse = await CommonService.post('data/UserKeyData', updatingItem);

        if (odataResponse && odataResponse.id) {

            if (isSendMail === true) {
                await sendEmail(odataResponse.id);
            }

            Notify.success(t('common-itemAddedSuccessfully', 'Item added successfully'));
        }

        PubSub.publish(DATA_TABLE_RELOAD);
        closeUpdateModal();
        setIsLoading(false);
    }


    const edit = async (updatingItem: IUserKey) => {
        setIsLoading(true);

        let url = `data/UserKeyData/${updatingItem.id}`;
        const odataResponse = await CommonService.patch(url, updatingItem);

        if (odataResponse === 0 || odataResponse === 1) {
            Notify.success(t('common-itemUpdatedSuccessfully', 'Item updated successfully'));
            if (isSendMail === true) {
                await sendEmail(updatingItem.id);
            }
        }

        PubSub.publish(DATA_TABLE_RELOAD);
        closeUpdateModal();
        setIsLoading(false);
    }

    const sendEmail = async (id: number) => {
        let url = 'data/UserKeyData/Bulk/SendEmail';
        const odataResponse = await CommonService.post(url, [id]);
        if (odataResponse && odataResponse === 1) {
            Notify.success(t('userCreationKeyEmailedSuccessfully'));
        } else {
            Notify.error(t('uesrCreationKeyEmailingFailed'));
        }
    }


    return (
        <>
            {
                userKeyUpdateFormModel &&
                <Modal title={(userKeyUpdateFormModel?.id !== 0) ? t('websiteContent-users-userCreationKeys-userCreationKeysEditModalTitle') : t('websiteContent-users-userCreationKeys-userCreationKeysAddModalTitle')}
                    className="data-table-item-update-modal user-key-update-modal"
                    visible={isShowModal && userKeyUpdateFormModel !== undefined && userKeyUpdateFormModel !== null}
                    onCancel={closeUpdateModal}
                    footer={null}>
                    <Form
                        name="data-table-item-update-form"
                        layout="vertical"
                        initialValues={userKeyUpdateFormModel}
                        onFinish={onFinish}>

                        <Form.Item hidden={true} name="id" > <Input /> </Form.Item>
                        <Form.Item hidden={true} name="signKey" > <Input /> </Form.Item>

                        <Row gutter={24}>

                            <Col span={24}>
                                <Form.Item
                                    name="email"
                                    label={t('userkey-emailAddress')}
                                    required={true}
                                    rules={[
                                        { required: true, message: `${t('common-validation-requiredValidationError')}` },
                                        { type: 'email', message: `${t('common-validation-emailValidationError')}` }]}>
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    name="url"
                                    label={t('userkey-link')}>
                                    <Input disabled={true} />
                                </Form.Item>
                                <Button onClick={copyToClipBoard} className="copy-to-clip-board-btn"><CopyOutlined /></Button>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    name="roles"
                                    label={t('userkey-roles')}
                                    required={true}
                                    rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                    <Select mode="multiple" allowClear className="w-100" >
                                        {
                                            rolesLookups.map(rolesLookup => {
                                                return <Option key={rolesLookup.name} value={rolesLookup.name}>{rolesLookup.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="expiryDate"
                                    label={t('userkey-expireDate')}>
                                    <DatePicker disabled={true} className="w-100" />
                                </Form.Item>
                            </Col>

                            {
                                userKeyUpdateFormModel?.id !== 0 && userKeyUpdateFormModel?.id !== null &&
                                <Col span={12}>
                                    <Form.Item
                                        name="regDate"
                                        label={t('userkey-createdDate')}>
                                        <DatePicker disabled={true} className="w-100" />
                                    </Form.Item>
                                </Col>
                            }
                            {
                                userKeyUpdateFormModel?.id !== 0 && userKeyUpdateFormModel?.id !== null &&
                                <Col span={24}>
                                    <Form.Item
                                        name="status"
                                        label={t('userkey-status')}>
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>
                            }

                        </Row>

                        <Divider />
                        <Row justify="end">
                            <Col span={24}>
                                <Space className="data-table-item-update-modal-footer">
                                    <Button onClick={closeUpdateModal}>
                                        {t('common-cancel', 'Cancel')}
                                    </Button>
                                    <Button type="primary" htmlType="submit" onClick={() => setIsSendMail(false)} loading={isLoading && isSendMail === false}>
                                        {(userKeyUpdateFormModel?.id !== 0) ? t('userKeys-userKeyUpdateModal-edit') : t('userKeys-userKeyUpdateModal-create')}
                                    </Button>
                                    <Button type="primary" htmlType="submit" onClick={() => setIsSendMail(true)} loading={isLoading && isSendMail === true}>
                                        {(userKeyUpdateFormModel?.id !== 0) ? t('userKeys-userKeyUpdateModal-editAndSend') : t('userKeys-userKeyUpdateModal-createAndSend')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>

                    </Form>
                </Modal >
            }
        </>

    )
}