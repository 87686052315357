import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

import DataTableHelper from "../../../components/DataTable/DataTable.helper";
import { IRedirect } from "../../../types/Redirects.types";
import { DataTable } from "../../../components/DataTable/DataTable";
import { RedirectsToolBox } from "../../../components/WebsiteContent/Pages/Redirects/RedirectsToolBox";
import { RedirectsUpdateModal } from "../../../components/WebsiteContent/Pages/Redirects/RedirectsUpdateModal";
import { IDataTableColumn, IDataTableSettings, IShowHideColumnPubSubKey } from "../../../types/DataTable.types";

import { TRANSLATION_KEY } from "../../../constants/commonConstants";
import { DATA_TABLE_SHOW_HIDE_COLUMN, DATA_TABLE_SHOW_HIDE_IMPORT } from "../../../constants/pubSubKeys";
import { REDIRECTS_DATATABLE_COLUMNS, REDIRECTS_DATATABLE_SETTINGS, SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_REDIRECTS_KEY } from "../../../constants/redirectsConstants";
import { DataTableImportExcel } from "../../../components/DataTable/DataTableImportExcel";



export const Redirects: React.FC = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [isShowExcelImport, setIsShowExcelImport] = useState<boolean>(false);
    const [dataTableSettings] = useState<IDataTableSettings>(REDIRECTS_DATATABLE_SETTINGS);
    const [dataTableColumns, setDataTableColumns] = useState<IDataTableColumn[]>(REDIRECTS_DATATABLE_COLUMNS);


    useEffect(() => {
        const init = async () => {
            let visibleColumns = await getVisibleColumnsFromSettings();
            resetColumnVisibilityWithSavedSettings(visibleColumns);
        }
        init();

        let importPubSub = PubSub.subscribe(DATA_TABLE_SHOW_HIDE_IMPORT, (msg: string, isShow: boolean) => {
            setIsShowExcelImport(isShow);
        });
        let showHideColumnsPubSub = PubSub.subscribe(
            DATA_TABLE_SHOW_HIDE_COLUMN,
            (msg: string, model: IShowHideColumnPubSubKey) => {
                let _dataTableColumns = DataTableHelper.resetSingleColumnVisibility(
                    [...dataTableColumns],
                    model.columnKey,
                    model.isChecked
                );
                setDataTableColumns(_dataTableColumns);
            });
        return () => {
            PubSub.unsubscribe(importPubSub);
            PubSub.unsubscribe(showHideColumnsPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const resetColumnVisibilityWithSavedSettings = (visibleColumnsFromSettngs: string[]) => {
        let _dataTableColumns = DataTableHelper.resetColumnVisibility(dataTableColumns, visibleColumnsFromSettngs);
        if (_dataTableColumns) {
            setDataTableColumns(_dataTableColumns);
        }
    }

    const getVisibleColumnsFromSettings = async () => {
        return await DataTableHelper.getVisibleColumnsFromSettings(SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_REDIRECTS_KEY);
    }

    const showHideExcelImport = (isShow: boolean) => {
        setIsShowExcelImport(isShow);
    }

    return (
        <>
            {
                isShowExcelImport === false &&

                <Row gutter={16}>
                    <Col span={5} className="gutter-row">
                        <RedirectsToolBox dataTableColumns={dataTableColumns}></RedirectsToolBox>
                    </Col>
                    <Col span={19} className="gutter-row">
                        {
                            <DataTable<IRedirect>
                                dataTableSettings={dataTableSettings}
                                dataTableColumns={dataTableColumns}
                                lang={t}></DataTable>
                        }
                    </Col>
                </Row>
            }
            {
                isShowExcelImport === true &&
                <DataTableImportExcel
                    dataTableSettings={dataTableSettings}
                    dataTableColumns={dataTableColumns}
                    showHideExcelImport={showHideExcelImport}
                    lang={t} />
            }

            <RedirectsUpdateModal></RedirectsUpdateModal>

        </>
    )
}