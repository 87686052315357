import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

import DataTableHelper from "../../../components/DataTable/DataTable.helper";

import { IUserKey } from "../../../types/UserKey.types";
import { DataTable } from "../../../components/DataTable/DataTable";
import { IDataTableColumn, IDataTableSettings, IShowHideColumnPubSubKey } from "../../../types/DataTable.types";
import { TRANSLATION_KEY } from "../../../constants/commonConstants";

import { IRole } from "../../../types/Role.types";

import { UserKeysToolBox } from "../../../components/WebsiteContent/Users/UserKeys/UserKeysToolBox";
import { UserKeyUpdateModal } from "../../../components/WebsiteContent/Users/UserKeys/UserKeyUpdateModal";
import { DATA_TABLE_SHOW_HIDE_COLUMN } from "../../../constants/pubSubKeys";
import { SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_USERKEYS_KEY, USERKEYS_DATATABLE_COLUMNS, USERKEYS_DATATABLE_SETTINGS } from "../../../constants/userKeys.constants";



export const UserKeys: React.FC = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [dataTableSettings] = useState<IDataTableSettings>(USERKEYS_DATATABLE_SETTINGS);
    const [dataTableColumns, setDataTableColumns] = useState<IDataTableColumn[]>(USERKEYS_DATATABLE_COLUMNS);
    const [visibleColumnsFromSettngs, setVisibleColumnsFromSettngs] = useState<string[]>();
    const [rolesLookups, setRolesLookups] = useState<IRole[]>([]);


    useEffect(() => {
        const init = async () => {
            await getVisibleColumnsFromSettings();
            await getUserRolesLookups();
        }
        init();


        let showHideColumnsPubSub = PubSub.subscribe(DATA_TABLE_SHOW_HIDE_COLUMN, (msg: string, model: IShowHideColumnPubSubKey) => {
            let _dataTableColumns = DataTableHelper.resetSingleColumnVisibility([...dataTableColumns], model.columnKey, model.isChecked);
            setDataTableColumns(_dataTableColumns);
        });
        return () => {
            PubSub.unsubscribe(showHideColumnsPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        const resetColumnVisibilityWithSavedSettings = () => {
            let _dataTableColumns = DataTableHelper.resetColumnVisibility(dataTableColumns, visibleColumnsFromSettngs);
            if (_dataTableColumns) {
                setDataTableColumns(_dataTableColumns);
            }
        }
        resetColumnVisibilityWithSavedSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleColumnsFromSettngs])


    const getVisibleColumnsFromSettings = async () => {
        let visibleColumns = await DataTableHelper.getVisibleColumnsFromSettings(SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_USERKEYS_KEY);
        setVisibleColumnsFromSettngs(visibleColumns);
    }

    const getUserRolesLookups = async () => {
        let roles = await DataTableHelper.getUserRolesLookups();
        setRolesLookups(roles);
    }

    return (
        <>
            <Row gutter={16}>
                <Col span={5} className="gutter-row">
                    <UserKeysToolBox
                        dataTableColumns={dataTableColumns}
                    ></UserKeysToolBox>
                </Col>
                <Col span={19} className="gutter-row">
                    {
                        <DataTable<IUserKey>
                            dataTableSettings={dataTableSettings}
                            dataTableColumns={dataTableColumns}
                            lang={t}>
                        </DataTable>
                    }
                </Col>
            </Row>

            <UserKeyUpdateModal rolesLookups={rolesLookups}></UserKeyUpdateModal>

        </>
    )
}