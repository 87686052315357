import { AxiosRequestConfig, AxiosResponse } from 'axios';

import axiosInstance from '../utils/axios/axiosInstance';
import { ENDPOINTS } from './endpoints';

class AuthService {

    public static async login(username: string, password: string): Promise<AxiosResponse> {
        var bodyFormData = new FormData();
        bodyFormData.append('username', username);
        bodyFormData.append('password', password);
       
        const requestConfig: AxiosRequestConfig = {
            method: 'post',
            url: `${ENDPOINTS.Token}`,
            headers: {
                'Access-Control-Allow-Origin': '*'
            },
            data: bodyFormData
            
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }
    public static async getToken(sign: string): Promise<AxiosResponse> {
       
        const requestConfig: AxiosRequestConfig = {
            method: 'post',
            url: `${ENDPOINTS.Invoke}`,
            headers: {
                'Content-Type': 'application/json',
              //  'Access-Control-Allow-Origin': '*',
            },
            data: {
                "sign":localStorage.getItem("sign"),
                "method": "GetToken",
                "url" : "/api/controlpanel",
                "args" : { "sign" : sign}
            }
            
        };
        const response: AxiosResponse = await axiosInstance(requestConfig);
        return response;
    }
}
export default AuthService;
