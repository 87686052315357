import { IPage } from "../types/Page.types";
import i18next from 'i18next';
import { Tag } from "antd";
import { UserRole } from "../types/enum/UserRole.enum";
import { PAGE_STATUSES_EDITED, PAGE_STATUSES_PUBLISHED, PAGE_STATUSES_READYFORPUBLISH, PAGE_STATUSES_UNPUBLISHED } from "../constants/commonConstants";

export default class CommonMethods {
  public static getAuthTokenParsed = () => {
    let token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const base64Url = token.split(".")[1];
    if (base64Url) {
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      return JSON.parse(window.atob(base64));
    } else {
      return;
    }
  };

  public static isJWTTokenValid(): boolean {
    let isValid: boolean = false;
    const decodedJwt = this.getAuthTokenParsed();
    if (decodedJwt) {
      if (decodedJwt !== null && decodedJwt.exp * 1000 > Date.now()) {
        isValid = true;
      }
    }
    return isValid;
  }

  public static getCurrentUsersUsername = () => {
    let tokenData = CommonMethods.getAuthTokenParsed();
    if (!tokenData || !tokenData.username) {
      return "";
    }
    return tokenData.username;
  };

  public static getCurrentUserRoles = () => {
    let tokenData = CommonMethods.getAuthTokenParsed();
    if (!tokenData || !tokenData.roles) {
      return [];
    }
    return tokenData.roles.split(",");
  };

  public static IsAdminUser = () => {
    let userRoles = CommonMethods.getCurrentUserRoles();
    let role = userRoles.find(
      (u: String) => u.toLowerCase() === UserRole[UserRole.Admin].toLowerCase()
    );
    if (role) {
      return true;
    } else {
      return false;
    }
  };

  public static removeFirstAndLastSlash = (url: string) => {
    let firstCharactor = url.slice(0, 1);
    let lastCharactor = url.slice(-1);

    if (firstCharactor === "/") {
      url = url.replace("/", "");
    }
    if (lastCharactor === "/") {
      url = url.replace(/.$/, "");
    }

    return url;
  };

  public static getWebPageStatus = (page: IPage) => {
    if (page.published === true && page.edited === true) {
      return PAGE_STATUSES_EDITED;
    }
    if (page.published === true) {
      return PAGE_STATUSES_PUBLISHED;
    }
    if (page.readyForPublish === true) {
      return PAGE_STATUSES_READYFORPUBLISH;
    }
    return PAGE_STATUSES_UNPUBLISHED;
  }

  public static getWebPageStatusText = (page: IPage) => {
    let status = CommonMethods.getWebPageStatus(page);

    switch (status) {
      case PAGE_STATUSES_EDITED:
        return i18next.t('page-status-edited');

      case PAGE_STATUSES_PUBLISHED:
        return i18next.t('page-status-published');

      case PAGE_STATUSES_READYFORPUBLISH:
        return i18next.t('page-status-readyForPubish');

      case PAGE_STATUSES_UNPUBLISHED:
        return i18next.t('page-status-unpublished');
    }
  }

  public static getWebPageStatusTag = (page: IPage) => {
    let status = CommonMethods.getWebPageStatus(page);

    switch (status) {
      case PAGE_STATUSES_EDITED:
        return <Tag color="blue">{i18next.t('page-status-edited')}</Tag>;

      case PAGE_STATUSES_PUBLISHED:
        return <Tag color="green">{i18next.t('page-status-published')}</Tag>;

      case PAGE_STATUSES_READYFORPUBLISH:
        return <Tag color="gold">{i18next.t('page-status-readyForPubish')}</Tag>;

      case PAGE_STATUSES_UNPUBLISHED:
        return <Tag color="red">{i18next.t('page-status-unpublished')}</Tag>;
    }
  }


}
