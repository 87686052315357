import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Form, Row, Switch } from "antd";

import { TRANSLATION_KEY } from "../../../constants/commonConstants";
import {
  WEBSITE_CONTENT_PAGES_VIEWS_ADMIN,
  WEBSITE_CONTENT_PAGES_VIEWS_EDITOR,
} from "../../../constants/innerViews";
import { TRY_REDIRECT_BEFORE_PAGE_LOOKUP } from "../../../constants/settingsConstant";
import {
  ISettingsItem,
  ISettingsItemUpdateModel,
} from "../../../types/Common.types";

import Loader from "../../../utils/loader-handler";
import Notify from "../../../utils/notification-handler";
import CommonService from "../../../services/CommonService";
import { ViewSelector } from "../../../components/Common/ViewSelector";
import CommonMethods from "../../../utils/commonMethods";

export const RedirectSettings: React.FC = () => {
  const { t } = useTranslation([TRANSLATION_KEY.toString()]);
  const [form] = Form.useForm();

  useEffect(() => {
    getRedirectSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRedirectSettings = async () => {
    let response: ISettingsItem[] = await CommonService.post(
      `api/system/settingsList`,
      [TRY_REDIRECT_BEFORE_PAGE_LOOKUP]
    );

    let formModel: any = {};
    let matchingItem = response.find(
      (s) => s.key === TRY_REDIRECT_BEFORE_PAGE_LOOKUP
    );
    formModel[TRY_REDIRECT_BEFORE_PAGE_LOOKUP] =
      matchingItem && matchingItem.value === "True";
    form.setFieldsValue(formModel);
  };

  const onFinish = async (updatingItem: any) => {
    Loader.show();

    let settingsUpdateModel: ISettingsItemUpdateModel[] = [];
    Object.keys(updatingItem).forEach((e: string) => {
      let value = updatingItem[e];

      if (typeof value == "boolean") {
        value = value === true ? "True" : "False";
      }

      settingsUpdateModel.push({
        key: e,
        value: value,
      });
    });

    let response = await CommonService.post(
      `api/system/settings`,
      settingsUpdateModel
    );
    if (response === true) {
      Notify.success(t("common-itemUpdatedSuccessfully"));
    } else {
      Notify.error(t("common-itemUpdatingFailed"));
    }

    Loader.hide();
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={5} className="gutter-row">
          <Card title={t("redirectSettings")}>
            <p>{t("redirectSettings-tableDescription")}</p>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                {CommonMethods.IsAdminUser() ? (
                  <ViewSelector views={WEBSITE_CONTENT_PAGES_VIEWS_ADMIN} />
                ) : (
                  <ViewSelector views={WEBSITE_CONTENT_PAGES_VIEWS_EDITOR} />
                )}
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={19} className="gutter-row">
          <Form
            form={form}
            onFinish={onFinish}
            name="data-table-item-update-form"
            layout="vertical"
          >
            <Card
              title={t("redirectSettings")}
              extra={
                <Button type="primary" htmlType="submit">
                  {t("common-save")}
                </Button>
              }
            >
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    valuePropName="checked"
                    name={TRY_REDIRECT_BEFORE_PAGE_LOOKUP}
                    label={t("redirectSettings-requireHttpsMovedPermanent")}
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
        </Col>
      </Row>
    </>
  );
};
