import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

import DataTableHelper from "../../../components/DataTable/DataTable.helper";
import { IRole } from "../../../types/Role.types";
import { DataTable } from "../../../components/DataTable/DataTable";
import { RolesToolBox } from "../../../components/WebsiteContent/Users/Roles/RolesToolBox";
import { RoleUpdateModal } from "../../../components/WebsiteContent/Users/Roles/RoleUpdateModal";
import { IDataTableColumn, IDataTableSettings, IShowHideColumnPubSubKey } from "../../../types/DataTable.types";

import { TRANSLATION_KEY } from "../../../constants/commonConstants";
import { DATA_TABLE_SHOW_HIDE_COLUMN } from "../../../constants/pubSubKeys";
import { ROLES_DATATABLE_COLUMNS, ROLES_DATATABLE_SETTINGS, SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_ROLES_KEY } from "../../../constants/rolesConstants";



export const Roles: React.FC = () => {
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [dataTableSettings] = useState<IDataTableSettings>(ROLES_DATATABLE_SETTINGS);
    const [dataTableColumns, setDataTableColumns] = useState<IDataTableColumn[]>(ROLES_DATATABLE_COLUMNS);


    useEffect(() => {
        const init = async () => {
            let visibleColumns = await getVisibleColumnsFromSettings();
            resetColumnVisibilityWithSavedSettings(visibleColumns);
        }
        init();

        let showHideColumnsPubSub = PubSub.subscribe(
            DATA_TABLE_SHOW_HIDE_COLUMN,
            (msg: string, model: IShowHideColumnPubSubKey) => {
                let _dataTableColumns = DataTableHelper.resetSingleColumnVisibility(
                    [...dataTableColumns],
                    model.columnKey,
                    model.isChecked
                );
                setDataTableColumns(_dataTableColumns);
            });

        return () => {
            PubSub.unsubscribe(showHideColumnsPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const resetColumnVisibilityWithSavedSettings = (visibleColumnsFromSettngs: string[]) => {
        let _dataTableColumns = DataTableHelper.resetColumnVisibility(dataTableColumns, visibleColumnsFromSettngs);
        if (_dataTableColumns) {
            setDataTableColumns(_dataTableColumns);
        }
    }

    const getVisibleColumnsFromSettings = async () => {
        return await DataTableHelper.getVisibleColumnsFromSettings(SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_ROLES_KEY);
    }


    return (
        <>
            <Row gutter={16}>
                <Col span={5} className="gutter-row">
                    <RolesToolBox dataTableColumns={dataTableColumns}></RolesToolBox>
                </Col>
                <Col span={19} className="gutter-row">
                    {
                        <DataTable<IRole>
                            dataTableSettings={dataTableSettings}
                            dataTableColumns={dataTableColumns}
                            lang={t}></DataTable>
                    }
                </Col>
            </Row>
            <RoleUpdateModal></RoleUpdateModal>
        </>
    );
}