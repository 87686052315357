import axios, { AxiosInstance } from 'axios';
import https from 'https';

const axiosInstance: AxiosInstance = axios.create({
    timeout: 10000,
    httpsAgent: new https.Agent({
        rejectUnauthorized: false
    })
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {

        return Promise.reject(error.response);
    },
);

axiosInstance.interceptors.request.use(async config => {
    //TODO: Temp remove once actual authentication is configured
    let tokenFromStorage = localStorage.getItem('token')?.toString();
    if(tokenFromStorage==='undefined')
    {
        localStorage.removeItem('token');
        return config;
    }
    if (config.url &&
        process.env.REACT_APP_NEW_API_ENDPOINT &&
        config.url.includes(process.env.REACT_APP_NEW_API_ENDPOINT)) {
        config.headers.authorization = `Bearer ` + tokenFromStorage;
    }

    return config;

}, error => Promise.reject(error));

export default axiosInstance;
