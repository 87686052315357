import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Tag } from "antd";

import DataTableHelper from "../../components/DataTable/DataTable.helper";
import { IRole } from "../../types/Role.types";
import { IDirectoryData } from "../../types/DirectoryData.types";
import { DataTable } from "../../components/DataTable/DataTable";
import { DirectoryDataToolBox } from "../../components/Resources/DirectoryData/DirectoryDataToolBox";
import { DirectoryDataUpdateModal } from "../../components/Resources/DirectoryData/DirectoryDataUpdateModal";
import { IDataTableColumn, IDataTableSettings, IShowHideColumnPubSubKey } from "../../types/DataTable.types";
import { TRANSLATION_KEY } from "../../constants/commonConstants";
import { DATA_TABLE_SHOW_HIDE_COLUMN } from "../../constants/pubSubKeys";
import { DIRECTORY_DATA_DATATABLE_COLUMNS, DIRECTORY_DATA_DATATABLE_SETTINGS, SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_DIRECTORY_DATA_KEY } from "../../constants/directoryDataConstants";



export const DirectoryData: React.FC = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [dataTableSettings] = useState<IDataTableSettings>(DIRECTORY_DATA_DATATABLE_SETTINGS);
    const [dataTableColumns, setDataTableColumns] = useState<IDataTableColumn[]>(DIRECTORY_DATA_DATATABLE_COLUMNS);
    const [rolesLookups, setRolesLookups] = useState<IRole[]>([]);


    useEffect(() => {
        const init = async () => {
            let visibleColumns = await getVisibleColumnsFromSettings();
            resetColumnVisibilityWithSavedSettings(visibleColumns);
            await getUserRolesLookups();
        }
        init();

        let showHideColumnsPubSub = PubSub.subscribe(
            DATA_TABLE_SHOW_HIDE_COLUMN,
            (msg: string, model: IShowHideColumnPubSubKey) => {
                let _dataTableColumns = DataTableHelper.resetSingleColumnVisibility(
                    [...dataTableColumns],
                    model.columnKey,
                    model.isChecked
                );
                setDataTableColumns(_dataTableColumns);
            });
        return () => {
            PubSub.unsubscribe(showHideColumnsPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const generateRolesCustomDisplay = (value: any) => {
        let display: JSX.Element[] = [];
        let roleIds = value as number[];

        roleIds.forEach((roleId: number) => {
            let roleName = getRoleNameById(roleId);
            display.push(<Tag color="#87d068">{roleName}</Tag>);
        });

        return display;
    }

    const generateCustomColumnDisplays = (key: string, value: any) => {
        switch (key) {
            case 'roleIds':
                return generateRolesCustomDisplay(value);
        }
        return <></>
    }

    const getRoleNameById = (roleId: number) => {
        let matchingRole = rolesLookups.find(r => r.id === roleId);
        return matchingRole ? matchingRole.name : '';
    }

    const resetColumnVisibilityWithSavedSettings = (visibleColumnsFromSettngs: string[]) => {
        let _dataTableColumns = DataTableHelper.resetColumnVisibility(dataTableColumns, visibleColumnsFromSettngs);
        if (_dataTableColumns) {
            setDataTableColumns(_dataTableColumns);
        }
    }


    const getVisibleColumnsFromSettings = async () => {
        return await DataTableHelper.getVisibleColumnsFromSettings(SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_DIRECTORY_DATA_KEY);
    }


    const getUserRolesLookups = async () => {
        let roles = await DataTableHelper.getUserRolesLookups();
        setRolesLookups(roles);
    }


    return (
        <>
            <Row gutter={16}>
                <Col span={5} className="gutter-row">
                    <DirectoryDataToolBox dataTableColumns={dataTableColumns}></DirectoryDataToolBox>
                </Col>
                <Col span={19} className="gutter-row">
                    {
                        <DataTable<IDirectoryData>
                            dataTableSettings={dataTableSettings}
                            dataTableColumns={dataTableColumns}
                            cutomDisplays={generateCustomColumnDisplays}
                            lang={t}></DataTable>
                    }
                </Col>
            </Row>

            <DirectoryDataUpdateModal rolesLookups={rolesLookups}></DirectoryDataUpdateModal>

        </>
    )
}