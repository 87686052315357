import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Divider, Form, Input, Modal, Row, Space } from "antd";

import Notify from "../../../../utils/notification-handler";
import CommonService from "../../../../services/CommonService";
import DefaultDataHandler from "../../../../utils/default-data-handler";
import { TRANSLATION_KEY } from "../../../../constants/commonConstants";
import { DATA_TABLE_HIDE_UPDATE_MODAL, DATA_TABLE_RELOAD, DATA_TABLE_SHOW_UPDATE_MODAL } from "../../../../constants/pubSubKeys";
import { IRole } from "../../../../types/Role.types";
import { STANDARD_ROLE_ADMIN, STANDARD_ROLE_BASE, STANDARD_ROLE_EDITOR, STANDARD_ROLE_STRUCTURE } from "../../../../constants/rolesConstants";



export interface IRoleUpdateModalProps {
}

export const RoleUpdateModal: React.FC<IRoleUpdateModalProps> = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [updatingFormModel, setUpdatingFormModel] = useState<IRole | null>(null);
    const [allRoles, setAllRoles] = useState<IRole[]>([]);

    const closeUpdateModal = () => {
        setUpdatingFormModel(null);
        setIsShowModal(false);

    }

    useEffect(() => {
        getAllRoles();

        let showModalPubSub = PubSub.subscribe(DATA_TABLE_SHOW_UPDATE_MODAL, async (msg: string, updatingItem: IRole | null) => {

            if (updatingItem === null) {
                updatingItem = DefaultDataHandler.newRole();
            }

            setUpdatingFormModel(updatingItem);
            setIsShowModal(true);

        });

        let hideModalPubSub = PubSub.subscribe(DATA_TABLE_HIDE_UPDATE_MODAL, () => {
            closeUpdateModal();
        })

        return () => {
            PubSub.unsubscribe(showModalPubSub);
            PubSub.unsubscribe(hideModalPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const isStandardRole = (roleName: string) => {
        let roleNameLowerCased = roleName.toLowerCase();
        if (roleNameLowerCased === STANDARD_ROLE_ADMIN.toLowerCase() ||
            roleNameLowerCased === STANDARD_ROLE_EDITOR.toLowerCase() ||
            roleNameLowerCased === STANDARD_ROLE_STRUCTURE.toLowerCase() ||
            roleNameLowerCased === STANDARD_ROLE_BASE.toLowerCase()) {
            return true;
        }

        return false;
    }

    const isRoleExist = (role: IRole) => {
        if ((role.id === 0 || role.id === null) && allRoles.findIndex(r => r.name.toLowerCase() === role.name.toLowerCase()) !== -1) {
            return true;
        }
        if ((role.id !== 0 && role.id !== null) && allRoles.findIndex(r => r.id !== role.id && r.name.toLowerCase() === role.name.toLowerCase()) !== -1) {
            return true;
        }
        return false;
    }

    const getAllRoles = async () => {
        const odataResponse = await CommonService.get('data/RoleData?$select=id,name');
        if (odataResponse) {
            setAllRoles(odataResponse);
        }
    }

    const onFinish = (updatedRole: IRole) => {
        if (isRoleExist(updatedRole) === true) {
            Notify.info(t('websiteContent-users-roles-roleExistNotification'));
            return;
        }

        if (updatedRole.id !== 0) {
            edit(updatedRole);
            return;
        }
        add(updatedRole);
    };


    const add = async (updatingItem: IRole) => {
        setIsLoading(true);
        const odataResponse = await CommonService.post('data/RoleData', updatingItem);

        if (odataResponse) {
            Notify.success(t('common-itemAddedSuccessfully'));
        } else {
            Notify.error(t('common-itemAddingFailed'));
        }

        PubSub.publish(DATA_TABLE_RELOAD);
        closeUpdateModal();
        setIsLoading(false);
    }


    const edit = async (updatingItem: IRole) => {
        setIsLoading(true);
        let url = `data/RoleData/${updatingItem.id}`;
        const odataResponse = await CommonService.patch(url, updatingItem);

        if (odataResponse === 0 || odataResponse === 1) {
            Notify.success(t('common-itemUpdatedSuccessfully'));
        } else {
            Notify.error(t('common-itemUpdatingFailed'));
        }

        PubSub.publish(DATA_TABLE_RELOAD);
        closeUpdateModal();
        setIsLoading(false);
    }


    return (
        <>
            {
                updatingFormModel &&
                <Modal title={(updatingFormModel.id !== 0) ? t('websiteContent-users-roles-rolesEditModalTitle') : t('websiteContent-users-roles-rolesAddModalTitle')}
                    className="data-table-item-update-modal"
                    visible={isShowModal && updatingFormModel !== null}
                    onCancel={closeUpdateModal} footer={null}>

                    <Form
                        name="data-table-item-update-form"
                        layout="vertical"
                        initialValues={updatingFormModel}
                        onFinish={onFinish}>
                        <Form.Item hidden={true} name="id" > <Input /> </Form.Item>

                        {
                            isStandardRole(updatingFormModel.name) &&
                            <p>{t('websiteContent-users-roles-standardRolesNotification')}</p>
                        }

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="name"
                                    label={t('role-name')}
                                    required={true}
                                    rules={[{ required: true, message: `${t('common-validation-requiredValidationError')}` }]}>
                                    <Input disabled={isStandardRole(updatingFormModel.name)} />
                                </Form.Item>
                            </Col>
                            {
                                updatingFormModel.id !== 0 && updatingFormModel.id !== null &&
                                <Col span={24}>
                                    <div className="ant-col ant-form-item-label">
                                        <label>{t('role-noOfUsers')}</label>
                                    </div>
                                    <Input disabled={true} value={updatingFormModel.userRoles ? updatingFormModel.userRoles.length : 0} />
                                </Col>
                            }
                        </Row>

                        <Divider />

                        <Row justify="end">
                            <Col span={24}>
                                <Space className="data-table-item-update-modal-footer">
                                    <Button onClick={closeUpdateModal}>
                                        {t('common-cancel')}
                                    </Button>
                                    <Button type="primary" htmlType="submit" loading={isLoading} disabled={isStandardRole(updatingFormModel.name)}>
                                        {t('common-save')}
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>

                </Modal>
            }
        </>
    )
}