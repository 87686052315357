import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Row, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

import { TRANSLATION_KEY } from '../../constants/commonConstants';
import { SYSTEM_GLOBAL_APP_SETTINGS_VIEWS } from '../../constants/innerViews';
import { REQUIRE_HTTPS, REQUIRE_HTTPS_MOVED_PERMENANT, SITE_NAME, SITE_URL } from '../../constants/settingsConstant';

import Loader from '../../utils/loader-handler';
import Notify from '../../utils/notification-handler';
import CommonService from '../../services/CommonService';
import { ISettingsItem, ISettingsItemUpdateModel } from '../../types/Common.types';
import { ViewSelector } from '../../components/Common/ViewSelector';


export const SiteSettings: React.FC = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [form] = Form.useForm();
    const [isShowMovedPermenent, setIsShowMovedPermenent] = useState<boolean>();

    useEffect(() => {
        getSiteSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getSiteSettings = async () => {
        let response: ISettingsItem[] = await CommonService.post(`api/system/settingsList`, [
            SITE_NAME, SITE_URL, REQUIRE_HTTPS, REQUIRE_HTTPS_MOVED_PERMENANT
        ]);

        let formModel: any = {};

        formModel[SITE_NAME] = getSettingsValueByKey(response, SITE_NAME);
        formModel[SITE_URL] = getSettingsValueByKey(response, SITE_URL);
        formModel[REQUIRE_HTTPS] = getSettingsValueByKey(response, REQUIRE_HTTPS);
        formModel[REQUIRE_HTTPS_MOVED_PERMENANT] = getSettingsValueByKey(response, REQUIRE_HTTPS_MOVED_PERMENANT);

        setIsShowMovedPermenent(formModel[REQUIRE_HTTPS] === true);
        form.setFieldsValue(formModel);
    }

    const getSettingsValueByKey = (settings: ISettingsItem[], key: string) => {
        let matchingItem = settings.find(s => s.key === key);

        if (!matchingItem) {
            return null;
        }

        switch (matchingItem.type) {
            case 'Boolean':
                return matchingItem.value === 'True';
            case 'String':
                return matchingItem.value;
            default:
                return matchingItem.value;
        }
    }

    const requireHttpsChanged = (value: boolean) => {
        setIsShowMovedPermenent(value);
    }

    const onFinish = async (updatingItem: any) => {
        Loader.show();

        let settingsUpdateModel: ISettingsItemUpdateModel[] = [];
        Object.keys(updatingItem).forEach((e: string) => {

            let value = updatingItem[e];
            
            if (typeof value == "boolean") {
                value = value === true ? "True": "False";
            }

            settingsUpdateModel.push(
                {
                    key: e,
                    value,
                }
            );
        });

        let response = await CommonService.post(`api/system/settings`, settingsUpdateModel);
        if (response === true) {
            Notify.success(t('common-itemUpdatedSuccessfully'));
        } else {
            Notify.error(t('common-itemUpdatingFailed'));
        }

        Loader.hide();
    }

    return (
        <>
            <Row gutter={16}>
                <Col span={5} className="gutter-row">
                    <Card title={t('siteSettings')}>
                        <p>{t('siteSettings-description')}</p>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <ViewSelector views={SYSTEM_GLOBAL_APP_SETTINGS_VIEWS} />
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={19} className="gutter-row">

                    <Form
                        form={form}
                        onFinish={onFinish}
                        name="data-table-item-update-form"
                        layout="vertical">

                        <Card title={t('siteSettings')}
                            extra={<Button disabled={true} type="primary" htmlType="submit">{t('common-save')}</Button>}>

                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        name={SITE_NAME}
                                        label={t('siteSettings-siteName')}>
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={SITE_URL}
                                        label={t('siteSettings-siteUrl')}>
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item valuePropName="checked"
                                        name={REQUIRE_HTTPS}
                                        label={t('siteSettings-requireHttps')}>
                                        <Switch onChange={requireHttpsChanged} disabled={true}/>
                                    </Form.Item>
                                </Col>
                                {
                                    isShowMovedPermenent === true &&
                                    <Col span={12}>
                                        <Form.Item valuePropName="checked"
                                            name={REQUIRE_HTTPS_MOVED_PERMENANT}
                                            label={t('siteSettings-requireHttpsMovedPermanent')}>
                                            <Switch disabled={true}/>
                                        </Form.Item>
                                    </Col>
                                }
                            </Row>

                        </Card>
                    </Form>
                </Col>
            </Row>

        </>
    )
};


