import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import { TRANSLATION_KEY } from '../../../constants/commonConstants';
import CommonService from "../../../services/CommonService";
import { IFormData } from "../../../types/FormData.types";

const { Option } = Select;


interface IFormSelectorProps {
    formSelected: (formId: number) => void;
}

export const FormSelector: React.FC<IFormSelectorProps> = ({
    formSelected
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [forms, setForms] = useState<IFormData[]>([]);

    useEffect(() => {
        getForms();
    }, [])

    const getForms = async () => {
        const odataResponse = await CommonService.get('data/FormData?$select=id,name');
        if (odataResponse) {
            setForms(odataResponse);
        }
    }

    return (
        <>
        <h4>{t('externalCommunication-formData-pleaseSelectAFormToContinue')}</h4>
            <Select className="w-100" onSelect={formSelected} placeholder={t('externalCommunication-formData-selectAForm')}>
                {
                    forms.map((formItem: IFormData) => {
                        return (
                            <Option key={formItem.id} value={formItem.id}>
                                {
                                    formItem.name
                                }
                            </Option>
                        );
                    })
                }
            </Select>
        </>
    );

}

