import React, { useEffect, useState } from 'react';
import Select from 'antd/lib/select';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TableOutlined } from '@ant-design/icons';

import { IViewSelectorItem } from '../../types/Common.types';
import { TRANSLATION_KEY } from '../../constants/commonConstants';

const { Option } = Select;


export interface IViewSelectorProps {
    views: IViewSelectorItem[];
}

export const ViewSelector: React.FC<IViewSelectorProps> = ({ views }) => {
    const history = useHistory();
    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [currentPath, setCurrentPath] = useState<string>('');


    useEffect(() => {
        let currentPath = window.location.pathname;
        let matchingView = views.find(v => v.path === currentPath);
        if (matchingView) {
            setCurrentPath(currentPath);
        }
    }, [views]);

    const handleChange = (path: string) => {
        history.push(path);
    }

    return (
        <>
            <Select className="w-100 view-selector" value={currentPath} onChange={handleChange}>
                {
                    views.map((view: IViewSelectorItem, index: number) => {
                        return (
                            <Option key={index} value={view.path}>
                                {
                                    currentPath === view.path &&
                                    <>
                                        <TableOutlined className="mr-3" />
                                        <small>{t('viewing')}:&nbsp;&nbsp;</small><span className="selected-path">{t(view.languageKey)}</span>
                                    </>
                                }
                                {
                                    currentPath !== view.path &&
                                    <>{t(view.languageKey)}</>
                                }

                            </Option>
                        );
                    })
                }
            </Select>
        </>
    );
}