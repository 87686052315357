import React, { useEffect } from "react";
import { useHistory } from "react-router";

import Notify from "../utils/notification-handler";
import AuthService from "./../services/AuthService";
import {
  AUTH_SIGN_KEY,
  AUTH_TOKEN_KEY,
  DASHBOARD_PATH,
  LANG_SWEDISH,
  LANG_SWEDISH_NEW_SYSTEM,
} from "./../constants/commonConstants";
import CommonService from "../services/CommonService";
import CommonMethods from "../utils/commonMethods";
import i18n from "../i18n";

export const Landing: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    getAndSetSignAndToken().then(() => {
      getSeletedLanguage();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAndSetSignAndToken = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const sign = urlParams.get(AUTH_SIGN_KEY);

    if (sign) {
      localStorage.setItem(AUTH_SIGN_KEY, sign);

      let tokenResponse = await AuthService.getToken(sign);
      localStorage.setItem(AUTH_TOKEN_KEY, tokenResponse.data.d);

      history.push(DASHBOARD_PATH);
    } else {
      let existingSign = localStorage.getItem(AUTH_SIGN_KEY);
      let token = localStorage.getItem(AUTH_TOKEN_KEY);

      if (!existingSign || !token) {
        redirectToLogin();
      } else {
        let isTokenValid = CommonMethods.isJWTTokenValid();

        if (isTokenValid) {
          history.push(DASHBOARD_PATH);
        } else {
          redirectToLogin();
        }
      }
    }
  };

  const redirectToLogin = () => {
    // In Development environment avoid redirecting
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      Notify.error(`Please login to continue`);
      return;
    }

    if (process.env.REACT_APP_BASE_URL) {
      window.location.href = process.env.REACT_APP_BASE_URL;
    }
    Notify.error(`Please login to continue`);
  };

  const getSeletedLanguage = async () => {
    const response = await CommonService.get(`api/user/settings/external`);
    if (typeof response === "string") {
      let responseJson = JSON.parse(response);
      if (responseJson.syslang) {
        updateLanguage(responseJson.syslang);
      }
    }
  };

  const updateLanguage = (lang: string) => {
    localStorage.setItem("lng", lang);
    if (lang === LANG_SWEDISH) {
      i18n.changeLanguage(LANG_SWEDISH_NEW_SYSTEM);
      // i18n.reloadResources("se");
    } else {
      i18n.changeLanguage(lang);
      // i18n.reloadResources(["en", "se"]);
    }
  };

  return (
    <div className="landing-container">
      <div className="loader-container">
        <img
          className="w-100"
          alt="Empori logo"
          src="https://cdn.empori.se/customer_logotypes/svgs/empori-green-icon.svg"
        />
      </div>
    </div>
  );
};
