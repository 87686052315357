import { Button, Modal } from "antd";

import React, { useEffect, useState } from "react";
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { useTranslation } from "react-i18next";
import { TRANSLATION_KEY } from "../../../constants/commonConstants";

import { OPEN_SEARCH_LOG_DETAILS } from "../../../constants/pubSubKeys";
import { ISearchLog, ISearchLogDetail } from "../../../types/SearchLog.types";
import CommonService from "../../../services/CommonService";
import Notify from "../../../utils/notification-handler";

const { confirm } = Modal;

interface ISearchLogDetailsProps {
}

export const SearchLogDetails: React.FC<ISearchLogDetailsProps> = () => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [searchLog, setSearchLog] = useState<ISearchLog | null>(null);

    useEffect(() => {
        let isShowModalPubSub = PubSub.subscribe(OPEN_SEARCH_LOG_DETAILS, openModal);
        return () => {
            PubSub.unsubscribe(isShowModalPubSub);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openModal = (msg: string, searchLog: ISearchLog) => {
        setSearchLog(searchLog);
        setIsModalVisible(true);
    }

    const closeModal = () => {
        setIsModalVisible(false);
    };

    const deleteBtnClicked = (id:number)=>{
        confirm({
            title: t('dataTable-deleteConfirm'),
            icon: <ExclamationCircleOutlined />,
            content: t('dataTable-areYouSureYouWantToPermenentlyDeleteSelected'),
            okText: t('common-yes'),
            okType: 'danger',
            cancelText: t('common-no'),
            onOk: ()=>deleteConfirmed(id)
        });
    }

    const deleteConfirmed = async (id:number) => {
        const odataResponse = await CommonService.delete(`data/SearchLog/${id}`);
        if (odataResponse === true) {
            Notify.success(t('dataTable-dataDeletedSuccessfully'));
        } else {
            Notify.error(t('dataTable-dataDeletingFailed'));
        }
    }

    return (
        <Modal title={t('searchLog-details')} visible={isModalVisible && searchLog !== null} onCancel={closeModal} width={800}>
            <table className="search-log-details-table">
                <thead>
                    <th>{t('searchLog-searchTerm')}</th>
                    <th>{t('searchLog-date')}</th>
                    <th>{t('searchLog-searchHit')}</th>
                    <th></th>
                </thead>
                <tbody>
                    {
                        searchLog?.details.map((item: ISearchLogDetail) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.vat}</td>
                                    <td>{format(new Date(item.ts), 'yyyy-dd-MM')}</td>
                                    <td>{item.hits}</td>
                                    <td>
                                        <Button  onClick={()=>deleteBtnClicked(item.id)}><DeleteOutlined className="text-red" /></Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Modal>

    );

}
