import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import Notify from '../../../utils/notification-handler';
import CommonService from '../../../services/CommonService';
import { TRANSLATION_KEY } from '../../../constants/commonConstants';
import { DATA_TABLE_RELOAD } from '../../../constants/pubSubKeys';


const { confirm } = Modal;


export interface ICommonRowToolItemProps {
    dataEndpoint: string;
    selectedRowKeys: number[];
    className?: string;
}

export const DeleteRowTool: React.FC<ICommonRowToolItemProps> = ({
    dataEndpoint,
    selectedRowKeys,
    className
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);


    const deleteConfirmed = async () => {
        await bulkDeleteData();
        PubSub.publish(DATA_TABLE_RELOAD);
    }

    const bulkDeleteData = async () => {
        let url = `data/${dataEndpoint}/Bulk/Delete`;
        const odataResponse = await CommonService.post(url, selectedRowKeys);
        if (odataResponse === true) {
            Notify.success(t('dataTable-dataDeletedSuccessfully', 'Data deleted successfully'));
        } else {
            Notify.error(t('dataTable-dataDeletingFailed', 'Data deleting failed'));
        }
    }

    const deleteBtnClicked = async () => {
        if (selectedRowKeys.length <= 0) {
            Notify.info(
                t('dataTable-notification-noItemsSelectedToDelete-content'),
                t('dataTable-notification-noItemsSelectedToDelete-title')
            );
            return;
        }

        confirm({
            title: t('dataTable-deleteConfirm'),
            icon: <ExclamationCircleOutlined />,
            content: `${t('dataTable-areYouSureYouWantToPermenentlyDeleteSelected')} ${selectedRowKeys.length} ${t('dataTable-items')}?`,
            okText: t('common-yes'),
            okType: 'danger',
            cancelText: t('common-no'),
            onOk: deleteConfirmed
        });
    }

    return (
        <div className={className}>
            <Button onClick={deleteBtnClicked}>
                <DeleteOutlined className="text-blue" /> {t('dataTable-permanentlyDelete')}
            </Button>
        </div>
    )
}