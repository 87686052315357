export default class DynamicIconHandler {

  public static AntIcon = ({ type, ...rest }: any) => {
    const icons = require(`@ant-design/icons`);
    const defaultIcon = 'StopOutlined';
    let Component = icons[type];

    if (Component === undefined) {
      Component = icons[defaultIcon];
    }
    
    return <Component {...rest} />
  }

}