import { IDataTableColumn, IDataTableSettings } from "../types/DataTable.types";
import { ARRAY, INT, STRING } from "./commonConstants";


export const SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_ROLES_KEY: string = 'SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_ROLES';


export const ROLES_DATATABLE_SETTINGS: IDataTableSettings = {
    defaultSortColumn: 'id',
    dataEndpoint: 'RoleData',
    staticFilters: []
}

export const ROLES_DATATABLE_COLUMNS: IDataTableColumn[] = [
    {
        key: 'id',
        dataIndex: 'id',
        languageKey: '',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: INT,
        isMandatory: true,
        isHiddenFromTableDisplay: true,
    },
    {
        key: 'name',
        dataIndex: 'name',
        languageKey: 'role-name',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        isImportable: true,
        dataType: STRING
    },
    {
        key: 'userRoles',
        dataIndex: ['userRoles'],
        languageKey: 'role-noOfUsers',
        isVisible: true,
        isSortable: false,
        isSearchable: false,
        canFilter: false,
        dataType: ARRAY,
        isMandatory: false
    },
    {
        key: 'userRoles_userId',
        dataIndex: ['userRoles', 'userId'],
        languageKey: '',
        isVisible: false,
        isSortable: false,
        isSearchable: false,
        dataType: ARRAY,
        isMandatory: true,
        isHiddenFromTableDisplay: true,
    }
];

export const STANDARD_ROLE_ADMIN: string = 'admin';
export const STANDARD_ROLE_EDITOR: string = 'Editor';
export const STANDARD_ROLE_BASE: string = 'Base';
export const STANDARD_ROLE_STRUCTURE: string = 'Structure';
