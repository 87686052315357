/* eslint-disable import/prefer-default-export */

const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_URL = process.env.REACT_APP_NEW_API_ENDPOINT;

export const ENDPOINTS = {
    Invoke: `${BASE_URL}/api/edit/invoke`,
    Settings: `${API_URL}/api/systemSettings`,
    Token: `${API_URL}/api/auth/token`,
    DeleteTempfiles: `${BASE_URL}/api/edit/DeleteTempfiles`,
};
