import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from '@ant-design/icons';
import { Input, Popover } from "antd";

import { IDataTableColumn } from "../../../types/DataTable.types";
import { TRANSLATION_KEY } from "../../../constants/commonConstants";
import { DATA_TABLE_RESET_TOOL_BOX_SEARCH, DATA_TABLE_SEARCH_TEXT_FILTER } from "../../../constants/pubSubKeys";


export interface IToolBoxSearchProps {
    dataTableColumns: IDataTableColumn[];
}

export const ToolBoxSearch: React.FC<IToolBoxSearchProps> = ({
    dataTableColumns
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [searchTypingTimeout, setSearchTypingTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
    const [searchText, setSearchText] = useState<string>('');


    useEffect(()=>{
        PubSub.subscribe(DATA_TABLE_RESET_TOOL_BOX_SEARCH, ()=>{
            setSearchText('');
        });
    }, []);


    useEffect(() => {
        setSearchTypingTimeout(setTimeout(() => {
            PubSub.publish(DATA_TABLE_SEARCH_TEXT_FILTER, searchText);
        }, 600));
    }, [searchText]);


    const searchTextFilterChanged = (value: string) => {
        if (searchTypingTimeout) {
            clearTimeout(searchTypingTimeout);
        }
        setSearchText(value);
    }


    const generateSearchPlaceholder = () => {
        let placeholder = '';
        dataTableColumns.forEach((column: IDataTableColumn) => {
            if (column.isSearchable === true && column.isVisible === true) {
                placeholder = placeholder + t(column.languageKey) + ' ';
            }
        })
        return t('dataTable-searchBy', 'Search by') + ' ' + placeholder;
    }


    return (
        <Popover content={generateSearchPlaceholder()} trigger="hover">
            <Input allowClear
                placeholder={generateSearchPlaceholder()}
                onChange={(e) => searchTextFilterChanged(e.target.value)}
                value={searchText} prefix={<SearchOutlined />} />
        </Popover>
    );

}