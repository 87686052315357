import React, { useEffect, useState } from "react";
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'antd';

import { ViewSelector } from "./../../Common/ViewSelector";
import { IDataTableColumn } from "./../../../types/DataTable.types";
import { ToolBoxSearch } from "./../../Common/DataTableToolBox/ToolBoxSearch";
import { ToolBoxCommonActions } from "./../../Common/DataTableToolBox/ToolBoxCommonActions";
import { ToolBoxColumnSelector } from "./../../Common/DataTableToolBox/ToolBoxColumnSelector";

import { TRANSLATION_KEY } from './../../../constants/commonConstants';
import { DATA_TABLE_ROW_SELECTION_CHANGED } from "./../../../constants/pubSubKeys";
import { SYSTEM_SEARCH_VIEWS } from "../../../constants/innerViews";
import { SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_SEARCH_LOG_KEY } from "../../../constants/searchLogConstants";
import { SearchLogDeleteRowTool } from "./SearchLogDeleteRowTool";
import { ISearchLog } from "../../../types/SearchLog.types";



interface ISearchLogToolBoxProps {
    dataTableColumns: IDataTableColumn[];
}

export const SearchLogToolBox: React.FC<ISearchLogToolBoxProps> = ({ dataTableColumns }) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);
    const [selectedItems, setSelectedItems] = useState<ISearchLog[]>([]);


    useEffect(() => {
        let rowSelectionPubSub = PubSub.subscribe(DATA_TABLE_ROW_SELECTION_CHANGED, (msg: string, selectedItems: ISearchLog[]) => {
            setSelectedItems(selectedItems);
        });
        return () => {
            PubSub.unsubscribe(rowSelectionPubSub);
        }
    }, []);


    return (
        <Card title={t('searchLog')}>
            <p>{t('searchLog-description')}</p>

            {
                selectedItems.length > 0 &&
                <p>{t('dataTable-selected')} <strong>{selectedItems.length}</strong> {t('dataTable-items')}</p>
            }

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <ToolBoxSearch dataTableColumns={dataTableColumns} />
                </Col>
                <Col span={24}>
                    <ToolBoxCommonActions isShowCopy={false} isShowCreateNew={false} isShowImport={false} />

                    <SearchLogDeleteRowTool className="mt-13-px" selectedItems={selectedItems} />
                </Col>

                <Col span={24}>
                    <ViewSelector views={SYSTEM_SEARCH_VIEWS} />
                </Col>

            </Row>

            <ToolBoxColumnSelector dataTableColumns={dataTableColumns} settingsKey={SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_SEARCH_LOG_KEY} />

        </Card>
    );

}

