import { IDataTableColumn, IDataTableSettings } from "../types/DataTable.types";
import {  DATE, INT, STRING } from "./commonConstants";


export const SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_FILE_DATA_KEY: string = 'SETTINGS_DATA_TABLE_VISIBLE_COLUMNS_FILE_DATA';


export const FILE_DATA_DATATABLE_SETTINGS: IDataTableSettings = {
    defaultSortColumn: 'id',
    dataEndpoint: 'FileData',
    staticFilters: []
}


export const FILE_DATA_DATATABLE_COLUMNS: IDataTableColumn[] = [
    {
        key: 'id',
        dataIndex: 'id',
        languageKey: 'fileData-id',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: INT
    },
    {
        key: 'title',
        dataIndex: 'title',
        languageKey: 'fileData-title',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'fileName',
        dataIndex: 'fileName',
        languageKey: 'fileData-fileName',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'path',
        dataIndex: 'path',
        languageKey: 'fileData-path',
        isVisible: true,
        isSortable: true,
        isSearchable: true,
        dataType: STRING
    },
    {
        key: 'created',
        dataIndex: 'uploaded',
        languageKey: 'fileData-created',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: DATE
    },
    {
        key: 'updated',
        dataIndex: 'lastEdited',
        languageKey: 'fileData-updated',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: DATE
    },
    {
        key: 'hash',
        dataIndex: 'fileHash',
        languageKey: 'fileData-hash',
        isVisible: true,
        isSortable: true,
        isSearchable: false,
        dataType: STRING
    }
];
