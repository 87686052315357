import React from 'react';
import PubSub from 'pubsub-js';
import Moment from 'moment';
import Highlighter from "react-highlight-words";
import { TFunction } from 'react-i18next';
import { FilterOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { Checkbox, Table, TablePaginationConfig } from 'antd';
import { FilterDropdownProps, FilterValue, Key, SorterResult, SortOrder, TableCurrentDataSource } from 'antd/lib/table/interface';

import Loader from '../../utils/loader-handler';
import CommonService from '../../services/CommonService';
import ExcelHandler from '../../utils/excel-handler';
import Notify from '../../utils/notification-handler';
import { IBase } from '../../types/Common.types';
import { DataTableColumnFilter } from './DataTableColumnFilter';

import {
    IColumnFilterPubSubData, IGetTableDataResponse, IStaticFilterPubSubData,
    IDataTableColumn, IDataTableSettings, IBulkAction, IBulkActionProperty, IFilter, IOverrideOdataQueries
} from '../../types/DataTable.types';

import {
    ARRAY, ASCEND, BOOLEAN, DATATABLE_DEFAULT_ITEMS_PER_PAGE,
    DATE,DATETIME, DESCEND
} from '../../constants/commonConstants';

import {
    DATA_TABLE_COPY_ITEM, DATA_TABLE_EXPORT,
    DATA_TABLE_COLUMN_FILTER, DATA_TABLE_RELOAD, DATA_TABLE_RESET_AND_RELOAD,
    DATA_TABLE_ROW_SELECTION_CHANGED,
    DATA_TABLE_STATIC_FILTER, DATA_TABLE_SEARCH_TEXT_FILTER,
    DATA_TABLE_RESET_TOOL_BOX_SEARCH, DATA_TABLE_RESET_STATIC_FILTER,
    DATA_TABLE_BULK_UPDATE,
    DATA_TABLE_SHOW_UPDATE_MODAL
} from '../../constants/pubSubKeys';
import DynamicIconHandler from '../../utils/dynamic-icon-handler';
import OdataQueryBuilder from '../../utils/odata-query-builder';



export interface ITableChangeProps<T> {
    pagination: TablePaginationConfig;
    filters: Record<string, FilterValue | null>;
    sorter: SorterResult<T> | SorterResult<T>[];
    extra: TableCurrentDataSource<T>;
}

export interface IDataTableProps<T> {
    dataTableSettings: IDataTableSettings;
    dataTableColumns: IDataTableColumn[];
    lang: TFunction<string[]>;
    hidePagination?: boolean;
    itemsPerPage?: number;
    modifyData?: (data: T[]) => T[];
    cutomDisplays?: (key: string, value: any, fullRecord: T) => JSX.Element[] | JSX.Element;
    cutomDisplaysExcel?: (key: string, value: any, fullRecord: T) => string;
    customFilters?: (dataIndex: string, column: IDataTableColumn, filterDropdownProps: FilterDropdownProps) => JSX.Element[] | JSX.Element;
    selectQuery?: string;
    onRowClick?: (record: T, rowIndex?: number) => void;
    tableKey?: string;
    onRowSelectPassFullItem?: boolean;
    hideEditColumn?: boolean;
    isDisableSelectQuery?: boolean;
    isDisableExpandQuery?: boolean;
    editColumnIcon?: string;
    overrideOdataQueries?: IOverrideOdataQueries;
}

export interface IDataTableState<T> {
    dataTableColumns: IDataTableColumn[];
    data: T[];
    loading: boolean;
    updatingItem: T | null;
    paginationConfig: TablePaginationConfig | false;
    sorter: SorterResult<T> | SorterResult<T>[] | null;
    selectedRowKeys: Key[];
    columnFilters: IFilter[];
    searchTextFilterValue: string;
    staticFilter: IFilter | null;
}

// This is a common component that can be used to display data in a table
// Used ant design data table as the base
// This component take data table settings and array of columns as properties
// Please go through comments in '../../types/DataTable.types.ts' to get idea about table settings & columns
// Once necesary properties are passed datatable will generate an odataquery and get data from backend & display data
// This table contains functionalities like pagination, sorting & searching, for each of these functionalities table will generate odata queries and get necessary data
export class DataTable<T extends IBase> extends React.Component<IDataTableProps<T>, IDataTableState<T>> {

    constructor(props: IDataTableProps<T>) {
        super(props);

        this.state = {
            dataTableColumns: this.props.dataTableColumns,
            data: [],
            loading: false,
            paginationConfig: this.getPaginationConfig(),
            sorter: this.getDefaultSorter(this.props.dataTableSettings),
            selectedRowKeys: [],
            updatingItem: null,
            columnFilters: [],
            searchTextFilterValue: '',
            staticFilter: null
        }
    }


    static defaultProps = {
        hidePagination: false,
        itemsPerPage: DATATABLE_DEFAULT_ITEMS_PER_PAGE
    }

    componentDidUpdate(prevProps: IDataTableProps<T>) {
        if (prevProps.dataTableColumns !== this.props.dataTableColumns) {
            this.setState({ dataTableColumns: this.props.dataTableColumns }, () => {
                this.setTableData();
            });
        }
    }

    componentDidMount() {
        PubSub.subscribe(DATA_TABLE_RELOAD, this.reloadTable);
        PubSub.subscribe(DATA_TABLE_RESET_AND_RELOAD, this.resetAndReloadTable);
        PubSub.subscribe(DATA_TABLE_COLUMN_FILTER, this.filterTableByColumnFilters);
        PubSub.subscribe(DATA_TABLE_SEARCH_TEXT_FILTER, this.filterTableBySearchTextFilters);
        PubSub.subscribe(DATA_TABLE_STATIC_FILTER, this.filterTableByStaticFilter);
        PubSub.subscribe(DATA_TABLE_EXPORT, this.exportTable);
        PubSub.subscribe(DATA_TABLE_COPY_ITEM, this.copyTableItem);
        PubSub.subscribe(DATA_TABLE_BULK_UPDATE, this.bulkUpdateTableItems);

        this.setTableData();
    }


    componentWillUnmount() {
        PubSub.unsubscribe(DATA_TABLE_RELOAD);
        PubSub.unsubscribe(DATA_TABLE_RESET_AND_RELOAD);
        PubSub.unsubscribe(DATA_TABLE_COLUMN_FILTER);
        PubSub.unsubscribe(DATA_TABLE_SEARCH_TEXT_FILTER);
        PubSub.unsubscribe(DATA_TABLE_STATIC_FILTER);
        PubSub.unsubscribe(DATA_TABLE_EXPORT);
        PubSub.unsubscribe(DATA_TABLE_COPY_ITEM);
        PubSub.unsubscribe(DATA_TABLE_BULK_UPDATE);
    }


    getPaginationConfig = (currentPage: number = 1, totalNoOfItems?: number) => {
        return {
            current: currentPage,
            total: totalNoOfItems,
            pageSize: this.props.itemsPerPage,
            showTotal: (total: number, range: number[]) => `${range[0]}-${range[1]} ${this.props.lang('dataTable-pagination-of')} ${total} ${this.props.lang('dataTable-pagination-items')}`,
            showSizeChanger: false, 
        } as TablePaginationConfig;
    }


    getDefaultSorter = (dataTableSettings: IDataTableSettings) => {
        let defaultSortColumn = dataTableSettings.defaultSortColumn ? dataTableSettings.defaultSortColumn : 'id';
        let defaultSortOrder = ASCEND as SortOrder;

        if (dataTableSettings.defaultSortOrder &&
            (dataTableSettings.defaultSortOrder === ASCEND || dataTableSettings.defaultSortOrder === DESCEND)) {
            defaultSortOrder = dataTableSettings.defaultSortOrder as SortOrder;
        }

        return {
            columnKey: this.getDataIndexString(defaultSortColumn),
            order: defaultSortOrder
        }
    }

    resetAndReloadTable = async () => {
        this.resetColumnFilters(() => {
            this.resetSearchTextFilter(() => {
                this.resetStaticFilter(async () => {

                    this.setState({
                        paginationConfig: this.getPaginationConfig(),
                        sorter: (this.props.dataTableColumns && this.props.dataTableColumns[0]) ? {
                            columnKey: this.getDataIndexString(this.props.dataTableColumns[0].dataIndex),
                            order: ASCEND as SortOrder
                        } : null,
                    })

                    this.onRowSelectChange([]);
                    await this.setTableData();
                });
            });
        });
    }

    reloadTable = async () => {
        await this.setTableData();
    }

    onRowClick = (record: T, rowIndex?: number) => {
        if (this.props.onRowClick) {
            this.props.onRowClick(record, rowIndex);
        }
    }

    copyTableItem = () => {
        if (this.state.selectedRowKeys.length <= 0) {
            Notify.info(
                this.props.lang('dataTable-notification-noItemsSelectedToCopy-content', 'Please select an item to copy'),
                this.props.lang('dataTable-notification-noItemsSelectedToCopy-title', 'No item selected')
            );
            return;
        }

        if (this.state.selectedRowKeys.length > 1) {
            Notify.info(
                this.props.lang('dataTable-notification-multipleItemsSelectedToCopy-content', 'Cannot copy more than one item at once. Please select single item to procees'),
                this.props.lang('dataTable-notification-multipleItemsSelectedToCopy-title', 'Multiple items selected')
            );
            return;
        }

        let matchingItem = this.state.data.find(d => d.id === this.state.selectedRowKeys[0] as number);
        if (!matchingItem) {
            return;
        }

        let newItemToAdd: T = JSON.parse(JSON.stringify(matchingItem));
        newItemToAdd.id = 0;

        PubSub.publish(DATA_TABLE_SHOW_UPDATE_MODAL, newItemToAdd);
        //  this.props.showHideUpdateModal(true, newItemToAdd);
    }


    filterTableBySearchTextFilters = (msg: string, searchTextFilterValue: string) => {
        this.resetStaticFilter(() => {
            this.resetColumnFilters(() => {
                this.setState({
                    paginationConfig: this.getPaginationConfig(),
                    searchTextFilterValue: searchTextFilterValue
                }, async () => {
                    await this.setTableData();
                })
            });
        });

    }


    filterTableByColumnFilters = (msg: string, filterTablePubSubData: IColumnFilterPubSubData) => {
        this.resetSearchTextFilter(() => {
            this.resetStaticFilter(() => {
                let matchingItemIndex = this.state.columnFilters.findIndex(c => c.columnName === filterTablePubSubData.filter.columnName);
                let columnFilters = this.state.columnFilters;

                if (matchingItemIndex === -1 && filterTablePubSubData.filter.value !== null) {
                    columnFilters.push(filterTablePubSubData.filter);
                }
                else if (matchingItemIndex !== -1 && filterTablePubSubData.filter.value !== null) {
                    columnFilters[matchingItemIndex].value = filterTablePubSubData.filter.value;
                }
                else if (matchingItemIndex !== -1 && filterTablePubSubData.filter.value === null) {
                    columnFilters.splice(matchingItemIndex, 1);
                }

                this.setState({ columnFilters }, async () => {
                    await this.setTableData();
                })
            });
        });

    }


    filterTableByStaticFilter = (msg: string, staticFilterPubSubData: IStaticFilterPubSubData) => {
        this.resetSearchTextFilter(() => {
            this.resetColumnFilters(() => {
                this.setState({
                    paginationConfig: this.getPaginationConfig(),
                    staticFilter: staticFilterPubSubData.filter
                }, async () => {
                    await this.setTableData();
                })
            });
        });
    }


    resetColumnFilters = (callBack?: () => void) => {
        this.setState({ columnFilters: [] }, () => {
            if (callBack) {
                callBack();
            }
        });
    }


    resetSearchTextFilter = (callBack?: () => void) => {
        PubSub.publish(DATA_TABLE_RESET_TOOL_BOX_SEARCH, {});
        this.setState({ searchTextFilterValue: '' }, () => {
            if (callBack) {
                callBack();
            }
        });
    }


    resetStaticFilter = (callBack?: () => void) => {
        PubSub.publish(DATA_TABLE_RESET_STATIC_FILTER, {});
        this.setState({ staticFilter: null }, () => {
            if (callBack) {
                callBack();
            }
        });
    }


    getDataIndexString = (dataIndex: string | string[]) => {
        if (Array.isArray(dataIndex)) {
            return dataIndex.join('/');
        }
        else {
            return dataIndex;
        }
    }

    exportTable = async () => {
        Loader.show();

        let model = {
            dataTableColumns: this.props.dataTableColumns,
            staticFilter: this.state.staticFilter,
            hidePagination: this.props.hidePagination,
            paginationConfig: this.state.paginationConfig,
            sorter: this.state.sorter,
            searchTextFilterValue: this.state.searchTextFilterValue,
            columnFilters: this.state.columnFilters,
            isDisablePaginationQuery: true,
            isDisableSelectQuery: this.props.isDisableSelectQuery,
            isDisableExpandQuery: this.props.isDisableExpandQuery,
            overrideOdataQueries: this.props.overrideOdataQueries
        };

        let odataQueryString: string = await OdataQueryBuilder.generateFullOdataQueryString(model);
        let response: IGetTableDataResponse<T> = await this.getData(odataQueryString);
        let excelDisplayData = await this.generateExcelDisplayData(response.data);

        ExcelHandler.writeToFile(excelDisplayData, `table-data`);
        Loader.hide();
    }


    generateExcelDisplayData = async (data: T[]) => {
        let excelDisplayData: any[] = [];

        data.forEach((dataItem: any) => {
            let excelDiaplayObject: any = {};
            this.props.dataTableColumns.forEach((column: IDataTableColumn) => {

                if (column.isNotExport === true) {
                    return;
                }

                let value: any;
                if (typeof column.dataIndex === 'string' || column.dataIndex instanceof String) {
                    let rawValue = dataItem[column.dataIndex as string];
                    value = this.getExcelColumnDisplayByDataType(rawValue, dataItem, column.dataType, column.key, column.isCustomDisplay);
                }
                else {
                    value = dataItem;
                    column.dataIndex.forEach((dataIndexString: string, index: number) => {
                        if (!value[dataIndexString]) {
                            return;
                        }

                        if (index === (column.dataIndex.length - 1)) {
                            let rawValue = value[dataIndexString];
                            value = this.getExcelColumnDisplayByDataType(rawValue, dataItem, column.dataType, column.key, column.isCustomDisplay);
                        } else {
                            value = value[dataIndexString];
                        }

                    });
                }
                excelDiaplayObject[this.props.lang(column.languageKey)] = value;
            });

            excelDisplayData.push(excelDiaplayObject);
        });

        return excelDisplayData;
    }


    setTableData = async () => {
        this.setState({ loading: true }, async () => {

            let model = {
                dataTableColumns: this.props.dataTableColumns,
                staticFilter: this.state.staticFilter,
                hidePagination: this.props.hidePagination,
                paginationConfig: this.state.paginationConfig,
                sorter: this.state.sorter,
                searchTextFilterValue: this.state.searchTextFilterValue,
                columnFilters: this.state.columnFilters,
                isDisableSelectQuery: this.props.isDisableSelectQuery,
                isDisableExpandQuery: this.props.isDisableExpandQuery,
                overrideOdataQueries: this.props.overrideOdataQueries
            };

            let odataQueryString: string = await OdataQueryBuilder.generateFullOdataQueryString(model);
            let response: IGetTableDataResponse<T> = await this.getData(odataQueryString);
            this.setState({
                data: response.data,
                loading: false,
                paginationConfig: { ...this.state.paginationConfig, total: response.total }
            });
        });
    }


    handleTableChange = (tableChangeProps: ITableChangeProps<T>) => {
        this.setState({
            loading: true,
            paginationConfig: {
                ...this.state.paginationConfig,
                current: tableChangeProps.pagination.current,
                pageSize: tableChangeProps.pagination.pageSize
            },
            sorter: (Object.keys(tableChangeProps.sorter).length !== 0) ? tableChangeProps.sorter : this.state.sorter
        }, async () => {
            this.setTableData();
        });
    }


   


    generateGetByIdOdataQueryString = async () => {
        let queryString = '';

        let selectQueryString = OdataQueryBuilder.getSelectQueryString(this.props.dataTableColumns,true);
        if (selectQueryString !== '') {
            queryString = queryString + ((queryString !== '') ? '&' : '') + selectQueryString;
        }

        let selectExpandString = await OdataQueryBuilder.getExpandQueryString(this.props.dataTableColumns,true);
        if (selectExpandString !== '') {
            queryString = queryString + ((queryString !== '') ? '&' : '') + selectExpandString;
        }

        return queryString;
    }


    onRowSelectChange = (selectedRowKeys: Key[]) => {

        if (this.props.onRowSelectPassFullItem === true) {
            let selectedItems: T[] = [];
            let tableKey: any = this.props.tableKey ? this.props.tableKey : "id";

            selectedRowKeys.forEach((selectedRowKeys: Key) => {
                let matchingItem = this.state.data.find((i: any) => i[tableKey] === selectedRowKeys);
                if (matchingItem) {
                    selectedItems.push(matchingItem);
                }
            })
            PubSub.publish(DATA_TABLE_ROW_SELECTION_CHANGED, selectedItems);
        }
        else {
            PubSub.publish(DATA_TABLE_ROW_SELECTION_CHANGED, selectedRowKeys as number[]);
        }


        this.setState({ selectedRowKeys });
    };

    rowClicked = async (updatingItemId: number) => {
        let updatingItem = await this.getDataItemById(updatingItemId);
        PubSub.publish(DATA_TABLE_SHOW_UPDATE_MODAL, updatingItem);
        // let updatingItem = await this.getDataItemById(updatingItemId);
        // this.props.showHideUpdateModal(true, updatingItem);
    }


    getColumnFilterValueByColumnName = (column: IDataTableColumn) => {
        if (column.canFilter === false) {
            return null;
        }

        let columnDataIndex = this.getDataIndexString(column.dataIndex);
        let matchingColumnFilter = this.state.columnFilters.find(cf => cf.columnName === columnDataIndex);
        return matchingColumnFilter ? [matchingColumnFilter.value] : null;
    }

    getDataItemById = async (id: number) => {
        let odataQueryString: string = await this.generateGetByIdOdataQueryString();
        let url = `data/${this.props.dataTableSettings.dataEndpoint}?$filter=id eq ${id}&${odataQueryString}`;

        const response = await CommonService.get(url);

        if (response && response.length > 0) {
            return response[0] as T;
        }

        return null;
    }


    getData = async (odataQueryString: string) => {
        let url = `data/${this.props.dataTableSettings.dataEndpoint}?${odataQueryString}`;
        const odataResponse = await CommonService.get(url, true);

        if (!odataResponse || !odataResponse.data) {
            return { data: [] };
        }

        let response: IGetTableDataResponse<T> = { data: [] };

        let x = response.total = odataResponse.data['@odata.count'];

        response.data = odataResponse.data.value as T[];
        response.total = odataResponse.data['@odata.count'];

        if (!x && response.data && response.data.length > 0) {
            response.total = (response.data[0] as any).recodeCount;
        }

        if (this.props.modifyData) {
            response.data = this.props.modifyData(response.data);
        }

        return response;
    }



    // TODO: ADD A BULK UPDATE
    bulkUpdateTableItems = (msg: string, bulkUpdateAction: IBulkAction) => {
        Loader.show();

        this.state.selectedRowKeys.forEach(async (selectedRowKey: Key) => {

            let updatingItem: any = this.state.data.find(d => d.id === selectedRowKey);
            if (updatingItem) {

                bulkUpdateAction.properties.forEach((property: IBulkActionProperty) => {
                    updatingItem[property.propertyDataIndex] = property.propertyValue;
                });

                let url = `data/${this.props.dataTableSettings.dataEndpoint}/${updatingItem.id}`;
                await CommonService.patch(url, updatingItem);
            }
        });

        this.reloadTable();
        Loader.hide();
        Notify.success(this.props.lang('common-itemSUpdatedSuccessfully'));
    }


    getExcelColumnDisplayByDataType = (value: any, fullRecord: T, sourceDataType: string, key: string, isCustomDisplay?: boolean) => {
        if (isCustomDisplay === true && this.props.cutomDisplaysExcel) {
            return this.props.cutomDisplaysExcel(key, value, fullRecord);
        }

        switch (sourceDataType) {
            case BOOLEAN:
                return (value === true) ? this.props.lang('common-true') : this.props.lang('common-false');
            case DATE:
                return value ? (Moment(value).format('YYYY-MM-DD')) : '';
            case DATETIME:
                return value ? (Moment(value).format('YYYY-MM-DD, HH:mm')) : '';
            case ARRAY:
                return (value) ? value.join(", ") : '';
            default:
                return value;
        }
    }

    getColumnRender = (value: any, fullRecord: T, sourceDataType: string, key: string, isCustomDisplay?: boolean) => {
        let displayValue: string = '';
        let searchWords: string[] = [];

        if (isCustomDisplay === true && this.props.cutomDisplays) {
            return this.props.cutomDisplays(key, value, fullRecord);
        }

        switch (sourceDataType) {
            case BOOLEAN:
                return (value != null) ? <Checkbox checked={value} disabled></Checkbox> : <></>;
            case DATE:
                displayValue = value ? (Moment(value).format('YYYY-MM-DD')) : '';
                break;
            case DATETIME:
                displayValue = value ? (Moment(value).format('YYYY-MM-DD, HH:mm')) : '';
                break;
            case ARRAY:
                return (value) ? value.length : <></>;
            default:
                displayValue = value;
        }

        this.state.columnFilters.forEach((filter: IFilter) => {
            searchWords.push(filter.value.toString());
        });

        return <Highlighter
            highlightClassName="text-highlight"
            searchWords={searchWords}
            autoEscape
            textToHighlight={displayValue ? displayValue.toString() : ''}
        />
    }

    generateFilterDropdown = (item: IDataTableColumn, itemDataIndex: string, f: FilterDropdownProps) => {
        if (item.canFilter === false) {
            return undefined;
        }
        if (item.isCustomFilter === true && this.props.customFilters) {
            return this.props.customFilters(itemDataIndex, item, f);
        }
        return <DataTableColumnFilter dataIndex={itemDataIndex} column={item} filterDropdownProps={f} />
    }


    render() {
        const rowSelection = { selectedRowKeys: this.state.selectedRowKeys, onChange: this.onRowSelectChange };

        return (
            <div className="data-table-container">
                <Table<T>
                    locale={{
                        emptyText: (
                            <div className="mt-4 mb-4">
                                <IssuesCloseOutlined className="nodata-icon" /> {this.props.lang('common-noData')}
                            </div>
                        )
                    }}
                    rowKey={this.props.tableKey ? this.props.tableKey : "id"}
                    scroll={{ x: 'max-content' }}
                    dataSource={this.state.data}
                    loading={this.state.loading}
                    rowSelection={rowSelection}
                    onChange={(pagination, filters, sorter, extra) => this.handleTableChange({ pagination, filters, sorter, extra })}
                    pagination={this.props.hidePagination !== true ? this.state.paginationConfig : false}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => this.onRowClick(record, rowIndex)
                        };
                    }}
                    bordered>
                    {
                        !this.props.hideEditColumn &&
                        <Table.Column<T> title={''} dataIndex="edit-icon-column"
                            render={(text: string, record: T) => {
                                let iconName = this.props.editColumnIcon ? this.props.editColumnIcon : 'EditOutlined';
                                return DynamicIconHandler.AntIcon({ type: iconName, className: "text-blue", onClick: () => this.rowClicked(record.id) })
                            }} />
                    }
                    {
                        this.state.dataTableColumns.filter(c => c.isHiddenFromTableDisplay !== true).map((item: IDataTableColumn) => {
                            let itemDataIndex = this.getDataIndexString(item.dataIndex);
                            return (
                                item.isVisible === true ?
                                    <Table.Column<T> key={itemDataIndex}
                                        title={this.props.lang(item.languageKey)}
                                        dataIndex={item.dataIndex}
                                        className="data-display-column"
                                        sorter={item.isSortable}
                                        sortDirections={[DESCEND, ASCEND] as SortOrder[]}
                                        filterDropdown={(f) => this.generateFilterDropdown(item, itemDataIndex, f)}
                                        filterIcon={filtered => (item.canFilter !== false) ? <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} /> : undefined}
                                        render={(value: any, record: T) => this.getColumnRender(value, record, item.dataType, item.key, item.isCustomDisplay)}
                                        filteredValue={this.getColumnFilterValueByColumnName(item)}
                                    /> : null
                            );
                        })
                    }
                </Table>
            </div>
        )
    }

}
