import React from 'react';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface'
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Radio, Space } from 'antd';
import { FilterConfirmProps, FilterDropdownProps } from 'antd/lib/table/interface';

import { IColumnFilterPubSubData, IDataTableColumn } from '../../types/DataTable.types';
import { DATA_TABLE_COLUMN_FILTER } from '../../constants/pubSubKeys';
import { BOOLEAN, DATETIME, INT, STRING, TRANSLATION_KEY } from '../../constants/commonConstants';



const { RangePicker } = DatePicker;

export interface IDataTableColumnFilterProps {
    dataIndex: string;
    column: IDataTableColumn;
    filterDropdownProps: FilterDropdownProps;
}

export const DataTableColumnFilter: React.FC<IDataTableColumnFilterProps> = ({
    dataIndex,
    column,
    filterDropdownProps
}) => {

    const { t } = useTranslation([TRANSLATION_KEY.toString()]);

    const handleSearch = (selectedKeys: React.Key[], confirm: (param?: FilterConfirmProps | undefined) => void) => {
        confirm();
        let columnFilterPubSubData: IColumnFilterPubSubData = {
            filter: {
                columnName: dataIndex,
                value: (selectedKeys[0] !== undefined) ? selectedKeys[0] : null
            }
        };
        PubSub.publish(DATA_TABLE_COLUMN_FILTER, columnFilterPubSubData);
    }


    const handleReset = (clearFilters: (() => void) | undefined) => {
        if (clearFilters) {
            clearFilters();
        }

        let columnFilterPubSubData: IColumnFilterPubSubData = {
            filter: {
                columnName: dataIndex,
                value: null
            }
        };
        PubSub.publish(DATA_TABLE_COLUMN_FILTER, columnFilterPubSubData);
    }

    const getDateRangeStringFromDateRangeValue = (dateRange: RangeValue<Moment>) => {
        if (!dateRange || !dateRange[0] || !dateRange[1]) {
            return [];
        }

        let dateRangeString = dateRange[0].utc().format() + '|' + dateRange[1].utc().format();
        return [dateRangeString];
    }


    const getDateRangeValueFromDateRangeString = (dateRange: React.Key): [Moment, Moment] | null => {
        let dateRangeString = dateRange as string;

        if (!dateRangeString || dateRangeString === '') {
            return null;
        }
        const dateRangeSplits = dateRangeString.split('|');

        if (dateRangeSplits.length !== 2) {
            return null;
        }

        return [moment(dateRangeSplits[0]), moment(dateRangeSplits[1])];
    }


    return (
        <div className="data-table-column-filter-container">
            {
                (column.dataType === STRING || column.dataType === INT) &&
                <Input
                    placeholder={`${t('common-search', 'Search')} ${dataIndex}`} className="filter-input"
                    value={filterDropdownProps.selectedKeys[0]}
                    onChange={e => filterDropdownProps.setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(filterDropdownProps.selectedKeys, filterDropdownProps.confirm)}
                />
            }
            {
                column.dataType === BOOLEAN &&
                <Radio.Group
                    value={filterDropdownProps.selectedKeys[0]} className="filter-input"
                    onChange={e => filterDropdownProps.setSelectedKeys((e.target.value !== null) ? [e.target.value] : [])}>
                    <Space direction="vertical">
                        <Radio value={true}>{t('common-true', 'True')}</Radio>
                        <Radio value={false}>{t('common-false', 'False')}</Radio>
                    </Space>
                </Radio.Group>
            }
            {
                column.dataType === DATETIME &&
                <RangePicker className="filter-input"
                    value={getDateRangeValueFromDateRangeString(filterDropdownProps.selectedKeys[0])}
                    onChange={e => filterDropdownProps.setSelectedKeys(getDateRangeStringFromDateRangeValue(e))} />
            }

            <Space>
                <Button type="primary" className="search-btn" size="small" icon={<SearchOutlined />}
                    onClick={() => handleSearch(filterDropdownProps.selectedKeys, filterDropdownProps.confirm)}>
                    {t('common-search', 'Search')}
                </Button>
                <Button className="reset-btn" size="small" onClick={() => handleReset(filterDropdownProps.clearFilters)} >
                    {t('common-reset', 'Reset')}
                </Button>
            </Space>
        </div>
    )

}